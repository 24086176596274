import React, {useEffect, useState} from "react";

import {Button, Checkbox, Grid, GridColumn, GridRow, ModalContent, TextArea} from "semantic-ui-react";
import {initializeApp} from "firebase/app";
import {firebaseConfig} from "../utils";
import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";
import {getAuth, signInAnonymously} from "firebase/auth";
import {getDownloadURL, getMetadata, getStorage, listAll, ref} from "firebase/storage";
import {useParams} from "react-router-dom";
import {useKeycloak} from "react-keycloak";
import NavBar from "../../../Navbar";
import {Col, Container, Modal, Row} from "react-bootstrap";
import LeftMenu from "../../../LeftMenu";
import axios from "axios";

const DefaultByCategoryContent = () => {
    const params = useParams();
    const category = params.category;
    const { initialized } = useKeycloak();
    const app = initializeApp(firebaseConfig);
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
            "6Led7IQpAAAAACyLa4k7hh0YivDqRBCMwvaFhzDI" // Replace with your ReCaptcha Site Key
        ),
        isTokenAutoRefreshEnabled: true,
    });

    const auth = getAuth();
    const [show, setShow] = useState(false);
    const [results, setResults] = useState([]);
    const [resultsByCategory, setResultsByCategory] = useState([]);
    const [toShow, setToShow] = useState([]);

    const [desc, setDesc] = useState("")
    const [conformity, setConformity] = useState("Conforme")
    const [selectedFile, setSelectedFile] = useState(null);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const data = new FormData();

    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onFileUpload = (item) => {
        try {
            data.append(
                "data",
                selectedFile
            );
            data.append("category", category)
            data.append("conformity", conformity)
            data.append("description", desc)
            handleShow();
            axios.post("https://europe-west1-bernypro-d3577.cloudfunctions.net/addFile", data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(r => {
                if (r.status === 200) {
                    window.location.reload()
                } else {
                    console.log('erreur')
                }
            }).catch((e) => {
                console.log(e)
            })
        }
        catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        signInAnonymously(auth)
            .then(() => {
                const storage = getStorage(app,"gs://app-pro-56fcb.appspot.com");
                const listRef = ref(storage, '/Defautheque');
                listAll(listRef).then((res) => {
                    res.prefixes.forEach((folderRef) => {
                        setResults(results=> [...results, folderRef.name])
                        const listRefChildConforme = ref(storage, '/Defautheque/'+folderRef.name + "/Conforme");
                        listAll(listRefChildConforme)
                            .then((res) => {
                                res.items.forEach((item) => {
                                    getMetadata(ref(storage, item.fullPath))
                                        .then((metadata) => {
                                            getDownloadURL(ref(getStorage(app,"gs://app-pro-56fcb.appspot.com"), item.fullPath)).then((urlPicture) => {
                                                setResultsByCategory( resultsByCategory => [...resultsByCategory, {
                                                    type: "Conforme",
                                                    category: folderRef.name,
                                                    name: item.name,
                                                    url: urlPicture.toString(),
                                                    desc: metadata.customMetadata === undefined ? "" : metadata.customMetadata.desc,
                                                    date: metadata.timeCreated,
                                                }])
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            // Uh-oh, an error occurred!
                                        });
                                });


                            }).catch((error) => {
                            // Uh-oh, an error occurred!
                        });

                        const listRefChildNonConforme = ref(storage, '/Defautheque/'+folderRef.name + "/Non conforme");
                        listAll(listRefChildNonConforme)
                            .then((res) => {
                                res.items.forEach((item) => {
                                    getMetadata(ref(storage, item.fullPath))
                                        .then((metadata) => {
                                            getDownloadURL(ref(getStorage(app,"gs://app-pro-56fcb.appspot.com"), item.fullPath)).then((urlPicture) => {
                                                        setResultsByCategory( resultsByCategory => [...resultsByCategory, {
                                                            type: "Non conforme",
                                                            category: folderRef.name,
                                                            name: item.name,
                                                            url: urlPicture.toString(),
                                                            desc: metadata.customMetadata === undefined ? "" : metadata.customMetadata.desc,
                                                            date: metadata.timeCreated,
                                                        }])

                                            }).catch((error) => {
                                                console.log(error)
                                                // Uh-oh, an error occurred!
                                            });
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            // Uh-oh, an error occurred!
                                        });

                                });

                            }).catch((error) => {
                            console.log(error);
                            // Uh-oh, an error occurred!
                        });
                    })})
                    .catch((error) => {
                        console.log("error : ", error.toString());
                    })})
            .catch((error) => {
                console.log("error : ", error.toString());
            });
        setToShow(resultsByCategory);
        results.sort(custom_sort)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const onDelete = (item, conformity, itemCategory) => {
        try {
            axios.post("https://europe-west1-bernypro-d3577.cloudfunctions.net/deleteFile", JSON.stringify({"fileName": item, "conformity": conformity, "category": itemCategory}), {
                headers: {
                    'Accept': '*/*',
                    'Content-Type': 'application/json'
                }
            }).then(r => {
                if (r.status === 200) {
                    window.location.reload()
                } else {
                    console.log('erreur')
                }
            }).catch((e) => {
                console.log(e)
            })
        }
        catch (e) {
            console.log(e)
        }
    };

    function custom_sort(a, b) {
        return new Date(a.date) - new Date(b.date);
    }
    resultsByCategory.sort(custom_sort)

    return initialized ? (
            <Container className="container">
                <NavBar />
                <Row>
                    <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                        <LeftMenu />
                    </Col>
                    <Col
                        xs={10}
                        style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
                    >
                                <div class="ui link cards">
                                    {
                                        resultsByCategory.map((mapItem) => {
                                            if(mapItem.category === category){
                                                console.log(mapItem)
                                    return <div class="card">
                                        <Grid>
                                            <GridColumn floated='right' width={2} style={{padding: '25px'}} >
                                                <button className="ui right floated" style={{backgroundColor: "red"}} onClick={() => onDelete(mapItem.name, mapItem.type, mapItem.category)}><p style={{color: "white"}}>x</p></button>
                                            </GridColumn>
                                        </Grid>
                                    <div class="image">
                                    <img src={mapItem.url}/>
                                    </div>
                                    <div class="content">
                                    <div class="header"></div>
                                    <div class="meta">
                                    <a>{mapItem.name.toString().split(".")[0]}</a>
                                    </div>
                                    <div class="description">
                                {mapItem.desc}
                                    </div>
                                    </div>
                                    <div class="extra content">
                                        {mapItem.type === "Conforme" ?
                                            <a className="ui green image label">
                                                <img
                                                    src={"https://cdn-icons-png.flaticon.com/512/4252/4252349.png"}/>
                                                {mapItem.type}
                                            </a>:
                                            <a className="ui red image label">
                                                <img
                                                    src={"https://cdn-icons-png.flaticon.com/512/4337/4337010.png"}/>
                                                {mapItem.type}
                                            </a>
                                        }
                                    <span class="right floated">
                                {mapItem.date.split("T")[0]} {mapItem.date.split("T")[1].split(".")[0]}
                                    </span>
                                    </div>
                                    </div>}
                                        else{

                                            }})}
                                    <div className="card">
                                        <div className="content" style={{textAlign: "center"}}>
                                            <i class="teal massive cloud upload icon" onClick={handleShow} />
                                        </div>
                                    </div>

                                    <Modal
                                        show={show}
                                        onHide={handleClose}
                                        size="lg"
                                        aria-labelledby="contained-modal-title-vcenter"
                                        centered
                                    >
                                        <Modal.Header closeButton>
                                            <Modal.Title>Ajout de contenu à la catégorie : "{category}"</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Grid columns={3}>
                                                <GridRow>
                                                    <GridColumn width={1}/>
                                                    <GridColumn width={10}>

                                                        <Button
                                                            as="label"
                                                            htmlFor="choose-file"
                                                            content={selectedFile !== null ? selectedFile.name: "Upload fichier"}
                                                            compact={true}
                                                            labelPosition="left"
                                                            icon="file"

                                                        />

                                                        <input type="file" id="choose-file" hidden onInput={onFileChange} accept="image/png"/>
                                                        <GridColumn/>
                                                    </GridColumn>

                                                    <GridColumn/>
                                                </GridRow>
                                                <GridRow>
                                                    <GridColumn width={1}/>
                                                    <GridColumn width={15}>
                                                        <TextArea value={desc} onChange={((e) => {setDesc(e.target.value)})} placeholder='Description' style={{width: "100%"}}/>
                                                    </GridColumn>
                                                </GridRow>
                                                <GridRow>
                                                    <GridColumn width={1}/>
                                                    <GridColumn width={10}>
                                                        <div style={{display: 'inline-flex', alignContent: "space-between"}}>
                                                            <p style={{marginRight: "10px"}}>Conforme</p>
                                                            <Checkbox toggle onChange={(value) => {
                                                                setConformity(!value ? "Conforme": "Non conforme")}
                                                            }/>
                                                            <p style={{marginLeft: "10px"}}>Non conforme</p>
                                                        </div>
                                                        {selectedFile !== null ? <Button
                                                            content="Valider"
                                                            labelPosition="left"
                                                            color="green"
                                                            floated={"right"}
                                                            style={{alignContent: "center"}}
                                                            icon="check"
                                                            onClick={onFileUpload}
                                                        ></Button> : <p></p>}
                                                    </GridColumn>
                                                </GridRow>

                                            </Grid>
                                        </Modal.Body>
                                    </Modal>
                                </div>
                    </Col>
                </Row>
            </Container>



    ): <p>Erreur</p>;

};

export default DefaultByCategoryContent;
