import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import { useParams } from "react-router-dom";
import { Card, Table } from "semantic-ui-react";
import LeftMenu from "../../LeftMenu";
import momentTimezone from "moment-timezone";
import NavBar from "../../Navbar";

function containerEllipsis(name) {
	return name.length > 10 ? `${name.slice(0, 5)}...${name.slice(-5)}` : name;
}

function ReceiptsCardContent({ timestamp }) {
	// filter by the day of the timestamp without timezome
	const daybegin = moment(timestamp).startOf("day").toISOString();
	const dayend = moment(timestamp).endOf("day").toISOString();
	const query = gql`
    query {
      receipts (
        where: {
          datetime: {
            _gte: "${daybegin}"
            _lte: "${dayend}"
          }
        }
      ) {
        datetime
        small
        big
        transportedIn
        enterprise
        brand
        name
      }
    }
  `;

	const { loading, error, data } = useQuery(query);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error : {error.message}</p>;

	return (
		<Card.Content>
			<Card.Header>Historique des réceptions</Card.Header>
			<Card.Description>
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Heure</Table.HeaderCell>
							<Table.HeaderCell>Bac</Table.HeaderCell>
							<Table.HeaderCell>Contenants</Table.HeaderCell>
							<Table.HeaderCell>Laveur</Table.HeaderCell>
							<Table.HeaderCell>Magasin</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{data.receipts.length > 0 ? (
							data.receipts.map((receipt) => {
								// TODO: parse receipt.dateTime (YYYY/MM/DD HH:mm UTC) and format it as YYYY/MM/DD HH:mm Europe/Paris
								const date = momentTimezone
									.tz(receipt.datetime, "UTC")
									.toISOString();
								const hour = moment(receipt.datetime).format("HH:mm");
								return (
									<Table.Row key={date}>
										<Table.Cell>
											<a
												href={`/receptions/${encodeURI(date)}/${
													receipt.transportedIn
												}`}
											>
												{hour}
											</a>
										</Table.Cell>
										<Table.Cell>
											<a href={`/bac/${encodeURI(receipt.transportedIn)}`}>
												{receipt.transportedIn.length > 5
													? `${containerEllipsis(receipt.transportedIn)}`
													: receipt.transportedIn}
											</a>
										</Table.Cell>
										<Table.Cell>
											{receipt.small} petits
											<br />
											{receipt.big} grands
										</Table.Cell>
										<Table.Cell>{receipt.enterprise}</Table.Cell>
										<Table.Cell>
											{receipt.brand} {receipt.name}
										</Table.Cell>
									</Table.Row>
								);
							})
						) : (
							<Table.Row>
								<Table.Cell colSpan="5">Aucune réception</Table.Cell>
							</Table.Row>
						)}
					</Table.Body>
				</Table>
			</Card.Description>
		</Card.Content>
	);
}

function Content({ timestamp }) {
	return (
		<Row>
			<Card fluid>
				<ReceiptsCardContent timestamp={timestamp} />
			</Card>
		</Row>
	);
}

const ReceiptsHistoryPage = () => {
	const { initialized } = useKeycloak();

	const params = useParams();

	const timestamp = params.timestamp;

	return initialized ? (
		<Container className="container">
			<NavBar />
			<Row>
				<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
					<LeftMenu />
				</Col>
				<Col
					sm={11}
					xl={10}
					style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
				>
					<h1>
						Historique des réceptions du{" "}
						{moment(timestamp).format("DD/MM/YYYY")}
					</h1>
					<Content timestamp={timestamp} />
				</Col>
			</Row>
		</Container>
	) : (
		<></>
	);
};

export default ReceiptsHistoryPage;
