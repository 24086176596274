import React from "react";
import gql from "graphql-tag";
import { MdOutlineCleaningServices } from "react-icons/md";
import { FaTruckLoading, FaHandHoldingUsd } from "react-icons/fa";
import { IconContext } from "react-icons";
import { useQuery } from "@apollo/client";

const queryCleaning = gql`
  query {
    cleanings_datetime(order_by: { date: desc }, limit: 1) {
      date
      count
    }
  }
`;
const queryDelivery = gql`
  query {
    delivery_by_day(order_by: { date: desc }, limit: 1) {
      date
      count
    }
  }
`;
const queryDeliveryBack = gql`
  query {
    delivery_back_by_day(order_by: { date: desc }, limit: 1) {
      date
      count
    }
  }
`;

// Chart component
const LastCleaning = ({ query }) => {
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const date = new Date(data.cleanings_datetime[0].date);
	const datetime = new Intl.DateTimeFormat("fr", {
		dateStyle: "full",
		timeStyle: "short",
		timeZone: "Europe/Paris",
	}).format(date);
	return (
		<div style={{ marginTop: "1em", textAlign: "center" }}>
			<IconContext.Provider value={{ color: "#F36857", size: "2em" }}>
				<div>
					<MdOutlineCleaningServices />{" "}
					<span style={{ color: "#005258", fontWeight: "bold" }}>
						{data.cleanings_datetime[0].count}{" "}
					</span>
					{" le "} {datetime.toString()}
				</div>
			</IconContext.Provider>{" "}
		</div>
	);
};

const LastDelivery = ({ query }) => {
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const date = new Date(data.delivery_by_day[0].date);
	const datetime = new Intl.DateTimeFormat("fr", { dateStyle: "full" }).format(
		date,
	);
	return (
		<div style={{ marginTop: "1em", textAlign: "center" }}>
			<IconContext.Provider value={{ color: "#F36857", size: "2em" }}>
				<div>
					<FaTruckLoading />{" "}
					<span style={{ color: "#005258", fontWeight: "bold" }}>
						{data.delivery_by_day[0].count}{" "}
					</span>
					{" le "} {datetime.toString()}
				</div>
			</IconContext.Provider>
		</div>
	);
};

const LastDeliveryBack = ({ query }) => {
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const date = new Date(data.delivery_back_by_day[0].date);
	const datetime = new Intl.DateTimeFormat("fr", {
		dateStyle: "full",
		timeStyle: "short",
		timeZone: "Europe/Paris",
	}).format(date);
	return (
		<div style={{ marginTop: "1em", textAlign: "center" }}>
			<IconContext.Provider value={{ color: "#F36857", size: "2em" }}>
				<div>
					<FaHandHoldingUsd />{" "}
					<span style={{ color: "#005258", fontWeight: "bold" }}>
						{data.delivery_back_by_day[0].count}
					</span>{" "}
					{" le "} {datetime.toString()}
				</div>
			</IconContext.Provider>
		</div>
	);
};

const Activity = () => {
	return (
		<div>
			<div style={{ backgroundColor: "white" }} className="wholeActivity">
				<div>
					<h5 style={{ margin: "10px", textAlign: "center" }}>
						Flux d'activité
					</h5>
					<div className="activity">
						<LastCleaning query={queryCleaning} />
						<LastDelivery query={queryDelivery} />
						<LastDeliveryBack query={queryDeliveryBack} />
					</div>
				</div>
			</div>
		</div>
	);
};

export { Activity };
