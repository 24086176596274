import React from "react";
import gql from "graphql-tag";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import NavBar from "../../Navbar";
import Menu from "../../LeftMenu";
import { useQuery } from "@apollo/client";

const Webapp = () => {
	const query = gql`
    query MyQuery4 {
      webapp(
        order_by: { updated_at: desc }
        where: { status: { _eq: "to_refund" } }
      ) {
        updated_at
        mobile
        payment_provider
        deposit_amount
        item_id
        Item {
          arrival_country
        }
        Shop {
          brand
          name
        }
      }
    }
  `;

	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	{
		const allRefund = [];
		data.webapp.forEach((el) => {
			allRefund.push(
				<tr>
					<td>{el.item_id}</td>
					<td>{el.deposit_amount === "2" ? "Petit" : "Grand"}</td>
					<td>
						{el.Item.arrival_country === "Corée" ? "Spencer" : "Degrenne"}
					</td>
					<td>{el.deposit_amount}</td>
					<td>{el.updated_at}</td>
					<td>{`${el.Shop.brand} - ${el.Shop.name}`}</td>
					<td>{el.payment_provider}</td>
					<td>{el.mobile}</td>
				</tr>,
			);
		});
		if (data.webapp.length === 0) {
			allRefund.push(
				<tr>
					<td colSpan={8} style={{ textAlign: "center" }}>
						Pas de remboursements disponibles
					</td>
				</tr>,
			);
		}
		return (
			<div className="metric">
				<div style={{ width: "100%", margin: "5%" }}>
					<Table striped bordered hover variant="dark">
						<thead>
							<tr>
								<th>Identifiant</th>
								<th>Taille de contenants</th>
								<th>Type de contenants</th>
								<th>Montant à rembourser</th>
								<th>Date de l'opération</th>
								<th>Magasin choisi</th>
								<th>Choix de remoursement</th>
								<th>Information sur le client</th>
							</tr>
						</thead>
						<tbody>{allRefund}</tbody>
					</Table>
				</div>
			</div>
		);
	}
};

const TableauRemboursement = () => {
	const { initialized } = useKeycloak();
	return initialized ? (
		<Container className="container">
			<div>
				<NavBar />
				<Row>
					<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
						<Menu />
					</Col>
					<Col sm={11} xl={9}>
						<div>
							<div style={{ marginTop: "100px", marginLeft: "15px" }}>
								<div>
									<h5 style={{ margin: "10px", textAlign: "left" }}>
										Clients à rembourser
									</h5>
									<div className="metrics">
										<Webapp />
									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Container>
	) : null;
};
export { TableauRemboursement };
