import React from "react";
import { useKeycloak } from "react-keycloak";
import { Redirect, Route, useLocation } from "react-router-dom";

/**
 * This component is a wrapper for a react-router Route component.
 * If the user is not part of the specified role, it will redirect to the login page.
 */
const ProtectedRoute = ({ component: Component, roles, ...rest }) => {
	const { keycloak, initialized } = useKeycloak();
	const location = useLocation();

	const hasRole = keycloak.tokenParsed?.groups?.some((r) => roles.includes(r));

	return (
		<Route
			{...rest}
			component={
				initialized
					? hasRole
						? Component
						: () => (
								<Redirect to={{ pathname: "/", state: { from: location } }} />
						  )
					: null
			}
		/>
	);
};

export default ProtectedRoute;
