import React from "react";
import { Feed, Label, Icon, Divider } from "semantic-ui-react";
import { useKeycloak } from "react-keycloak";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../../Navbar";
import Menu from "../../LeftMenu";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const ReceiptFeed = () => {
	const query = gql`
    query MyQuery4 {
      receipts {
        datetime
        transportedIn
        name
        brand
        enterprise
        big
        small
      }
    }
  `;
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const receipts = [];
	data.receipts.forEach((el) => {
		let image;
		if (el.brand === "U") {
			image = "/u.png"
		} else if (el.brand === "Leclerc") {
			image = "/1200px-Logo_E.Leclerc_Sans_le_texte.svg.png"
		} else {
			image = "/kisspng-logo-carrefour-market-brand-image-startup-weekend-monaco-2017-retour-sur-la-4ème-5b677e2a9f4257.9785071315335091626523.jpg"}

		receipts.push(
			<>
				<Feed.Event>
					<Feed.Label image={image} />
					<Feed.Content>
						<Feed.Date content={el.datetime} />
						<Feed.Summary content={`BAC n°${el.transportedIn}`} />
						<Feed.Extra text>
							Petits contenants:{" "}
							<span style={{ fontWeight: "bold" }}>{el.small}</span>
						</Feed.Extra>
						<Feed.Extra text>
							Grands contenants:{" "}
							<span style={{ fontWeight: "bold" }}>{el.big}</span>
						</Feed.Extra>
						<Feed.Extra text>
							En provenance de:{" "}
							<span style={{ fontWeight: "bold" }}>{el.name}</span>
						</Feed.Extra>
						<Feed.Extra text>
							Réceptionné par:{" "}
							<span style={{ fontWeight: "bold" }}>{el.enterprise}</span>
						</Feed.Extra>
					</Feed.Content>
					<Feed.Meta>
						<Feed.Content>
							<Label.Group tag>
								<Label as="a">
									<Icon name="weight" color="orange" />
									{((el.small * 166 + el.big * 315) / 1000 + 2.5).toFixed(3)} kg
								</Label>
							</Label.Group>
						</Feed.Content>
					</Feed.Meta>
				</Feed.Event>
				<Divider />
			</>,
		);
	});

	return (
		<div
			style={{
				textAlign: "center",
				paddingTop: "5%",
				paddingRight: "12.5%",
				paddingLeft: "12.5%",
				display: "block",
			}}
		>
			<Feed>{receipts}</Feed>
		</div>
	);
};
const ReceiptFeedLast = () => {
	const query = gql`
    query MyQuery4 {
      receipts(limit: 4, order_by: { datetime: desc }) {
        datetime
        transportedIn
        enterprise
        name
        brand
        big
        small
      }
    }
  `;
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const receipts = [];
	data.receipts.forEach((el) => {
		let image;
		if (el.brand === "U") {
			image = "/u.png"
		} else if (el.brand === "Leclerc") {
			image = "/1200px-Logo_E.Leclerc_Sans_le_texte.svg.png";
		} else {
			image = "/kisspng-logo-carrefour-market-brand-image-startup-weekend-monaco-2017-retour-sur-la-4ème-5b677e2a9f4257.9785071315335091626523.jpg"}
		const date = new Date(el.datetime);
		const today = new Date();
		const datediff = date.getTime() - today.getTime();
		const hours = datediff / (1000 * 60 * 60);
		const minutes = datediff / (1000 * 60);
		receipts.push(
			<Feed.Event>
				<Feed.Label image={image} />
				<Feed.Content>
					<Feed.Date
						content={`Il y a ${
							Math.abs(hours / 24)
								.toString()
								.split(".")[0]
						} jours et ${
							Math.abs(hours % 24)
								.toString()
								.split(".")[0]
						}h${
							Math.abs(minutes % 60)
								.toString()
								.split(".")[0]
						}`}
					/>
					<Feed.Summary content={`BAC n°${el.transportedIn}`} />
					<Feed.Extra>
						Petits contenants:{" "}
						<span style={{ fontWeight: "bold" }}>{el.small}</span>
					</Feed.Extra>
					<Feed.Extra text>
						Grands contenants:{" "}
						<span style={{ fontWeight: "bold" }}>{el.small}</span>
					</Feed.Extra>
					<Feed.Extra text>
						En provenance de:{" "}
						<span style={{ fontWeight: "bold" }}>{el.big}</span>
					</Feed.Extra>
					<Feed.Extra text>
						Date de réception:{" "}
						<span style={{ fontWeight: "bold" }}>{el.datetime}</span>
					</Feed.Extra>
					<Feed.Extra text>
						Réceptionné par:{" "}
						<span style={{ fontWeight: "bold" }}>{el.enterprise}</span>
					</Feed.Extra>
				</Feed.Content>
				<Feed.Meta>
					<Feed.Content>
						<Label.Group tag>
							<Label as="a">
								<Icon name="weight" color="orange" />
								{((el.small * 166 + el.big * 315) / 1000 + 2.5).toFixed(3)} kg
							</Label>
						</Label.Group>
					</Feed.Content>
				</Feed.Meta>
			</Feed.Event>,
		);
	});

	return (
		<div style={{ textAlign: "center", padding: "40px" }}>
			<Feed>{receipts}</Feed>
		</div>
	);
};

const ReceiptFeedTop = () => {
	const query = gql`
    query MyQuery4 {
      receipts(limit: 4, order_by: { big: asc, small: asc }) {
        datetime
        transportedIn
        name
        brand
        big
        small
        enterprise
      }
    }
  `;
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const receipts = [];
	data.receipts.forEach((el) => {
		let image;
		if (el.brand === "U") {
			image = "/u.png"
		} else if (el.brand === "Leclerc") {
			image = "/1200px-Logo_E.Leclerc_Sans_le_texte.svg.png";
		} else {
			image = "/kisspng-logo-carrefour-market-brand-image-startup-weekend-monaco-2017-retour-sur-la-4ème-5b677e2a9f4257.9785071315335091626523.jpg"}

		receipts.push(
			<Feed.Event>
				<Feed.Label image={image} />
				<Feed.Content>
					<Feed.Date content={el.datetime} />
					<Feed.Summary content={`BAC n°${el.transportedIn}`} />
					<Feed.Extra text>
						Petits contenants:{" "}
						<span style={{ fontWeight: "bold" }}>{el.small}</span>
					</Feed.Extra>
					<Feed.Extra text>
						Grands contenants:{" "}
						<span style={{ fontWeight: "bold" }}>{el.big}</span>
					</Feed.Extra>
					<Feed.Extra text>
						En provenance de:{" "}
						<span style={{ fontWeight: "bold" }}>{el.name}</span>
					</Feed.Extra>
					<Feed.Extra text>
						Réceptionné par:{" "}
						<span style={{ fontWeight: "bold" }}>{el.enterprise}</span>
					</Feed.Extra>
				</Feed.Content>
				<Feed.Meta>
					<Feed.Content>
						<Label.Group tag>
							<Label as="a">
								<Icon name="weight" color="orange" />
								{((el.small * 166 + el.big * 315) / 1000 + 2.5).toFixed(3)} kg
							</Label>
						</Label.Group>
					</Feed.Content>
				</Feed.Meta>
			</Feed.Event>,
		);
	});

	return (
		<div style={{ textAlign: "center", padding: "40px" }}>
			<Feed>{receipts}</Feed>
		</div>
	);
};

const Receipt = () => {
	const { initialized } = useKeycloak();
	return initialized ? (
		<Container className="container">
			<div>
				<NavBar />
				<Row>
					<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
						<Menu />
					</Col>

					<Col sm={5}>
						<div>
							<div style={{ marginTop: "100px", marginLeft: "15px" }}>
								<div>
									<h5 style={{ margin: "10px", textAlign: "center" }}>
										Derniers bacs réceptionnés
									</h5>
									<ReceiptFeedLast />
								</div>
							</div>
						</div>
					</Col>
					<Col sm={5}>
						<div>
							<div style={{ marginTop: "100px", marginLeft: "15px" }}>
								<div>
									<h5 style={{ margin: "10px", textAlign: "center" }}>
										Top réceptions
									</h5>
									<ReceiptFeedTop />
								</div>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }} />
					<Col sm={10}>
						<div>
							<div style={{ marginTop: "100px", marginLeft: "15px" }}>
								<div>
									<h5 style={{ margin: "10px", textAlign: "center" }}>
										Historique des réceptions
									</h5>
									<ReceiptFeed />
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Container>
	) : null;
};

export default Receipt;
