import { Table } from "semantic-ui-react";
import React, { useState } from "react";
import { useQuery, useApolloClient } from "@apollo/client";
import gql from "graphql-tag";
import NavBar from "../../Navbar";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import Menu from "../../LeftMenu";
import { useKeycloak } from "react-keycloak";
import { TableSupplyOrders } from "./SupplyDeliveries";
import { BsPencilFill } from "react-icons/all";
import InputNumber from "rc-input-number";

const Supply = () => {
	const [show, setShow] = useState(false);
	const handleShow = () => setShow(true);
	const handleClose = () => setShow(false);
	const client = useApolloClient();
	const modifySupplyList = [];
	const OnCloseModal = () => {
		modifySupplyList.forEach((element) => {
			const mutateSupply = gql`mutation MyMutation3 {
  update_supply(where: {id: {_eq: "${element.id}"}}, _set: {Quantity: ${element.Quantity}}) {
    affected_rows
  }
}`;
			client
				.mutate({
					mutation: mutateSupply,
				})
				.then((r) => setShow(false));
		});
		window.location.reload(false);
	};

	const onChange = (number, object) => {
		const tempObject = {
			id: object.id,
			Quantity: number,
		};
		if (modifySupplyList.length > 0) {
			if (modifySupplyList[0].id === object.id) {
				modifySupplyList.shift();
			} else if (
				modifySupplyList[modifySupplyList.length - 1].id === object.id
			) {
				modifySupplyList.pop();
			} else if (modifySupplyList.some((e) => e.id === object.id)) {
				modifySupplyList.splice(
					modifySupplyList.findIndex((e) => e.id === object.id),
					1,
				);
			}
		}
		modifySupplyList.push(tempObject);
	};
	const querySupply = gql`
    query MyQuery {
      supply {
        id
        Name
        Quantity
        warehouse {
          name
        }
      }
    }
  `;
	const { /* loading, */ data } = useQuery(querySupply);
	console.log(data);
	const { initialized } = useKeycloak();
	return initialized ? (
		data !== null ? (
			<Container className="container">
				<NavBar />
				<Row>
					<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
						<Menu />
					</Col>
					<Col
						sm={11}
						xl={10}
						style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
					>
						<Modal
							show={show}
							onHide={handleClose}
							size="lg"
							aria-labelledby="contained-modal-title-vcenter"
							centered
						>
							<Modal.Header closeButton>
								<Modal.Title>Modification quantité de fournitures</Modal.Title>
							</Modal.Header>
							<Modal.Body>
								{data !== undefined
									? data.supply.map((el) => (
											<>
												<div
													style={{
														textAlign: "center",
														alignContent: "center",
													}}
												>
													<h3>{el.Name} ({el.warehouse.name})</h3>
													<InputNumber
														min={0}
														onChange={(value) => onChange(value, el)}
														max={1000}
														controls={true}
														defaultValue={el.Quantity}
													/>
												</div>
											</>
									  ))
									: ""}
							</Modal.Body>
							<Modal.Footer>
								<Button variant="primary" onClick={OnCloseModal}>
									Enregistrer
								</Button>
							</Modal.Footer>
						</Modal>
						<h3 style={{ textAlign: "center" }}>
							Stock actualisés de fournitures livrables en magasin
						</h3>
						<Table
							celled
							structured
							color={"grey"}
							inverted
							className={"TableRecap"}
						>
							<Table.Header>
								<Table.Row>
									<Table.HeaderCell verticalAlign={"middle"}>
										Nom fourniture
									</Table.HeaderCell>
									<Table.HeaderCell verticalAlign={"middle"}>
										Quantité en stock{" "}
										<Button
											onClick={handleShow}
											style={{
												backgroundColor: "transparent",
												borderColor: "transparent",
											}}
										>
											<BsPencilFill />
										</Button>
									</Table.HeaderCell>
									<Table.HeaderCell verticalAlign={"middle"}>
										Lieu de stockage
									</Table.HeaderCell>
								</Table.Row>
							</Table.Header>
							<Table.Body>
								{data !== undefined ? (
									data.supply.map((el) => Tablebody(el))
								) : (
									<Table.Row> Chargement en cours ...</Table.Row>
								)}
							</Table.Body>
						</Table>
						<h3 style={{ textAlign: "center" }}>
							Historique de livraisons de fournitures
						</h3>
						<TableSupplyOrders />
					</Col>
				</Row>
			</Container>
		) : (
			<p>En chargement ....</p>
		)
	) : null;
};

function Tablebody(el) {
	return (
		<>
			<Table.Row>
				<Table.Cell verticalAlign={"middle"}>{el.Name}</Table.Cell>
				<Table.Cell verticalAlign={"middle"}>{el.Quantity}</Table.Cell>
				<Table.Cell verticalAlign={"middle"}>
					{el.warehouse.name}
				</Table.Cell>
			</Table.Row>
		</>
	);
}

export { Supply };
