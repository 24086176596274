function _getBacByStatus(shopBacData, status) {
  const bacOpen = [];
  shopBacData?.Container.forEach((bac) => {
    if (bac.status === status) bacOpen.push(bac);
  });
  return bacOpen;
}

function _getSize(items) {
  const size = { big: 0, small: 0 };
  items.Item.forEach((item) =>
    item.size === "Grand" ? (size.big += 1) : (size.small += 1)
  );
  return size;
}

function _gqlStringToArray(arrayString) {
  let array = [];
  if (arrayString === null || arrayString === "") return array;
  array = arrayString.split("[")[1].split("]")[0].split(", ");
  return array;
}

function _calcWeight(size) {
  return size === null ? 0 : size.big * 315 + size.small * 166;
}

export { _getBacByStatus, _getSize, _calcWeight, _gqlStringToArray };
