import React, { useState, useEffect } from "react";
import { useMutation } from "@apollo/client";
import {Dropdown, Input, Button, Form, Grid, GridRow, GridColumn, Divider} from "semantic-ui-react";
import DatePicker from "react-datepicker";
import { format, parseISO } from "date-fns";
import { ADD_SALE } from "./Query";
import "react-datepicker/dist/react-datepicker.css";
import filterOptions from "./FilterOptions";
import IAModal from "./modal";

const WeeklySalesForm = ({
  shopOptions,
  brandOptions,
  onFormSubmitSuccess,
}) => {
  const [selectedShop, setSelectedShop] = useState("");
  const [selectedSection, setSelectedSection] = useState("Sans détails");
  const [quantity, setQuantity] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [brandselected, setBrandSelected] = useState("");
  const [filteredShopOptions, setFilteredShopOptions] = useState([]);
  const [searchedShopOptions, setSearchedShopOptions] = useState([]);
  const [searchedBrandOptions, setSearchedBrandOptions] = useState([]);

  const [selectedDate, setSelectedDate] = useState(new Date());

  // Custom function to check if the date is a Monday
  const isMonday = (date) => {
    return date.getDay() === 1; // 0 = Sunday, 1 = Monday, ...
  };

  const sectionOptions = [
    { key: "boucherie", value: "Boucherie", text: "Boucherie" },
    { key: "poissonnerie", value: "Poissonnerie", text: "Poissonnerie" },
    { key: "traiteur", value: "Traiteur", text: "Traiteur" },
    { key: "fromage", value: "Fromage", text: "Fromage" },
    {
      key: "boulangerie",
      value: "Boulangerie-Patisserie",
      text: "Boulangerie-Patisserie",
    },
    { key: "charcuterie", value: "Charcuterie", text: "Charcuterie" },
    { key: "trad", value: "trad", text: "Rayon traditionnel" },
    { key: "sansdetails", value: "Sans détails", text: "Sans détails" },
  ];
  useEffect(() => {
    // Sort brandOptions by name
    const sortedBrandOptions = brandOptions
      .slice()
      .sort((a, b) => a.text.localeCompare(b.text));
    setSearchedBrandOptions(sortedBrandOptions);
  }, [brandOptions]);

  useEffect(() => {
    // Sort shopOptions by name and update filteredShopOptions when the brand changes or shopOptions are updated
    if (brandselected) {
      const filteredShops = shopOptions.filter(
        (shop) => shop.brand === brandselected
      );

      const sortedShops = filteredShops
        .slice()
        .sort((a, b) => a.text.localeCompare(b.text));

      setFilteredShopOptions(sortedShops);
    } else {
      // If no brand is selected, sort all shop options by name
      if (shopOptions) {
        const sortedShops = shopOptions
          .slice()
          .sort((a, b) => a.text.localeCompare(b.text));
        setFilteredShopOptions(sortedShops);
      }
    }
  }, [brandselected, shopOptions]);

  useEffect(() => {
    // Update the searched shop options when the filteredShopOptions change or when the input text changes
    if (filteredShopOptions) {
      setSearchedShopOptions(filterOptions(filteredShopOptions, selectedShop));
    }
  }, [filteredShopOptions, selectedShop]);

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const formattedDate = format(selectedDate, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

    addSale({
      variables: {
        amount: parseInt(quantity),
        Shop: selectedShop,
        week: formattedDate,
        section: selectedSection,
      },
    })
      .then(() => {
        setSuccessMessage("La vente a été ajoutée !");
        if (onFormSubmitSuccess) {
          onFormSubmitSuccess(); // Call the callback function
        }
      })
      .catch((error) => {
        console.error("Error adding sale:", error);
        setErrorMessage("La vente n'a pas réussi à être ajoutée :(");
      });

    setSelectedShop("");
    setSelectedDate(new Date());
    setSelectedSection("Sans détails");
    setQuantity("");
  };

  const [addSale, { loading: mutationLoading, error: mutationError }] = useMutation(ADD_SALE);

  const handleResetBrand = () => {
    setBrandSelected("");
    setSelectedShop("");
  };

  const handleResetShop = () => {
    setSelectedShop("");
  };

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <Form
      style={{ padding: "20px" }}
    >
      <Grid columns={3}>
        <GridRow>
          <GridColumn>
      <Form.Field>
        <label>Enseigne du magasin</label>
        {searchedBrandOptions ? ( // Add a check here to conditionally render the Dropdown
          <Dropdown
            placeholder="Enseigne du magasin"
            search
            selection
            options={searchedBrandOptions}
            value={brandselected}
            onChange={(e, { value }) => setBrandSelected(value)}
          />
        ) : (
          <p>Loading brand options...</p>
        )}
      </Form.Field>

      <Form.Field>
        <Button type="button" onClick={handleResetBrand}>
          Réinitialiser
        </Button>
      </Form.Field>
          </GridColumn>
        <GridColumn>
      <Form.Field>
        <label>Nom du magasin</label>
        {searchedShopOptions ? ( // Add a check here to conditionally render the Dropdown
          <Dropdown
            placeholder="Nom du magasin"
            search
            selection
            options={filteredShopOptions}
            value={selectedShop}
            onChange={(e, { value }) => setSelectedShop(value)}
          />
        ) : (
          <p>Loading shop options...</p>
        )}
      </Form.Field>
      <Form.Field>
        {" "}
        <Button type="button" onClick={handleResetShop}>
          Réinitialiser
        </Button>
      </Form.Field>
        </GridColumn>
        <GridColumn>
      <Form.Field>
        <label>Semaine sélectionnée (Lundi seulement)</label>
        <DatePicker
          onChange={handleDateChange}
          selected={selectedDate}
          filterDate={isMonday}
          dateFormat="dd/MM/yyyy"
        />
      </Form.Field>
        </GridColumn>
        </GridRow>
        <GridRow columns={1} centered>
        <GridColumn ><Divider /></GridColumn>
        </GridRow>
        <GridRow>
          <GridColumn>
      <Form.Field>
        <label>Rayon</label>
        <Dropdown
          placeholder="Rayon"
          selection
          options={sectionOptions}
          value={selectedSection}
          onChange={(e, { value }) => setSelectedSection(value)}
        />
      </Form.Field>
      <Form.Field>
        <label>Nombre de ventes</label>
        <Input
          type="Nombre de ventes"
          value={quantity}
          onChange={(e, { value }) => setQuantity(value)}
        />
      </Form.Field>
            <Button type="submit"
                    onClick={handleFormSubmit}
                    content="Valider"
                    labelPosition="left"
                    color="green"
                    style={{alignContent: "center"}}
                    icon="check"></Button>
            {/* Display success message */}
            {successMessage && <p style={{ color: "#006168" }}>{successMessage}</p>}

            {/* Display error message */}
            {errorMessage && <p style={{ color: "#F36857" }}>{errorMessage}</p>}
        </GridColumn>
          <GridColumn>
            <Divider vertical> OU </Divider>
          </GridColumn>
          <GridColumn>
            <IAModal date={selectedDate} brand={brandselected} shop={selectedShop !== "" ? filteredShopOptions.find((element) => element.value === selectedShop): "magasin"} />
          </GridColumn>
        </GridRow>
      </Grid>
    </Form>
  );
};

export default WeeklySalesForm;
