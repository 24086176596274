import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState } from "react";
import {
  Button,
  Form,
  FormControl,
  Navbar,
  OverlayTrigger,
  Popover,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

const Result = ({ id }) => {
  const query = gql`
    query MyQuery {
  Item_by_pk(id: "${id}") {
    id
  }
}
    `;
  const { loading, error, data } = useQuery(query);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  try {
    if (data) {
      const link = `/contenant/${data.Item_by_pk.id}`;
      return <a href={link}>{data.Item_by_pk.id}</a>;
    } else {
      return <p>Error</p>;
    }
  } catch (e) {
    return <p>Error</p>;
  }
};

const ResultContainer = ({ id }) => {
  const query = gql`
    query MyQuery3 {
  Container_by_pk(id: "${id}") {
    choosen_shop
    choosen_delivery_date
    choosen_size
    items
    id
    max_big
    max_small
    status
  }
}
    `;
  const { loading, error, data } = useQuery(query);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  try {
    if (data) {
      const link = `/colis/${data.Container_by_pk.id}`;
      return <a href={link}>{data.Container_by_pk.id}</a>;
    } else {
      return <p>Error</p>;
    }
  } catch (e) {
    return <p>Error</p>;
  }
};

const ResultCollect = ({ id }) => {
  const query = gql`
    query MyQuery3 {
  Container_by_pk(id: "${id}") {
    choosen_shop
    choosen_delivery_date
    choosen_size
    items
    id
    max_big
    max_small
    status
  }
}
    `;
  const { loading, error, data } = useQuery(query);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  try {
    if (data) {
      const link = `/bac/${data.Container_by_pk.id}`;
      return <a href={link}>{data.Container_by_pk.id}</a>;
    } else {
      return <p>Error</p>;
    }
  } catch (e) {
    return <p>Error</p>;
  }
};

const Bar = ({ restricted, title }) => {
  const [searchContainerId, setSearchContainerId] = useState("");
  const [searchId, setSearchId] = useState("");
  const [collectSearchId, setCollectSearchId] = useState("");
  const [showCollect, setShowCollect] = useState(false);
  const [show, setShow] = useState(false);
  const [showContainer, setShowContainer] = useState(false);
  const popover = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Résultat</Popover.Header>
      <Popover.Body>
        <Result id={searchId} />
      </Popover.Body>
    </Popover>
  );
  const popoverContainer = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Résultat</Popover.Header>
      <Popover.Body>
        <ResultContainer id={searchContainerId} />
      </Popover.Body>
    </Popover>
  );
  const popoverCollect = (
    <Popover id="popover-basic">
      <Popover.Header as="h3">Résultat</Popover.Header>
      <Popover.Body>
        <ResultCollect id={collectSearchId} />
      </Popover.Body>
    </Popover>
  );
  return (
    <Navbar fixed="top" style={{ backgroundColor: "#006168" }}>
      <LinkContainer to="/">
        <Navbar.Brand>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              paddingRight: "10px",
            }}
          >
            <img
              alt=""
              src="/logoBerny.png"
              width="50"
              height="50"
              className="d-inline-block align-top rounded"
            />
            <span
              style={{
                color: "white",
                fontWeight: "bold",
              }}
            >
              {title ?? "Berny Dashboard"}
            </span>
          </div>
        </Navbar.Brand>
      </LinkContainer>

      {!restricted && (
        <Navbar.Collapse className="justify-content-end d-flex">
          <Form className="d-flex justify-content-end" placement="end">
            <FormControl
              type="search"
              placeholder="Bacs de collecte"
              className="me-1"
              aria-label="Search"
              onChange={(e) => {
                if (e.target.value.length === 3) {
                  setCollectSearchId(e.target.value);
                  if (showCollect === false) {
                    setShowCollect(!showCollect);
                  }
                }
              }}
            />
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popoverCollect}
              show={showCollect}
            >
              <Button
                className="searchButton"
                style={{ backgroundColor: "#F36857" }}
              >
                Rechercher
              </Button>
            </OverlayTrigger>
          </Form>
          <div style={{ padding: "10px" }} />
          <Form className="d-flex justify-content-end" placement="end">
            <FormControl
              type="search"
              placeholder="Caisses et colis"
              className="me-1"
              aria-label="Search"
              onChange={(e) => {
                if (e.target.value.length > 3) {
                  setSearchContainerId(e.target.value);
                  if (show === false) {
                    setShowContainer(!show);
                  }
                }
              }}
            />
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popoverContainer}
              show={showContainer}
            >
              <Button
                className="searchButton"
                style={{ backgroundColor: "#F36857" }}
              >
                Rechercher
              </Button>
            </OverlayTrigger>
          </Form>
          <div style={{ padding: "10px" }} />
          <Form className="d-flex justify-content-end" placement="end">
            <FormControl
              type="search"
              placeholder="Contenant"
              className="me-2"
              aria-label="Search"
              onChange={(e) => {
                setSearchId(e.target.value);
                if (show === false) {
                  setShow(!show);
                }
              }}
            />
            <OverlayTrigger
              trigger="click"
              placement="bottom"
              overlay={popover}
              show={show}
            >
              <Button
                className="searchButton"
                style={{ backgroundColor: "#F36857" }}
              >
                Rechercher
              </Button>
            </OverlayTrigger>
          </Form>
        </Navbar.Collapse>
      )}
    </Navbar>
  );
};

export default Bar;
