export const firebaseConfig = {
  apiKey: "AIzaSyBQBWyszJa1LJyI3aHYIMxYmh87aRtYHpI",
  authDomain: "app-pro-56fcb.firebaseapp.com",
  databaseURL:
    "https://app-pro-56fcb-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "app-pro-56fcb",
  storageBucket: "app-pro-56fcb.appspot.com",
  messagingSenderId: "326719112285",
  appId: "1:326719112285:web:dcccd43d77ca9402c679b4",
};

export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear().toString();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  return `${day}/${month}/${year} à ${hours}:${minutes}:${seconds}`;
};
