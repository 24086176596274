import React, { useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import NavBar from "../../Navbar";
import gql from "graphql-tag";
import pin from "./pin.svg";
import u from "./u.svg";
import auchan from "./auchan.svg";
import gf from "./gf.svg";
import casino from "./casino.svg";
import carrefour from "./carrefour.svg";
import intermarche from "./intermarche.svg";
import leclerc from "./leclerc.svg";
import { Icon } from "leaflet";
import { useKeycloak } from "react-keycloak";
import {
	Col,
	Container,
	Row,
	ToggleButton,
	ToggleButtonGroup,
} from "react-bootstrap";
import Menu from "../../LeftMenu";
import { useQuery } from "@apollo/client";

let table = [];
const Chart = ({ viewMode }) => {
	let query;
	if (viewMode === "Industry") {
		query = gql`
      query {
        Shop(where: { isShop: { _eq: false } }) {
          name
          brand
          long
          lat
          isShop
        }
      }
    `;
	} else if (viewMode === "Shop") {
		query = gql`
      query {
        Shop(where: { isShop: { _eq: true } }) {
          name
          brand
          long
          lat
          isShop
        }
      }
    `;
	} else {
		query = gql`
      query {
        Shop {
          name
          brand
          long
          lat
          isShop
        }
      }
    `;
	}
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	table = [];
	data.Shop.forEach((element) => {
		let choosenPin = "";
		if (element.brand === "U") {
			choosenPin = u;
		} else if (element.brand === "Auchan") {
			choosenPin = auchan;
		} else if (element.brand === "Grand-Frais") {
			choosenPin = gf;
		} else if (element.brand === "Leclerc") {
			choosenPin = leclerc;
		} else if (element.brand === "Intermarché") {
			choosenPin = intermarche;
		} else if (element.brand === "Casino") {
			choosenPin = casino;
		} else if (element.brand === "Carrefour") {
			choosenPin = carrefour;
		} else {
			choosenPin = pin;
		}
		table.push(
			<Marker
				key={element.name + Date.now()}
				position={[parseFloat(element.lat), parseFloat(element.long)]}
				icon={
					new Icon({
						iconUrl: choosenPin,
						iconSize: [75, 102],
						iconAnchor: [24, 82],
					})
				}
			>
				<Popup>
					{element.brand} - {element.name}
				</Popup>
			</Marker>,
		);
	});
	return table;
};

const MapComponent = () => {
	const [viewMode, setViewMode] = useState("All");
	const [radioValue, setRadioValue] = useState(1);
	const radios = [
		{ name: "Tous", value: 1 },
		{ name: "Magasins", value: 2 },
		{ name: "Industriels", value: 3 },
	];
	const { initialized } = useKeycloak();
	return initialized ? (
		<Container className="container">
			<div>
				<NavBar />
				<Row>
					<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
						<Menu />
					</Col>
					<Col sm={11}>
						<div>
							<div style={{ marginTop: "100px", marginLeft: "15px" }}>
								<div>
									<h2 style={{ margin: "10px", textAlign: "center" }}>
										La Berny-carte
									</h2>
									<div style={{ margin: "10px", paddingTop: "65px" }}>
										<ToggleButtonGroup
											type="radio"
											name="radio"
											defaultValue={radioValue}
										>
											{" "}
											{radios.map((radio, idx) => {
												return (
													<ToggleButton
														key={radio.value}
														id={`radio-${idx}`}
														value={radio.value}
														variant="outline-success"
														checked={radioValue === radio.value}
														onChange={(e) => {
															setRadioValue(e.currentTarget.value);
															if (radio.value === 3) {
																setViewMode("Industry");
															} else if (radio.value === 2) {
																setViewMode("Shop");
															} else {
																setViewMode("All");
															}
														}}
													>
														{radio.name}
													</ToggleButton>
												);
											})}
										</ToggleButtonGroup>
										<div className="mapLeaflet">
											<MapContainer
												center={[47.218371, -1.553621]}
												zoom={9}
												scrollWheelZoom={true}
												style={{ height: "70vh", width: "60wh" }}
											>
												<TileLayer
													attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
													url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
												/>

												<Chart viewMode={viewMode} />
											</MapContainer>
										</div>
									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Container>
	) : null;
};

export { MapComponent as Map };
