import { useQuery } from "@apollo/client";
import React, { useState } from "react";
import { GET_SHOP_BY_ID } from "./Query";
import { formatDate } from "./utils";
import CustomModal from "./ModalLoans";

const UserLoanDetails = ({ loan }) => {
  const { loading, data } = useQuery(GET_SHOP_BY_ID, {
    variables: { id: loan.shop_id },
  });
  const [modalOpen, setModalOpen] = useState(false);

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <tr style={{ textAlign: "center" }}>
        <td data-label="name">{loan.item_id}</td>
        {/* ONLY IF THERE IS THIS FORMAT IN APP */}
        <td data-label="size">{loan.price === 3 ? "BM45" : "BL45"}</td>
        {/* ONLY IF THERE IS THIS FORMAT IN APP */}
        <td data-label="shop">
          {loading
            ? "Chargement ..."
            : `${data.Shop_by_pk.brand} ${data.Shop_by_pk.name}`}
        </td>
        <td data-label="date_loan">{formatDate(loan.date_loan)}</td>
        <td data-label="date_loan_return">{formatDate(loan.date_to_return)}</td>
        <td data-label="returned">{loan.is_returned ? "Oui" : "Non"}</td>
        <td data-label="paid">{loan.is_paid ? "Oui" : "Non"}</td>
        <td data-label="modification">
          <button className="ui teal button" onClick={openModal}>
            Modifier
          </button>
        </td>
      </tr>

      <CustomModal
        loanId={loan.id}
        id={loan.item_id}
        date_to_return={loan.date_to_return}
        shopId={data?.Shop_by_pk.id}
        open={modalOpen}
        onClose={closeModal}
        header={`Modification des données de l'emprunt ${loan.item_id}`}
      />
    </>
  );
};

export default UserLoanDetails;
