import { useKeycloak } from "react-keycloak";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../../Navbar";
import Menu from "../../LeftMenu";
import React from "react";
import gql from "graphql-tag";
import { Bar } from "react-chartjs-2";
import { useQuery } from "@apollo/client";

const Chart = () => {
	const query = gql`
    query MyQuery {
      stepCleaningAndDelivery(where: { ecart: { _gte: 0 } }) {
        ecart
      }
    }
  `;
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const groupBy = data.stepCleaningAndDelivery.reduce(function (acc, curr) {
		return acc[curr.ecart] ? ++acc[curr.ecart] : (acc[curr.ecart] = 1);
	}, {});
	const selectedData = {
		labels: Object.keys(groupBy),
		datasets: [
			{
				label: "Occurence",
				data: Object.values(groupBy),
				backgroundColor: "rgb(162,253,77)",
				borderColor: "rgb(255,255,255)",
				borderWidth: 1,
			},
		],
	};
	const options = {
		scales: {
			maintainAspectRatio: false,
			y: {
				min: 0,
				stepSize: 10,
			},
		},
		plugins: {
			legend: {
				display: false,
			},
			datalabels: {
				display: true,
				labels: {
					title: {
						color: "black",
						align: "end",
						anchor: "end",
						font: {
							weight: "bold",
						},
					},
				},
			},
			title: {
				display: true,
				text: `Pour tous les contenants ayant 1 livraison et 1 lavage (${data.stepCleaningAndDelivery.length} lignes concernées)`,
			},
			tooltip: {
				callbacks: {
					title: function (context) {
						const label = context[0].label;
						return `Ecart: ${label}`;
					},
				},
			},
		},
	};
	return <Bar data={selectedData} options={options} />;
};
const StepCleaningAndDelivery = () => {
	const { initialized } = useKeycloak();
	return initialized ? (
		<Container className="container">
			<div>
				<NavBar />
				<Row>
					<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
						<Menu />
					</Col>
					<Col sm={10}>
						<h2 style={{ marginTop: "100px" }}>
							Ecart en jours entre la date de lavage et la date de livraison
						</h2>
						<Chart />
					</Col>
				</Row>
			</div>
		</Container>
	) : null;
};

export { StepCleaningAndDelivery };
