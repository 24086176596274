import { useQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { GET_LOANS_BY_USER_ID } from "./Query";
import UserLoanDetails from "./UserLoanDetails";
import { formatDate } from "./utils";
import ModalNotification from "./ModalNotification";
import ModalUpdateBalance from "./ModalUpdateBalance";

const UserDetails = ({ data }) => {
  const [userLoanDetails, setUserLoanDetails] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const { refetch: refetchLoans } = useQuery(GET_LOANS_BY_USER_ID, {
    variables: { id_user: data.id },
    skip: true,
  });

  useEffect(() => {
    const fetchUserLoanDetails = async (userId) => {
      try {
        const result = await refetchLoans({ id_user: userId });
        setUserLoanDetails(result.data.loans);
      } catch (error) {
        console.error("Error fetching user loans:", error);
      } finally {
        setIsLoading(false);
      }
    };
    if (data?.id) {
      fetchUserLoanDetails(data.id);
    }
  }, [data.id, refetchLoans]);

  return (
    <UserDetailsTable
      data={data}
      userLoanDetails={userLoanDetails}
      isLoading={isLoading}
    />
  );
};

const UserDetailsTable = ({ data, userLoanDetails, isLoading }) => {
  const [modalNotificationOpen, setModalNotificationOpen] = useState(false);
  const [modalPotOpen, setmodalPotOpen] = useState(false);

  const openModalPot = () => {
    setmodalPotOpen(true);
  };
  const openModalNotification = () => {
    setModalNotificationOpen(true);
  };

  const closeModalPot = () => {
    setmodalPotOpen(false);
  };

  const closeModalNotification = () => {
    setModalNotificationOpen(false);
  };
  return (
    <>
      <Row>
        <Col sm={3} style={{ paddingLeft: 10, paddingRight: 0 }}>
          <div className="ui small image">
            {data.attributes?.picture?.[0] &&
            data.attributes.picture[0] !== "" ? (
              <img src={data.attributes.picture[0]} alt="profil" />
            ) : (
              <img src="/avatar-anonyme.png" alt="profil" />
            )}
          </div>
        </Col>
        <Col xs={4}>
          <div style={{ marginBottom: "15px" }}>
            Date de naissance :
            {data.attributes?.birthdate?.[0] &&
            data.attributes?.birthdate[0] !== "" ? (
              <span> {data.attributes?.birthdate[0]}</span>
            ) : (
              <span> Non renseigné</span>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            Téléphone :
            {data.attributes?.phone?.[0] && data.attributes?.phone[0] !== "" ? (
              <span> {data.attributes?.phone[0]}</span>
            ) : (
              <span> Non renseigné</span>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            Code postal :
            {data.attributes?.zipcode?.[0] &&
            data.attributes?.zipcode[0] !== "" ? (
              <span> {data.attributes?.zipcode[0]}</span>
            ) : (
              <span> Non renseigné</span>
            )}
          </div>
          <div style={{ marginBottom: "15px" }}>
            Date d'inscription : {formatDate(data.createdTimestamp)}
          </div>
          <div style={{ marginBottom: "15px" }}>
            Nombre d'emprunt : {userLoanDetails.length}
          </div>
        </Col>
        <Col xs={4}>
          <button
            onClick={openModalNotification}
            style={{ marginBottom: "15px" }}
            className="ui teal button"
          >
            Envoyer une notification
          </button>
          <button
            onClick={openModalPot}
            style={{ marginBottom: "15px" }}
            className="ui teal button"
          >
            Créditer la cagnotte
          </button>
        </Col>
      </Row>
      {isLoading ? (
        <div>Loading...</div>
      ) : userLoanDetails.length > 0 ? (
        <>
          <h3> Liste des emprunts</h3>
          <table className="ui celled table">
            <thead>
              <tr>
                <th>Nom du contenant</th>
                <th>Taille du contenant</th>
                <th>Magasin d'emprunt</th>
                <th>Date d'emprunt</th>
                <th>Date de retour</th>
                <th>Retourné</th>
                <th>Payé</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {userLoanDetails.map((loan) => (
                <UserLoanDetails key={loan.id} loan={loan} />
              ))}
            </tbody>
          </table>
        </>
      ) : (
        <h3>😞 Pas d'emprunt 😞</h3>
      )}
      <ModalNotification
        userId={data.id}
        open={modalNotificationOpen}
        onClose={closeModalNotification}
        header={`Envoie de notification`}
      />

      <ModalUpdateBalance
        userId={data.id}
        open={modalPotOpen}
        onClose={closeModalPot}
        header={`Mise à jour cagnotte utilisateur`}
      />
    </>
  );
};

export default UserDetails;
