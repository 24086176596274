import { useQuery } from "@apollo/client";

import gql from "graphql-tag";
import { convertArrayToGqlString } from "../../Tools";

function MakeMyQuery(query) {
  const { loading, error, data } = useQuery(query);
  if (loading) return { success: false, msg: "Loading..." };
  if (error) return { success: false, msg: error.message };
  return { success: true, data: data };
}

function GetListOfContainerByShopID(shopID) {
  const query = gql`
    query MyQuery {
        Container(where: {choosen_shop: {_eq: "${shopID}"}, id: {_regex: "^.{3,3}$"}}) {
          id
          items
          status
        }
      }`;
  return MakeMyQuery(query);
}

function GetListOfColisByShopID(shopID) {
  const query = gql`
    query MyQuery {
        Delivery(distinct_on: transportedIn, where: {shop: {_eq: "${shopID}"}}) {
          delivery_date
          transportedIn
        }
      }`;
  return MakeMyQuery(query);
}

function GetShopDataByID(shopID) {
  const query = gql`
    query MyQuery {
        Shop(where: {id: {_eq: "${shopID}"}}) {
        id
        brand
        name
        }
      }`;
  return MakeMyQuery(query);
}

function GetShopWithClosePackage() {
  const query = gql`
    query MyQuery {
      nonOpenContainerByShop(where: { isShop: { _eq: true } }) {
        count
        shop
        brand
        name
      }
    }
  `;
  return MakeMyQuery(query);
}

function GetContainerShopEnd(shopID) {
  const containerShopEndQuery = gql`
    query ContainerShopEndQuery{
      containerShopEnd(where: { shop: { _eq: "${shopID}" } }) {
        container
      }
    }
  `;

  return containerShopEndQuery;
}

function GetPackagesDataByShop(shopID, containerShopEnd) {
  const query = gql`
    query MyQuery {
        usedContainerByShopGetStatus_aggregate(where: {shop: {_eq: "${shopID}"}}) {
          aggregate {
            count
          }
        }
        usedContainerByShopGetStatus(where: {shop: {_eq: "${shopID}"}, container: {_neq: "${containerShopEnd}"}}) {
          status
          container
          sumdelivery
          sumreturned
          delivery_date
        }
      }`;
  return query;
}

function GetItemsData(itemsList) {
  const query = gql`
    query MyQuery {
        Item(where: {id: {_in: ${convertArrayToGqlString(itemsList)}}}) {
          id
          size
        }
      }`;
  return MakeMyQuery(query);
}

export {
  GetListOfContainerByShopID,
  GetItemsData,
  GetShopDataByID,
  GetListOfColisByShopID,
  GetContainerShopEnd,
  GetPackagesDataByShop,
  GetShopWithClosePackage,
};
