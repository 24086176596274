import { gql } from "@apollo/client";

export const INSERT_ITEM_MUTATION = gql`
  mutation MyMutation($id: String!, $size: String, $type: String!) {
    insert_Item(
      objects: {
        id: $id
        in_transit: false
        size: $size
        arrival_country: "France - Cerizay"
        type: $type
      }, on_conflict: {constraint: Item_pkey}
    ) {
      affected_rows
    }
  }
`;
