import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import { convertArrayToGqlString } from "../../Tools";

function GetListOfContainerByShopID(shopID) {
  const query = gql`
    query MyQuery {
        Container(where: {choosen_shop: {_eq: "${shopID}"}, id: {_regex: "^.{3,3}"}}) {
          id
          items
          status
          usedFor
          updatedDate
        }
      }`;
  const { loading, error, data } = useQuery(query);
  if (loading) return { success: false, msg: "Loading..." };
  if (error) return { success: false, msg: error.message };
  return { success: true, data: data };
}

function GetShopDataByID(shopID) {
  const query = gql`
      query MyQuery {
          Shop(where: {id: {_eq: "${shopID}"}}) {
              id
              brand
              name
          }
      }`;
  const { loading, error, data } = useQuery(query);
  if (loading) return { success: false, msg: "Loading..." };
  if (error) return { success: false, msg: error.message };
  return { success: true, data: data };
}

function GetItemsData(itemsList) {
  const query = gql`
      query MyQuery {
          Item(where: {id: {_in: ${convertArrayToGqlString(
            itemsList ?? []
          )}}}) {
              id
              size
              Format {
                name
                weight
                length
                width
                depth
                capacity
              }
          }
      }`;
  const { loading, error, data } = useQuery(query);
  if (loading) return { success: false, msg: "Loading..." };
  if (error) return { success: false, msg: error.message };
  return { success: true, data: data };
}

export { GetListOfContainerByShopID, GetItemsData, GetShopDataByID };
