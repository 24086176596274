import React from "react";
import NavBar from "../../Navbar";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import LeftMenu from "../../LeftMenu";
import { SelectShopComponent } from "../generic/SelectShopComponent";
import { OperationAllInfos } from "./dashboardPackage/OperationAllInfos";
import { GetShopWithClosePackage } from "./dashboardPackage/Query.js";
import ContainerControl from "../logistic/ContainerControl";

/**
 * principal component for the special operation page
 * @returns
 */
const OperationPage = () => {
	const { initialized, keycloak} = useKeycloak();
	const shopPackageStatus = GetShopWithClosePackage();
	console.log(keycloak.token)
	return initialized ? keycloak.authenticated ? (
		<Container className="container">
			<NavBar />
			<Row>
				<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
					<LeftMenu />
				</Col>
				<Col
					sm={11}
					xl={10}
					style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
				>
					<div>
						<ContainerControl />
						{shopPackageStatus.success ? (
							<OperationAllInfos shopPackageStatus={shopPackageStatus} />
						) : null}
						<SelectShopComponent urlPrefix={"/operation/shop/"} />
					</div>
				</Col>
			</Row>
		</Container>
	): null: null;
};

export { OperationPage };
