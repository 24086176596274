import React, { useMemo, useEffect, useState } from "react";
import { useTable, useSortBy } from "react-table";
import { GET_SHOP_BY_ID, DELETE_SALE } from "./Query";
import { useLazyQuery, useMutation } from "@apollo/client";
import { FaTrash } from "react-icons/fa";
import { Button } from "semantic-ui-react";

const ListSales = ({ data, onRefresh }) => {
  const [shopNames, setShopNames] = useState({});
  const [loadingShopIds, setLoadingShopIds] = useState(new Set());
  const [getShopName] = useLazyQuery(GET_SHOP_BY_ID);

  useEffect(() => {
    // Fetch shop names for each unique shop ID in the data
    const uniqueShopIds = Array.from(new Set(data.map((row) => row.Shop)));

    // Filter out already fetched shop names
    const pendingShopIds = uniqueShopIds.filter((shopId) => !shopNames[shopId]);

    // If there are pending shop names, fetch them using Promise.all
    if (pendingShopIds.length > 0) {
      setLoadingShopIds(new Set(pendingShopIds));

      // Create an array of promises for each shop name query
      const promises = pendingShopIds.map((shopId) =>
        getShopName({ variables: { id: shopId } })
      );

      // Wait for all the promises to resolve using Promise.all
      Promise.all(promises).then((results) => {
        const newShopNames = {};
        results.forEach((result) => {
          if (result.data?.Shop_by_pk) {
            newShopNames[result.data.Shop_by_pk.id] =
              result.data.Shop_by_pk.name;
          }
        });
        setShopNames((prevShopNames) => ({
          ...prevShopNames,
          ...newShopNames,
        }));
        setLoadingShopIds(new Set());
      });
    }
  }, [data, getShopName, shopNames]);

  const [deleteSale] = useMutation(DELETE_SALE, {
    onCompleted: () => {
      // After deleting the sale, refresh the component to reflect the updated data
      onRefresh();
    },
    onError: (error) => {
      console.error("Error while deleting sale:", error.message);
    },
  });

  const handleDeleteSale = (saleId) => {
    if (saleId !== undefined) {
      console.log(saleId);
      const id = saleId.toString();
      deleteSale({ variables: { saleId: id } });
    } else {
      console.error("Error: saleId is undefined");
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Magasin", // Header text for the column
        accessor: (row) => shopNames[row.Shop] || "Loading...",
      },
      {
        Header: "Date",
        accessor: "week",
        Cell: ({ value }) => {
          const formattedDate = formatDate(value);
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Quantité",
        accessor: "amount",
      },
      {
        Header: "Rayon",
        accessor: "section",
      },
      {
        Header: "Date création",
        accessor: "created_at",
        Cell: ({ value }) => {
          const formattedDate = formatDate(value);
          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Actions",
        accessor: "id",
        Cell: ({ value }) => (
          <Button onClick={() => handleDeleteSale(value)}>
            <FaTrash />
          </Button>
        ),
      },
    ],
    [shopNames]
  );

  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: {
          sortBy: [{ id: "created_at", desc: true }], // Set the default sorting
        },
      },
      useSortBy
    );

  return (
    <table className="table table-striped"
      {...getTableProps()}
      style={{ border: "1px solid #006168", marginBottom: "15px" }}
    >
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()} style={{textAlign: "center", verticalAlign: "middle"}}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                style={{
                  padding: "10px",
                  borderBottom: "solid 3px red",
                  background: "#F4F6F8",
                  color: "rgba(0, 0, 0, 0.87)",
                  fontWeight: "bold",
                }}
              >
                {column.render("Header")}
                <span>
                  {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row) => {
          prepareRow(row);
          return (
            <tr
              {...row.getRowProps()}
              style={{ borderBottom: "1px solid #006168",textAlign: "center", verticalAlign: "middle"}}
            >
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{
                      padding: "10px",
                      border: "solid 1px gray",
                      background: "white",
                    }}
                  >
                    {cell.render("Cell")}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default ListSales;
