import React, { useEffect, useState } from "react";
import {Button, Col, Container, Form, Row} from "react-bootstrap";
import LeftMenu from "../../../LeftMenu";
import NavBar from "../../../Navbar";
import { initializeApp } from "firebase/app";
import {getDatabase, ref, get, child, push, update} from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { useParams } from "react-router-dom";
import { firebaseConfig } from "../utils";
import { getAuth, signInAnonymously } from "firebase/auth";
import { formatDate } from "../utils";



const AnomalyDetails = () => {
  const app = initializeApp(firebaseConfig);
  const date = new Date()
  const params = useParams();
  let anomalyId = params.id;
  const anomalyDate = params.child;
  const anomalyIndex = params.creator;
  const [anomalyData, setAnomalyData] = useState([]);
  const [url, setUrl] = useState();
  const [deal, setDeal] = useState(false);
  const path = `/suivi/${anomalyIndex}/${anomalyDate}/${anomalyId}/`;
  const username = "berny"

  const onSubmit = (event) => {
    event.preventDefault()
    const dbRef = ref(getDatabase(app, "https://app-pro-56fcb-default-rtdb.europe-west1.firebasedatabase.app/"));
    const newPost = push(child(dbRef, path +"steps")).key;
    const updates = {};
    updates[path + "steps/"+ newPost] = {
      "date": date.toLocaleDateString() + "-" + date.toLocaleTimeString(),
      "operator": username,
      "comments": event.target.description.value,
      "seen": "false",
      "status": "Répondu par berny"
    }
    update(dbRef, updates).then((result) => {
      window.location.reload();
    }).catch((error) => {
      console.log(error)
    });
  }

  const onChangeDeal = () => {
    const dbRef = ref(getDatabase(app, "https://app-pro-56fcb-default-rtdb.europe-west1.firebasedatabase.app/"));
    const updates = {};
    updates[`${path}/dealt`] = !deal;
    update(dbRef, updates).then((result) => setDeal(!deal))
  }

  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
        "6Led7IQpAAAAACyLa4k7hh0YivDqRBCMwvaFhzDI" // Replace with your ReCaptcha Site Key
    ),
    isTokenAutoRefreshEnabled: true,
  });

  const auth = getAuth();
  const findDealtFalse = (data) => {
    const filteredItems = [];
    Object.keys(data).forEach((key) => {

      const currentItem = data[key];
      if(typeof data[key] == "object"){
        filteredItems.push({ key, data: currentItem });
      }else if(key === "urlPicture"){
        setUrl(data[key])
      }
      else if(key === "dealt"){
        setDeal(data[key])
      }
    });
    return filteredItems;
  };



  useEffect(() => {
    signInAnonymously(auth).then(() => {
      if(anomalyId.toString().includes("?")){
        anomalyId = anomalyId.replaceAll('?', "")
      }
      const dbRef = ref(getDatabase(app, "https://app-pro-56fcb-default-rtdb.europe-west1.firebasedatabase.app/"));
      get(child(dbRef, path))
          .then(async (snapshot) => {
          if (snapshot.exists()) {
            const ref = await findDealtFalse(snapshot.val());
            Object.keys(ref).map((key) => {
              if (typeof ref[key] === "object") {
                Object.values(ref[key]).map((result, index2, array2) => {
                  if (typeof result === "object") {
                    setAnomalyData(Object.values(result))
                  }
                });
              }
              else{
                console.log(ref)
              }
            });
          } else {
            console.log("No data found");
          }
          })
          .catch((error) => {
            console.log(error.toString());
          });
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = () => {
    onChangeDeal();
  };

  return <Container className="container">
      <NavBar />
      <Row>
        <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <LeftMenu />
        </Col>
        <Col
          xs={10}
          style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
        >
          <Row>
            <Col xs={10} style={{ paddingLeft: 10, paddingRight: 0 }}>
              {/* REPLACE anomalyData.NAME BY anomalyData.CREATOR WHEN CHANGED IN DB */}
              <h2>{`Anomalie créée par ${anomalyIndex} le ${formatDate(
                anomalyDate
              )}`}</h2>
              <h3> État anomalie</h3>
             <div className="ui toggle checkbox" style={{ marginTop: "15px" }}>
                  <input
                      type="checkbox"
                      name="deal"
                      onChange={handleCheckboxChange}
                      checked={deal}
                  />
                  <label>
                    {deal ? "Traitée" : "Non traitée"}
                  </label>
                </div>
              <h3> Description visuelle de l'anomalie</h3>
              <div className="ui medium image" style={{display: 'flex', justifyContent: 'center'}}>
                {url !== "Sans photo" ? <img src={url} alt="anomaly" style={{display: 'flex', justifyContent: 'center'}} />: url}
              </div>
              <h3>Historique de l'anomalie</h3>
              <Form onSubmit={onSubmit}>
              <table className="ui celled table striped">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Description</th>
                    <th>Emetteur</th>
                    <th>Statut</th>
                  </tr>
                </thead>
                <tbody>
                  {typeof anomalyData === "object" ? (

                   anomalyData.map((key, index, array) => {
                      if (typeof key === "object") {
                            return <tr>
                              <td data-label="date">
                                {key.date}
                              </td>
                              <td data-label="comment">
                                {key.comments}
                              </td>
                              {/* CHANGE .NAME BY .CREATOR AFTER CHANGING DB */}
                              <td data-label="creator">
                                {key.operator}
                              </td>
                              <td data-label="status">
                                {key.status}
                              </td>
                            </tr>
                        }
                       else {
                        return <tr>
                          <td>... Chargement1</td>
                          <td>... Chargement1</td>
                          <td>... Chargement1</td>
                          <td>... Chargement1</td>
                        </tr>
                      }

                    })) :
                      <tr>
                      <td>{"test"}</td>
                      <td>{anomalyData}</td>
                      <td>... Chargement</td>
                      <td>... Chargement</td>
                    </tr>
                  }

                    <tr>
                      <td data-label="date">
                        <Form.Control type="text" placeholder={date.toLocaleDateString() + "-" + date.toLocaleTimeString()} readOnly />
                      </td>
                      <td data-label="comment">
                        <Form.Group className="mb-3">
                          <Form.Control as="textarea" rows={3} name={"description"}/>
                          <br/>
                          <Button className="btn btn-primary btn-lg btn-block" style={{justifyContent: "center"}} type={"submit"} >Valider</Button>
                        </Form.Group>
                      </td>
                      <td data-label="creator">
                        <Form.Control type="text" placeholder={username} readOnly />
                      </td>
                      <td data-label="status">
                        <Form.Control type="text" placeholder="Répondu par Berny" readOnly />
                      </td>
                    </tr>
                </tbody>
              </table>
                <br/>
              </Form>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
};

export default AnomalyDetails;
