import React from "react";
import { useKeycloak } from "react-keycloak";
import { Col, Container, Row } from "react-bootstrap";
import LeftMenu from "../../LeftMenu";
import NavBar from "../../Navbar";
import NewItemsForm from "./NewItemsForm";

const NewItems = () => {
  const { initialized } = useKeycloak();

  return initialized ? (
    <Container className="container">
      <NavBar />
      <Row>
        <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <LeftMenu />
        </Col>
        <Col
          xs={12}
          style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
        >
          <h2>Ajouter des contenants</h2>
          <NewItemsForm />
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default NewItems;
