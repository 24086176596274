import React from "react";
import gql from "graphql-tag";
import { Col, Container, Row, Table } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { useKeycloak } from "react-keycloak";
import NavBar from "../../Navbar";
import Menu from "../../LeftMenu";
import { useQuery } from "@apollo/client";

const ActualStock = () => {
	const da = new Date();
	const d = da.toISOString().split("T")[0];
	const query = gql`
query MyQuery {
  Container(where: {status: {_eq: "ready"}, choosen_delivery_date: {_gte: "${
		d.split("T")[0]
	}"}}) {
    id
    status
    max_big
    max_small
    items
    choosen_size
    choosen_delivery_date
    choosen_shop
  }
}
`;

	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const allContainers = [];
	let grandsSpencer = 0;
	let petitsSpencer = 0;
	let grandsDegrenne = 0;
	let petitsDegrenne = 0;
	data.Container.forEach((el) => {
		if (el.max_small === 75) {
			if (el.choosen_size === "small") {
				petitsSpencer = petitsSpencer + el.max_small;
			} else {
				grandsSpencer = grandsSpencer + el.max_big;
			}
		} else {
			if (el.choosen_size === "small") {
				petitsDegrenne = petitsDegrenne + el.max_small;
			} else {
				grandsDegrenne = grandsDegrenne + el.max_big;
			}
		}
		allContainers.push(
			<tr>
				<td>{el.id.length === 4 ? `Colis ${el.id}` : `Bac ${el.id}`}</td>
				<td>{el.choosen_size}</td>
				<td>{el.choosen_size === "small" ? el.max_small : el.max_big}</td>
				<td>{el.max_small === 75 ? "Spencer" : "Degrenne"}</td>
				<td>{el.choosen_delivery_date.split("T")[0]}</td>
				<NomMagasin shop={el.choosen_shop} />
				<td>{el.items}</td>
			</tr>,
		);
	});
	const back = [];
	const dat = [
		"Petits Spencer",
		"Grands Spencer",
		"Petits Degrenne",
		"Grands Degrenne",
	];
	dat.forEach((el) => {
		const r = Math.floor(Math.random() * 255);
		const g = Math.floor(Math.random() * 255);
		const b = Math.floor(Math.random() * 255);
		back.push(`rgb(${r},${g},${b}, 1)`);
	});
	const datas = {
		labels: dat,
		datasets: [
			{
				label: "Contenants",
				data: [petitsSpencer, grandsSpencer, petitsDegrenne, grandsDegrenne],
				backgroundColor: [
					"rgba(255, 99, 132, 1)",
					"rgba(54, 162, 235, 1)",
					"rgba(255, 206, 86, 1)",
					"rgba(75, 192, 192, 1)",
				],
				borderWidth: 1,
			},
		],
	};
	const total = petitsSpencer + grandsSpencer + petitsDegrenne + grandsDegrenne;
	const plugins = [
		{
			beforeDraw: function (chart) {
				const width = chart.width;
				const height = chart.height;
				const ctx = chart.ctx;
				ctx.restore();
				const fontSize = (height / 160).toFixed(2);
				ctx.font = `${fontSize}em sans-serif`;
				ctx.textBaseline = "top";
				const text = `Total: ${total}`;
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 2;
				ctx.fillText(text, textX, textY);
				ctx.save();
			},
		},
	];
	const options = {
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: true,
			},
			datalabels: {
				display: true,
				color: "white",
				align: "center",
				labels: {
					title: {
						font: {
							weight: "bold",
						},
					},
					value: {
						color: "white",
					},
				},
				formatter: function (value) {
					if (value !== 0) {
						return "\n" + parseFloat(value).toFixed(0);
					} else return "";
				},
			},
		},
	};
	return (
		<div style={{ height: "400px" }}>
			<Doughnut data={datas} plugins={plugins} options={options} />
			<div className="metric">
				<div style={{ width: "100%", margin: "5%" }}>
					<span style={{ fontWeight: "bold" }}>Détail colis et bacs prêts</span>
					<Table striped bordered hover variant="dark">
						<thead>
							<tr>
								<th>Identifiant</th>
								<th>Taille de contenants</th>
								<th>Nombre de contenants</th>
								<th>Type de contenants</th>
								<th>Date d'enlèvement</th>
								<th>Magasin choisi</th>
								<th>Id des contenants</th>
							</tr>
						</thead>
						<tbody>{allContainers}</tbody>
					</Table>
				</div>
			</div>
		</div>
	);
};

const NomMagasin = ({ shop }) => {
	const query = gql`
    query {
  Shop_by_pk(id: "${shop}") {
    brand
    name
  }
}
    `;
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	return <td>{`${data.Shop_by_pk.brand} - ${data.Shop_by_pk.name}`}</td>;
};

const ShippingClose = () => {
	const { initialized } = useKeycloak();
	return initialized ? (
		<Container className="container">
			<div>
				<NavBar />
				<Row>
					<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
						<Menu />
					</Col>
					<Col sm={11} xl={9}>
						<div>
							<div style={{ marginTop: "100px", marginLeft: "15px" }}>
								<div>
									<h5 style={{ margin: "10px", textAlign: "left" }}>
										Cartons/colis prêts pour livraison
									</h5>
									<div className="metrics">
										<ActualStock />
									</div>
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Container>
	) : null;
};
export { ShippingClose };
