import { useQuery } from "@apollo/client";
import "chartjs-plugin-datalabels";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Doughnut } from "react-chartjs-2";
import { FaCalendar } from "react-icons/fa";
import { RiFileDamageFill } from "react-icons/ri";
import { useKeycloak } from "react-keycloak";
import { Accordion, Feed, Icon, Image, Loader } from "semantic-ui-react";
import Menu from "../../LeftMenu";
import NavBar from "../../Navbar";
import { client } from "../..";

const Content = ({ groupBy = "date" }) => {
	const query = gql`
		{
			Delivery (order_by: {delivery_date: desc}) {
				id
				Item {
					id
					Format {
						name
					}
				}
				Shop {
					id
					brand
					name
				}
				delivery_date
				transportedIn
			}
		}
	`;

	const { loading, error, data } = useQuery(query, {
		fetchPolicy: "no-cache",
	});
	if (loading) return <Loader active inline="centered" />;
	if (error) return `Error! ${error.message}`;

	let groupedDeliveries = {};

	if (groupBy === "shop") {
		groupedDeliveries = data.Delivery.reduce((acc, delivery) => {
			const { delivery_date, Shop } = delivery;
			const shopId = `${Shop.brand} - ${Shop.name}`;
			if (!acc[shopId]) {
				acc[shopId] = {};
			}
			if (!acc[shopId][delivery_date]) {
				acc[shopId][delivery_date] = [];
			}
			acc[shopId][delivery_date].push(delivery);
			return acc;
		}, {});
	} else {
		groupedDeliveries = data.Delivery.reduce((acc, delivery) => {
			const { delivery_date, Shop } = delivery;
			const shopId = `${Shop.brand} - ${Shop.name}`;
			if (!acc[delivery_date]) {
				acc[delivery_date] = {};
			}
			if (!acc[delivery_date][shopId]) {
				acc[delivery_date][shopId] = [];
			}
			acc[delivery_date][shopId].push(delivery);
			return acc;
		}, {});
	}

	let sortedGroupedDeliveries = {};

	if (groupBy === "shop") {
		Object.keys(groupedDeliveries)
			.sort()
			.forEach((key) => {
				sortedGroupedDeliveries[key] = groupedDeliveries[key];
			});
	} else {
		sortedGroupedDeliveries = groupedDeliveries;
	}

	return <NestedFeeds content={sortedGroupedDeliveries} />;
};

const shopIcon = (shop) => {
	let url;

	switch (shop.split(" - ")[0]) {
		case "U":
			url = "/u.png";
			break;
		case "Leclerc":
			url =
				"/1200px-Logo_E.Leclerc_Sans_le_texte.svg.png"
			break;
		case "Intermarché":
			url =
				"/730-dmwew3stwq.webp"
			break;
		case "Sodebo":
			url =
				"/1200px-Logosodebo.svg.png"
			break;
		case "Auchan":
			url =
			"/Auchan-Logo.png"
			break;
		case "Carrefour":
			url =
				"/kisspng-logo-carrefour-market-brand-image-startup-weekend-monaco-2017-retour-sur-la-4ème-5b677e2a9f4257.9785071315335091626523.jpg"
			break;
		case "Salon":
			url = "https://www.prodandpack.com/favicon.ico";
			break;
		case "Petitgas":
			url =
				"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTckiPRW75tvOhgY_Nwhsx0aPm8mIp8RoatJx-Kfgs&s";
			break;
		case "Guelt":
			url =
				"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ6kqdbj_cD5p1pGLPjbDaOwHBN5T6PwTmrzg4cKSxFLg&s";
			break;
		case "Berny":
			url = "/logoBerny.png";
			break;
		default:
			break;
	}

	return url ? (
		<Image src={url} size="mini" />
	) : (
		<div
			style={{
				backgroundColor: "white",
				borderRadius: "50%",
				width: "40px",
				height: "40px",
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<RiFileDamageFill size={24} color="lightgrey" />
		</div>
	);
};

const NestedFeeds = ({ content }) => {
	const isDate = (title) => {
		return !isNaN(Date.parse(title));
	};

	return Object.keys(content).map((key, index) => {
		return (
			<Feed key={key}>
				<Feed.Event>
					<Feed.Label>
						{isDate(key) ? (
							<div
								style={{
									backgroundColor: "white",
									borderRadius: "50%",
									width: "40px",
									height: "40px",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
								}}
							>
								<FaCalendar size={24} color="black" />
							</div>
						) : (
							shopIcon(key)
						)}
					</Feed.Label>
					<Feed.Content>
						<NestedFeedContent content={content[key]} title={key} />
					</Feed.Content>
				</Feed.Event>
			</Feed>
		);
	});
};

const isDate = (title) => {
	return !isNaN(Date.parse(title));
};

const NestedFeedContent = ({ content, title }) => {
	const [open, setOpen] = useState(false);

	const handleClick = () => {
		setOpen(!open);
	};

	return (
		<>
			<Feed.Summary>
				{isDate(title)
					? new Date(title).toLocaleDateString(
							// 12 janvier 2021
							{
								weekday: "long",
								year: "numeric",
								month: "long",
								day: "numeric",
							},
					  )
					: title}
			</Feed.Summary>
			{Array.isArray(content) ? (
				<DeliveryInformation delivery={content} title={title} />
			) : isDate(title) ? (
				<NestedFeeds content={content} />
			) : (
				<Accordion>
					<Accordion.Title active={open} onClick={handleClick}>
						<Icon name='dropdown' />
						{Array.isArray(content)
							? `${content.length} contenants`
							: isDate(title)
							? Object.keys(content).join(", ")
							: `${Object.keys(content).length} livraison${
									Object.keys(content).length > 1 ? "s" : ""
							  }`}
					</Accordion.Title>
					<Accordion.Content active={open}>
						<NestedFeeds content={content} />
					</Accordion.Content>
				</Accordion>
			)}
		</>
	);
};

const DeliveryInformation = ({ delivery, title }) => {
	const [open, setOpen] = useState(false);
	const [queryRun, setQueryRun] = useState(false);
	const [deliveryBack, setDeliveryBack] = useState([]);

	const handleClick = () => {
		setOpen(!open);
		if (!queryRun) {
			const shopId = delivery[0].Shop.id;
			const date = delivery[0].delivery_date;
			const itemIds = delivery.map((item) => item.Item.id);

			const query = gql`
				query findReturns($shopId: uuid!, $date: timestamptz!, $itemIds: [String!]!) {
					Delivery_back(
						where: {
							Item: { id: { _in: $itemIds } }
							Shop: { id: { _eq: $shopId } }
							date: { _gt: $date }
						}
						order_by: { date: asc }
					) {
						id
						date
						Item {
							id
							Format {
								name
							}
						}
						Shop {
							id
							brand
							name
						}
					}
				}
			`;

			client
				.query({
					fetchPolicy: "no-cache",
					query,
					variables: { shopId, date, itemIds },
				})
				.then((result) => {
					setDeliveryBack([...result.data.Delivery_back]);
					setQueryRun(true);
				})
				.catch((error) => {
					console.error(error);
				});
		}
	};

	const listeColis = delivery.reduce((acc, item) => {
		const parcelId = item.transportedIn?.match(/COLIS (\d*)/)
			? item.transportedIn.match(/COLIS (\d*)/)[1]
			: item.transportedIn;
		const parcelFormat = item.Item?.Format?.name;
		const parcelName = item.transportedIn
			? item.transportedIn?.match(/COLIS (\d*)/)
				? item.transportedIn
				: `IFCO ...${parcelId?.slice(-8)}`
			: "Colis inconnu";
		if (!acc[item.transportedIn]) {
			acc[item.transportedIn] = (
				<React.Fragment key={item.transportedIn}>
					<a href={`/colis/${parcelId}`}>{parcelName}</a>
					{parcelFormat && ` (${parcelFormat})`}
				</React.Fragment>
			);
		}
		return acc;
	}, {});

	return (
		<Accordion>
			<Accordion.Title active={open} onClick={handleClick}>
				<Icon name='dropdown' />
				{Array.isArray(delivery)
					? `${delivery.length} contenants`
					: isDate(title)
					? Object.keys(delivery).join(", ")
					: `${Object.keys(delivery).length} livraison${
							Object.keys(delivery).length > 1 ? "s" : ""
					  }`}{" "}
				({open ? "cacher" : "afficher"} les détails)
			</Accordion.Title>
			<Accordion.Content active={open}>
				<Row
					style={{
						height: "30em",
					}}
				>
					<Col sm={6}>
						<GraphiqueContenants items={delivery} />
					</Col>
					<Col>
						<div
							style={{
								height: "100%",
								overflowY: "scroll",
								boxShadow: "inset 0 0 10px rgba(0, 0, 0, 0.2)",
								padding: "1em",
							}}
						>
							<span style={{ fontWeight: "bold", fontSize: "1.2em" }}>
								Informations sur les livraisons :<br />
							</span>
							<span style={{ fontWeight: "bold" }}>
								Nombre actuel de retours :
							</span>{" "}
							{queryRun ? (
								deliveryBack.length
							) : (
								<Loader inline='centered' active size="tiny" />
							)}
							<br />
							<span style={{ fontWeight: "bold" }}>Liste des colis :</span>
							<br />
							{Object.keys(listeColis).map((key) => (
								<div key={key}>{listeColis[key]}</div>
							))}
						</div>
					</Col>
				</Row>
			</Accordion.Content>
		</Accordion>
	);
};

const GraphiqueContenants = ({ items }) => {
	const randomColorFromName = (name) => {
		const colors = [
			"#FF6633",
			"#FF33FF",
			"#00B3E6",
			"#E6B333",
			"#3366E6",
			"#999966",
			"#E6B3B3",
			"#B34D4D",
			"#80B300",
			"#6680B3",
		];

		const hash = name
			.split("")
			.reduce((acc, char) => acc + char.charCodeAt(0), 0);
		return colors[hash % colors.length];
	};

	const formatColors = {};

	items.forEach((el) => {
		const formatName = el.Item.Format.name;
		if (!formatColors[formatName]) {
			const color = randomColorFromName(formatName);
			formatColors[formatName] = color;
		}
	});

	const dat = [];
	const count = [];

	items.forEach((el) => {
		if (dat.includes(el.Item.Format.name)) {
			count[dat.indexOf(el.Item.Format.name)]++;
		} else {
			dat.push(el.Item.Format.name);
			count.push(1);
		}
	});

	const total = count.reduce((acc, el) => acc + el, 0);

	const plugins = [
		{
			beforeDraw: function (chart) {
				const width = chart.width;
				const height = chart.height;
				const ctx = chart.ctx;
				ctx.restore();
				const fontSize = (height / 160).toFixed(2);
				ctx.font = `${fontSize}em sans-serif`;
				ctx.textBaseline = "top";
				const text = `Total: ${total}`;
				const textX = Math.round((width - ctx.measureText(text).width) / 2);
				const textY = height / 2;
				ctx.fillText(text, textX, textY);
				ctx.save();
			},
		},
	];

	const options = {
		maintainAspectRatio: false,
		plugins: {
			legend: {
				display: true,
			},
			datalabels: {
				display: true,
				color: "white",
				align: "center",
				labels: {
					title: {
						font: {
							weight: "bold",
						},
					},
					value: {
						color: "black",
					},
				},
				formatter: function (value) {
					if (value !== 0) {
						return "\n" + parseFloat(value).toFixed(0);
					} else return "";
				},
			},
			total: {
				display: true,
				text: `Total: ${count.reduce((a, b) => a + b, 0)}`,
				font: {
					size: 20,
					weight: "bold",
				},
				color: "#333",
				padding: 4,
			},
		},
	};

	const datas = {
		labels: dat,
		datasets: [
			{
				label: "Contenants",
				data: count,
				backgroundColor: dat.map((formatName) => formatColors[formatName]),
				borderWidth: 1,
			},
		],
	};

	return <Doughnut data={datas} options={options} plugins={plugins} />;
};

const LivraisonsPassees = () => {
	const { initialized } = useKeycloak();
	const [groupBy, setGroupBy] = useState("Format");

	return initialized ? (
		<Container className="container">
			<div>
				<NavBar />
				<Row>
					<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
						<Menu />
					</Col>
					<Col sm={11} xl={9}>
						<div>
							<div style={{ marginTop: "100px", marginLeft: "15px" }}>
								<div>
									<h5 style={{ margin: "10px", textAlign: "left" }}>
										Historique des livraisons
									</h5>
									<div style={{ marginBottom: "10px" }}>
										<label htmlFor="group-by-select">Grouper par : </label>
										<select
											id="group-by-select"
											value={groupBy}
											onChange={(e) => setGroupBy(e.target.value)}
										>
											<option value="date">Date</option>
											<option value="shop">Magasin</option>
										</select>
									</div>
									<Content groupBy={groupBy} />
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Container>
	) : null;
};
export { LivraisonsPassees };
