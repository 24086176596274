import { useQuery } from "@apollo/client";
import "chart.js/auto";
import gql from "graphql-tag";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import momentTimezone from "moment-timezone";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useParams } from "react-router-dom";
import { Icon as StatIcon, Statistic } from "semantic-ui-react";
import Menu from "../../LeftMenu";
import NavBar from "../../Navbar";
import pin from "../map/pin.svg";

function Table({ id: timestamp, bac }) {
  // reformat the timestamp string from dd-mm-yyyy hh:mm to
  const date = momentTimezone.tz(timestamp, "UTC");

  const start = date.startOf("minute").toISOString();
  const end = date.endOf("minute").toISOString();

  const query = gql`
      query GetDeliveriesByDate {
  Container_shipped(where: {date: {_gte: "${start}", _lte: "${end}"}, container_id: {_eq: "${bac}"}}) {
    Shop {
      brand
      name
      id
    }
		Warehouse_receiver {
			id
			brand
			name
		}
    Cleaner {
      enterprise
      lat
      long
      id
    }
		Warehouse_sender {
			id
			lat
			long
			brand
			name
		}
		container_id
		date
  }
}

  `;

  const { loading, error, data } = useQuery(query);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const cleaner =
    data.Container_shipped[0].Warehouse_sender ??
    data.Container_shipped[0].Cleaner;

  const shop =
    data.Container_shipped[0].Shop ??
    data.Container_shipped[0].Warehouse_receiver;

  if (!cleaner) {
    return (
      <div style={{ textAlign: "center", marginTop: "2em" }}>
        <h3>Aucun résultat pour cette date</h3>
      </div>
    );
  }

  const cleanerMarker = (
    <Marker
      position={[cleaner.lat, cleaner.long]}
      icon={
        new Icon({
          iconUrl: pin,
          iconSize: [75, 102],
          iconAnchor: [24, 82],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        })
      }
    >
      <Popup>
        <div>
          <h3>Laveur {cleaner.enterprise}</h3>
        </div>
      </Popup>
    </Marker>
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Statistic.Group
          horizontal
          style={{
            width: "50%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="calendar" color="grey" />
              Le{" "}
              {new Date(timestamp).toLocaleDateString("fr-FR", {
                timeZone: "Europe/Paris",
              })}{" "}
              à{" "}
              {new Date(timestamp).toLocaleTimeString("fr-FR", {
                timeZone: "Europe/Paris",
              })}
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="archive" color="grey" />
              Bac{" "}
              <a href={`/bac/${data.Container_shipped[0].container_id}`}>
                {data.Container_shipped[0].container_id}
              </a>
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="building" color="grey" />
              {shop.brand} {shop.name}
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="truck" color="grey" />
              {cleaner.enterprise ?? `${cleaner.brand} ${cleaner.name}`}
            </Statistic.Value>
          </Statistic>
        </Statistic.Group>
        <div
          style={{
            width: "50%",
            height: "500px",
            alignItems: "center",
          }}
        >
          <MapContainer
            center={[cleaner.lat, cleaner.long]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {cleanerMarker}
          </MapContainer>
        </div>
      </div>
    </div>
  );
}

function Receipt({ date }) {
  const params = useParams();
  const timestamp = params.timestamp;
  const bac = params.bac;
  const { initialized } = useKeycloak();
  return initialized ? (
    <Container className="container">
      <div>
        <NavBar />
        <Row>
          <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Menu />
          </Col>
          <Col sm={10}>
            <div>
              <div style={{ marginTop: "100px", marginLeft: "15px" }}>
                <h4 style={{ margin: "10px", textAlign: "center" }}>
                  <Table id={timestamp} bac={bac} />
                </h4>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  ) : null;
}

export default Receipt;
