import React from "react";
import "chart.js/auto";
import "bootstrap/dist/css/bootstrap.min.css";
import { ShopContainerInfo } from "./ShopContainerInfo.js";
import { ShopContainerCollectionStatus } from "./ShopContainerCollectionStatus.js";
import { Accordion } from "react-bootstrap";

const StateCollectionContainer = ({ rsltContainer, rsltShopData }) => {
  const containerInShop = [];
  let containerObject;
  if (rsltContainer.data != null) {
    rsltContainer.data.Container.forEach((element) => {
      if (
        element.status === "opened" ||
        element.status === "close" ||
        element.status === "shipped"
      ) {
        containerInShop.push(element);
      }
    });
    containerObject = { Container: containerInShop };
  } else {
    containerObject = { Container: [] };
  }
  return (
    <div
      style={{
        marginTop: "100px",
        marginLeft: "15px",
        marginRight: "15px",
        marginBottom: "50px",
      }}
    >
      <h2>
        {" "}
        Statut des bacs du magasin:{" "}
        {rsltShopData.data !== undefined
          ? rsltShopData.data.Shop[0].name
          : "Loading . . ."}
      </h2>
      <div style={{ height: "20px" }} />
      <ShopContainerInfo shopBacData={containerObject} />
      <Accordion
        defaultActiveKey="0"
        flush
        style={{ marginTop: "25px", marginBottom: "50px" }}
      >
        <Accordion.Item eventKey={0}>
          <Accordion.Header>Détails</Accordion.Header>
          <Accordion.Body>
            <ShopContainerCollectionStatus shopBacData={rsltContainer.data} />
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export { StateCollectionContainer };
