import { gql } from "@apollo/client";

export const ADD_SALE = gql`
  mutation InsertSales(
    $amount: Int!
    $Shop: uuid!
    $week: timestamptz!
    $section: String!
  ) {
    insert_Sales(
      objects: { amount: $amount, Shop: $Shop, week: $week, section: $section }
    ) {
      returning {
        id
        amount
        Shop
        week
        section
      }
    }
  }
`;


export const GET_SHOPS = gql`
  query GetShops {
    Shop {
      id
      brand
      name
    }
  }
`;

export const GET_SALES = gql`
  query GetSales {
    Sales(order_by: {created_at: desc}) {
      id
      Shop
      amount
      created_at
      section
      week
    }
  }
`;

export const GET_SHOP_BY_ID = gql`
  query GetShopById($id: uuid!) {
    Shop_by_pk(id: $id) {
      id
      name
    }
  }
`;

export const DELETE_SALE = gql`
  mutation DeleteSale($saleId: uuid!) {
    delete_Sales(where: { id: { _eq: $saleId } }) {
      affected_rows
      returning {
        id
      }
    }
  }
`;
