import React, { useState } from "react";
import NavBar from "../../Navbar";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import LeftMenu from "../../LeftMenu";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
	Popover,
	Form,
	ToggleButtonGroup,
	ToggleButton,
	OverlayTrigger,
} from "react-bootstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { Doughnut, Bar } from "react-chartjs-2";
import moment from "moment";
import { convertNumberBold } from "../Tools";

function Content() {
	const [viewMode, setViewMode] = useState("Annee");
	const [radioValue, setRadioValue] = useState("1");
	// this week using moment
	// weeks start on mondays
	const [date, setDate] = useState([
		moment().startOf("week").add(1, "days").toDate(),
		moment().endOf("week").add(1, "days").toDate(),
	]);
	const [show, setShow] = useState(false);

	const radios = [
		{ name: "Tout", value: "0" },
		{ name: "Annee", value: "1" },
		{ name: "Mois", value: "2" },
		{ name: "Personnalisé", value: "3" },
	];

	const popover = (
		<Popover id="popover-basic">
			<Popover.Body>
				<Form className="d-inline-flex" placement="end">
					<DateRangePicker
						onChange={(value) => {
							setViewMode("Custom");
							setDate(value);
						}}
						closeCalendar={true}
						value={date}
						clearIcon={false}
					/>
				</Form>
			</Popover.Body>
		</Popover>
	);

	return (
		<div>
			<h5 style={{ margin: "10px", textAlign: "left" }}>
				Mode de visualisation
			</h5>

			<ToggleButtonGroup type="radio" name="radio" defaultValue="1">
				{radios.map((radio, idx) => {
					return (
						<ToggleButton
							key={radio.value}
							id={`radio-${idx}`}
							value={radio.value}
							variant="outline-success"
							checked={radioValue === radio.value}
							onChange={(e) => {
								setDate([date[0] ?? new Date(), date[1] ?? new Date()]);
								setRadioValue(e.currentTarget.value);
								if (radio.value === "3") {
									setViewMode("Custom");
									setShow(true);
								} else if (radio.value === "2") {
									setViewMode("Mois");
									setShow(false);
								} else if (radio.value === "1") {
									setViewMode("Annee");
									setShow(false);
								} else {
									setViewMode("All");
									setShow(false);
								}
							}}
						>
							{radio.name === "Personnalisé" ? (
								<OverlayTrigger
									trigger="click"
									placement="right"
									show={show}
									overlay={popover}
								>
									<span>{radio.name}</span>
								</OverlayTrigger>
							) : (
								<span>{radio.name}</span>
							)}
						</ToggleButton>
					);
				})}
			</ToggleButtonGroup>
			<Data viewMode={viewMode} date={date} />
		</div>
	);
}

function Data({ viewMode, date }) {
	let query;

	if (viewMode === "All") {
		query = gql`
      query DistinctCleanings {
        Cleaning(
          distinct_on: cleaning_date
          order_by: { cleaning_date: desc }
        ) {
          cleaning_date
          Cleaner {
            enterprise
          }
          Item {
            size
          }
        }
      }
    `;
	} else {
		// Determine the start and end date of the period with iso strings using moment
		let start;
		let end;
		switch (viewMode) {
			case "Annee":
				start = moment().startOf("year").toISOString();
				end = moment().endOf("year").endOf("day").toISOString();
				break;
			case "Mois":
				start = moment().startOf("month").toISOString();
				end = moment().endOf("month").endOf("day").toISOString();
				break;
			case "Custom":
				start = moment(date[0]).toISOString();
				end = moment(date[1])
					.endOf("day")
					.toISOString();
				break;
			default:
				start = moment().startOf("week").toISOString();
				end = moment().endOf("week").endOf("day").toISOString();
				break;
		}

		query = gql`
            query DistinctCleanings {
                Cleaning(
                    distinct_on: cleaning_date,
                    order_by: {cleaning_date: desc},
                    where: {cleaning_date: {_gte: "${start}", _lte: "${end}"}}
                    ) {
                    cleaning_date
                    Cleaner {
                        enterprise
                    }
                    Item {
                        size
                        arrival_country
                        type
                    }
                }
            }
        `;
	}

	const { loading, error, data } = useQuery(query);
	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error : {error.message} </p>;

	return (
		<div>
			{data.Cleaning.length === 0 ? (
				<Row>
					<Col
						style={{
							textAlign: "left",
							height: "400px",
						}}
					>
						Il n'y a pas de données à afficher{" "}
						{viewMode === "Custom"
							? "pour la période sélectionnée"
							: viewMode === "Annee"
							? `pour ${moment().year()}`
							: viewMode === "Mois"
							? `pour ${moment().format("MMMM")}`
							: ""}
						.
					</Col>
				</Row>
			) : (
				<>
					<Row>
						<Col
							style={{
								textAlign: "left",
								height: "400px",
							}}
						>
							<h5 style={{ margin: "10px", textAlign: "left" }}>
								Nombre de nettoyages par taille
							</h5>
							<CleaningsPerSize data={data.Cleaning} />
						</Col>
						<Col
							style={{
								textAlign: "left",
								height: "400px",
							}}
						>
							<h5 style={{ margin: "10px", textAlign: "left" }}>
								Nombre de nettoyages par entreprise
							</h5>
							<CleaningsPerCleaner data={data.Cleaning} />
						</Col>
					</Row>
					<Row>
						<Col
							style={{
								textAlign: "left",
								height: "100px",
							}}
						/>
					</Row>
					<Row>
						<Col
							style={{
								marginTop: "50px",
								marginBottom: "50px",
								textAlign: "left",
								height: "400px",
							}}
						>
							<h5 style={{ margin: "10px", textAlign: "left" }}>
								Nombre de nettoyages{" "}
								{
									// Display year, month name with year, dates, or "Tout"
									viewMode === "Annee"
										? `pour ${new Date().getFullYear()}`
										: viewMode === "Mois"
										? `pour ${`${new Date().toLocaleString("fr-FR", {
												month: "long",
										  })} ${new Date().getFullYear()}`}`
										: viewMode === "Custom"
										? `entre le ${new Date(
												date[0].toISOString(),
										  ).toLocaleDateString("fr-FR", {
												timeZone: "Europe/Paris",
										  })} et le ${new Date(
												date[1].toISOString(),
										  ).toLocaleDateString("fr-FR", {
												timeZone: "Europe/Paris",
										  })}`
										: "global"
								}
							</h5>
							<CleaningsPerSizeBarChart data={data.Cleaning} />
						</Col>
					</Row>
					<h5 style={{ margin: "10px", textAlign: "left" }}>
						Historique des nettoyages
					</h5>
					<table className="table table-striped table-bordered">
						<thead>
							<tr>
								<th scope="col">Date</th>
								<th scope="col">Taille</th>
								<th scope="col">Entreprise</th>
							</tr>
						</thead>
						<tbody>
							{data.Cleaning.map((cleaning, idx) => {
								return (
									<tr key={cleaning.cleaning_date}>
										<td>
											<a href={`/dateLavage/${cleaning.cleaning_date}`}>
												Le{" "}
												{new Date(cleaning.cleaning_date).toLocaleDateString(
													"fr-FR",
													{
														timeZone: "Europe/Paris",
													},
												)}{" "}
												à{" "}
												{new Date(cleaning.cleaning_date).toLocaleTimeString(
													"fr-FR",
													{
														timeZone: "Europe/Paris",
													},
												)}
											</a>
										</td>
										<td>
											{cleaning.Item.size} {cleaning.Item.type ?? "degrenne"}
										</td>
										<td>{cleaning.Cleaner.enterprise}</td>
									</tr>
								);
							})}
						</tbody>
					</table>
				</>
			)}
		</div>
	);
}

// Doughnut chart for cleaning depending on the size of the item
function CleaningsPerSize({ data }) {
	// let size = {};
	// for (let i = 0; i < data.length; i++) {
	//   if (size[data[i].Item.size] === undefined) {
	//     size[data[i].Item.size] = 1;
	//   } else {
	//     size[data[i].Item.size] += 1;
	//   }
	// }

	const size = {};
	for (let i = 0; i < data.length; i++) {
		size[`${data[i].Item.size} ${data[i].Item.type ?? "degrenne"}`] =
			(size[`${data[i].Item.size} ${data[i].Item.type ?? "degrenne"}`] ?? 0) +
			(data[i].Item.size === "Petit" ? 50 : 25);
	}

	const sizeData = [];
	for (const key in size) {
		sizeData.push({
			label: key,
			value: size[key],
		});
	}

	return (
		<Doughnut
			data={{
				labels: sizeData.map((data) => data.label),
				datasets: [
					{
						label: "Nombre de nettoyages",
						data: sizeData,
						backgroundColor: [
							"#FF6384", // red
							"#36A2EB", // blue
							"#FFCE56", // yellow
							"#FF9F40", // orange
							"#4BC0C0", // green
							"#9966FF", // purple
							"#C9CBCF", // grey
						],
					},
				],
			}}
			options={{
				maintainAspectRatio: false,
				title: {
					display: true,
					text: "Nombre de nettoyages par taille",
				},
				legend: {
					display: true,
					position: "right",
				},
				plugins: {
					datalabels: {
						display: true,
						color: "white",
						font: {
							weight: "bold",
							size: 16,
						},
						autoSkip: true,
						formatter: (value, ctx) => {
							const dataArr = ctx.chart.data.datasets[0].data;
							const hiddenIndices = ctx.chart._hiddenIndices;
							const displayedData = dataArr.filter((_, i) => !hiddenIndices[i]);
							const displayedSum = displayedData.reduce((acc, data) => {
								return acc + data;
							});
							const percentage = Math.round((value / displayedSum) * 100);
							return percentage > 5 ? `${value} (${percentage}%)` : "";
						},
					},
				},
			}}
		/>
	);
}

// Doughnut chart for cleaning depending on the enterprise of the cleaner
function CleaningsPerCleaner({ data }) {
	const enterprise = {};
	for (let i = 0; i < data.length; i++) {
		if (enterprise[data[i].Cleaner.enterprise] === undefined) {
			enterprise[data[i].Cleaner.enterprise] = 1;
		} else {
			enterprise[data[i].Cleaner.enterprise] += 1;
		}
	}

	const enterpriseData = [];
	for (const key in enterprise) {
		enterpriseData.push({
			label: key,
			value: enterprise[key],
		});
	}

	return (
		<Doughnut
			style={{
				width: "100%",
			}}
			data={{
				labels: enterpriseData.map((data) => data.label),
				datasets: [
					{
						label: "Nombre de nettoyages",
						data: enterpriseData.map((data) => data.value),
						backgroundColor: [
							"rgba(54, 162, 235, 1)",
							"rgba(255, 205, 86, 1)",
							"rgba(75, 192, 192, 1)",
							"rgba(255, 99, 132, 1)",
						],
					},
				],
			}}
			options={{
				maintainAspectRatio: false,
				title: {
					display: true,
					text: "Nombre de nettoyages par entreprise",
				},
				legend: {
					display: true,
					position: "right",
				},
				plugins: {
					datalabels: {
						display: true,
						color: "white",
						font: {
							weight: "bold",
							size: 15,
						},
						formatter: (value, ctx) => {
							const dataArr = ctx.chart.data.datasets[0].data;
							const hiddenIndices = ctx.chart._hiddenIndices;
							const displayedData = dataArr.filter((_, i) => !hiddenIndices[i]);
							const displayedSum = displayedData.reduce((acc, data) => {
								return acc + data;
							});
							const percentage = Math.round((value / displayedSum) * 100);
							return percentage > 5 ? `${value} (${percentage}%)` : "";
						},
					},
				},
			}}
		/>
	);
}

// Cleanings per day, with one bar per day and one bar per size
// Use moment for the dates
// Sort by date
function CleaningsPerSizeBarChart({ data }) {
	// const sizes = data.reduce((acc, val) => {
	//   if (!acc.includes(val.Item.size)) acc.push(val.Item.size);
	//   return acc;
	// }, []);

	const sizes = data.reduce((acc, val) => {
		if (!acc.includes(`${val.Item.size} ${val.Item.type ?? "degrenne"}`))
			acc.push(`${val.Item.size} ${val.Item.type ?? "degrenne"}`);
		return acc;
	}, []);

	let datasets = sizes.map((size) => {
		return {
			label: size,
			data: data.reduce((acc, val) => {
				// if (val.Item.size === size) {
				//   const date = moment(val.cleaning_date).format("DD/MM/YYYY");
				//   if (acc[date] === undefined) {
				//     acc[date] = 1 * size === "Petit" ? 50 : 25;
				//   } else {
				//     acc[date] += 1 * size === "Petit" ? 50 : 25;
				//   }
				// }
				// return acc;
				if (`${val.Item.size} ${val.Item.type ?? "degrenne"}` === size) {
					const date = moment(val.cleaning_date).format("DD/MM/YYYY");
					if (acc[date] === undefined) {
						acc[date] = val.Item.size === "Petit" ? 50 : 25;
					} else {
						acc[date] += val.Item.size === "Petit" ? 50 : 25;
					}
				}
				return acc;
			}, {}),
			backgroundColor:
				size === "Petit degrenne"
					? "rgba(54, 162, 235, 1)"
					: size === "Grand degrenne"
					? "rgba(255, 205, 86, 1)"
					: size === "Petit BM45"
					? "rgba(75, 192, 192, 1)"
					: "rgba(255, 99, 132, 1)",
		};
	});

	const earliestDate = moment(
		data.reduce((acc, val) => {
			if (acc === undefined || moment(val.cleaning_date).isBefore(acc))
				acc = val.cleaning_date;
			return acc;
		}, undefined),
	);

	const latestDate = moment(
		data.reduce((acc, val) => {
			if (acc === undefined || moment(val.cleaning_date).isAfter(acc))
				acc = val.cleaning_date;
			return acc;
		}, undefined),
	);

	const diff = latestDate.diff(earliestDate, "days");

	let isByWeek = false;

	if (diff > 30) {
		isByWeek = true;
		datasets = sizes.map((size) => {
			return {
				label: size,
				data: data.reduce((acc, val) => {
					if (`${val.Item.size} ${val.Item.type ?? "degrenne"}` === size) {
						const date = moment(val.cleaning_date).format("GGGG-WW");
						if (acc[date] === undefined) {
							acc[date] = val.Item.size === "Petit" ? 50 : 25;
						} else {
							acc[date] += val.Item.size === "Petit" ? 50 : 25;
						}
					}
					return acc;
				}, {}),
				backgroundColor:
					size === "Petit degrenne"
						? "rgba(255, 99, 132, 1)"
						: size === "Grand degrenne"
						? "rgba(54, 162, 235, 1)"
						: size === "Petit BM45"
						? "rgba(255, 205, 86, 1)"
						: "rgba(75, 192, 192, 1)",
			};
		});
	}

	// Merge labels
	const labels = datasets.reduce((acc, val) => {
		const keys = Object.keys(val.data);
		keys.forEach((key) => {
			if (!acc.includes(key)) acc.push(key);
		});
		return acc;
	}, []);

	// sort labels using moment
	labels.sort((a, b) => {
		if (isByWeek) {
			// parse GGGG-WW using moment
			const dateA = moment(a, "GGGG-WW");
			const dateB = moment(b, "GGGG-WW");
			return dateA.isBefore(dateB) ? -1 : 1;
		} else {
			// parse DD/MM/YYYY using moment
			const dateA = moment(a, "DD/MM/YYYY");
			const dateB = moment(b, "DD/MM/YYYY");
			return dateA.isBefore(dateB) ? -1 : 1;
		}
	});

	// Create missing weeks if needed
	if (isByWeek) {
		const firstWeek = moment(labels[0], "GGGG-WW");
		const lastWeek = moment(labels[labels.length - 1], "GGGG-WW");
		const diff = lastWeek.diff(firstWeek, "weeks");
		for (let i = 0; i < diff; i++) {
			const week = firstWeek.clone().add(i, "weeks");
			const weekString = week.format("GGGG-WW");
			if (!labels.includes(weekString)) {
				labels.splice(i, 0, weekString);
			}
		}
	}

	return (
		<Bar
			data={{
				labels,
				datasets: datasets,
			}}
			options={{
				maintainAspectRatio: false,
				title: {
					display: true,
					text: "Nombre de nettoyages par taille",
				},
				legend: {
					display: true,
					position: "right",
				},
				scales: {
					x: {
						ticks: {
							callback: function (value, index, values) {
								const label = this.getLabelForValue(value);
								if (isByWeek) {
									return `${label.split("-")[0]}-${convertNumberBold(
										label.split("-")[1],
									)}`;
								} else return label;
							},
						},
						grid: {
							color: function (context) {
								if (context.tick) {
									const { scale, tick } = context;
									const previousTick =
										scale.ticks[scale.ticks.indexOf(tick) - 1];
									if (previousTick) {
										const year = tick.label.split("-")[0];
										const previousTickYear = previousTick.label.split("-")[0];
										const index = scale.ticks.indexOf(tick);
										if (
											year !== previousTickYear &&
											index > 1 &&
											index < scale.ticks.length - 1
										) {
											return "rgba(0, 0, 0, .5)";
										}
									}
								}
								return "rgba(0, 0, 0, .1)";
							},
						},
					},
				},
				plugins: {
					datalabels: {
						display: false,
					},
				},
			}}
		/>
	);
}

const CleaningsHistoryPage = () => {
	const { initialized } = useKeycloak();

	return initialized ? (
		<Container className="container">
			<NavBar />
			<Row>
				<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
					<LeftMenu />
				</Col>
				<Col
					sm={11}
					xl={10}
					style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
				>
					<h1>Historique des lavages</h1>
					<Content />
				</Col>
			</Row>
		</Container>
	) : (
		<></>
	);
};

export default CleaningsHistoryPage;
