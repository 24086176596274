import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import { useParams } from "react-router-dom";
import { Card, Table } from "semantic-ui-react";
import LeftMenu from "../../LeftMenu";
import momentTimezone from "moment-timezone";
import NavBar from "../../Navbar";

function containerEllipsis(name) {
	return name.length > 10 ? `${name.slice(0, 5)}...${name.slice(-5)}` : name;
}

function ReceiptsCardContent({ timestamp }) {
	// filter by the day of the timestamp without timezome
	const daybegin = moment(timestamp).startOf("day").toISOString();
	const dayend = moment(timestamp).endOf("day").toISOString();
	const query = gql`
    query {
      Container_shipped (
        where: {
          date: {
            _gt: "${daybegin}"
            _lte: "${dayend}"
          }
        }
      )  {
    Shop {
      brand
      name
      id
    }
		Warehouse_receiver {
			id
			brand
			name
		}
    Cleaner {
      enterprise
      id
    }
		Warehouse_sender {
			id
			brand
			name
		}
		container_id
		date
  }
}
  `;

	const { loading, error, data } = useQuery(query);

	if (loading) return <p>Loading...</p>;
	if (error) return <p>Error : {error.message}</p>;

	return (
		<Card.Content>
			<Card.Header>Historique des livraisons</Card.Header>
			<Card.Description>
				<Table celled>
					<Table.Header>
						<Table.Row>
							<Table.HeaderCell>Lien</Table.HeaderCell>
							<Table.HeaderCell>Bac</Table.HeaderCell>
							<Table.HeaderCell>Laveur</Table.HeaderCell>
							<Table.HeaderCell>Magasin</Table.HeaderCell>
						</Table.Row>
					</Table.Header>
					<Table.Body>
						{data.Container_shipped.map((shipment) => {
							const date = momentTimezone
								.tz(shipment.date, "UTC")
								.toISOString();
							return (
								<Table.Row key={`${date}-${shipment.container_id}`}>
									<Table.Cell>
										<a
											href={`/livraisons/${encodeURI(date)}/${
												shipment.container_id
											}`}
										>
											Consulter la livraison
										</a>
									</Table.Cell>
									<Table.Cell>
										<a href={`/bac/${encodeURI(shipment.container_id)}`}>
											{shipment.container_id.length > 5
												? `${containerEllipsis(shipment.container_id)}`
												: shipment.container_id}
										</a>
									</Table.Cell>
									<Table.Cell>
										{shipment.Warehouse_sender
											? `${shipment.Warehouse_sender.brand} ${shipment.Warehouse_sender.name}`
											: `${shipment.Cleaner.enterprise}`}
									</Table.Cell>
									<Table.Cell>
										{shipment.Shop ? (
											<a
												href={`/operation/shop/${encodeURI(shipment.Shop.id)}`}
											>
												{shipment.Shop.brand} {shipment.Shop.name}
											</a>
										) : (
											<a
												href={`/operation/warehouse/${encodeURI(
													shipment.Warehouse_receiver.id,
												)}`}
											>
												{shipment.Warehouse_receiver.brand}{" "}
												{shipment.Warehouse_receiver.name}
											</a>
										)}
									</Table.Cell>
								</Table.Row>
							);
						})}
					</Table.Body>
				</Table>
			</Card.Description>
		</Card.Content>
	);
}

function Content({ timestamp }) {
	return (
		<Row>
			<Card fluid>
				<ReceiptsCardContent timestamp={timestamp} />
			</Card>
		</Row>
	);
}

const ReceiptsHistoryPage = () => {
	const { initialized } = useKeycloak();

	const params = useParams();

	const timestamp = params.timestamp;

	return initialized ? (
		<Container className="container">
			<NavBar />
			<Row>
				<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
					<LeftMenu />
				</Col>
				<Col
					sm={11}
					xl={10}
					style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
				>
					<h1>
						Historique des livraisons du{" "}
						{moment(timestamp).format("DD/MM/YYYY")}
					</h1>
					<Content timestamp={timestamp} />
				</Col>
			</Row>
		</Container>
	) : (
		<></>
	);
};

export default ReceiptsHistoryPage;
