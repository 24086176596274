import { Table } from "semantic-ui-react";
import React from "react";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";

const TableSupplyOrders = () => {
	const querySupply = gql`
    query MyQuery {
      Supply_order {
        supply {
          Name
        }
        quantity_ordered
        Shop {
          brand
          name
        }
        date_deliver
        date_order
      }
    }
  `;
	const { /* loading,  */ data } = useQuery(querySupply);
	return data !== null ? (
		<Table celled structured color={"green"} inverted className={"TableRecap"}>
			<Table.Header>
				<Table.Row>
					<Table.HeaderCell verticalAlign={"middle"}>
						Nom fourniture
					</Table.HeaderCell>
					<Table.HeaderCell verticalAlign={"middle"}>
						Quantité commandée
					</Table.HeaderCell>
					<Table.HeaderCell verticalAlign={"middle"}>
						Lieu de livraison
					</Table.HeaderCell>
					<Table.HeaderCell verticalAlign={"middle"}>
						Date de livraison
					</Table.HeaderCell>
					<Table.HeaderCell verticalAlign={"middle"}>
						Date de l'opération
					</Table.HeaderCell>
				</Table.Row>
			</Table.Header>
			<Table.Body>
				{data !== undefined ? (
					data.Supply_order.map((el) => Tablebody(el))
				) : (
					<Table.Row> Chargement en cours ...</Table.Row>
				)}
			</Table.Body>
		</Table>
	) : (
		<p>En chargement ....</p>
	);
};

function Tablebody(el) {
	const orderDate = new Date(el.date_deliver);
	const operationDate = new Date(el.date_order);
	const options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
		hour12: false,
		hour: "2-digit",
		minute: "2-digit",
	};
	return (
		<>
			<Table.Row>
				<Table.Cell verticalAlign={"middle"}>{el.supply.Name}</Table.Cell>
				<Table.Cell verticalAlign={"middle"}>{el.quantity_ordered}</Table.Cell>
				<Table.Cell verticalAlign={"middle"}>
					{el.Shop != null ? el.Shop.brand : "" } - {el.Shop != null ? el.Shop.name : ""}
				</Table.Cell>
				<Table.Cell verticalAlign={"middle"}>
					{orderDate.toLocaleString("fr-FR", options)}
				</Table.Cell>
				<Table.Cell verticalAlign={"middle"}>
					{operationDate.toLocaleString("fr-FR", options)}
				</Table.Cell>
			</Table.Row>
		</>
	);
}

export { TableSupplyOrders };
