import React, { useState } from 'react';
import { Form, Input, Dropdown, Checkbox } from 'semantic-ui-react';
import { Col, Row } from "react-bootstrap";
import { GET_WAREHOUSE } from "./Query";
import { useQuery } from "@apollo/client";

const MyForm = () => {
  const [formData, setFormData] = useState({
    regionalCenter: '',
    brand: '',
    name: '',
    address: '',
    postalCode: '',
    deploymentDate: '',
    deploymentPrice: '',
    priceBM: '',
    priceBL: '',
    deliveryPerson: '',
    phoneOrdered: false,
    phoneAndSIMDelivered: false,
    supplyReadyCSM: false,
  });

  const { data: warehouseData } = useQuery(GET_WAREHOUSE);

  const warehouseOptions = warehouseData?.warehouse.map(warehouse => ({
    key: warehouse.id,
    text: warehouse.name,
    value: warehouse.id,
  })) || [];

  const handleChange = (e, { name, value, checked }) => {
    setFormData({ ...formData, [name]: name === 'phoneOrdered' ? checked : value });
  };

  const handleSubmit = () => {
    // You can perform any action with the form data here
    console.log(formData);
    //TODO terminer le formulaire
  };

  return (
    <Form onSubmit={handleSubmit} style={{ width: "800px", padding: "20px" }}>
      <Row style={{ marginBottom: '25px' }}>
        <Col sm={6} style={{ paddingLeft: 0, paddingRight: 50 }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.2em', marginTop: '25px', marginBottom: '25px' }}>Informations générales</div>
          <Form.Field>
            <label>Enseigne</label>
            <Input
              type="text"
              name="brand"
              value={formData.brand}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Centrale régionale</label>
            <Input
              type="text"
              name="regionalCenter"
              value={formData.regionalCenter}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Nom</label>
            <Input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
            />
          </Form.Field>
        </Col>
        <Col sm={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.2em', marginTop: '25px', marginBottom: '25px' }}>Informations géographiques</div>
          <Form.Field>
            <label>Adresse</label>
            <Input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Code postal</label>
            <Input
              type="text"
              name="postalCode"
              value={formData.postalCode}
              onChange={handleChange}
            />
          </Form.Field>
        </Col>
      </Row>
      <Row style={{ marginBottom: '25px' }}>
        <Col sm={6} style={{ paddingLeft: 0, paddingRight: 50 }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.2em', marginTop: '25px', marginBottom: '25px' }}>Informations commerciales</div>
          <Form.Field>
            <label>Date de formation</label>
            <Input
              type="text"
              name="deploymentDate"
              value={formData.deploymentDate}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Tarif déploiement</label>
            <Input
              type="text"
              name="deploymentPrice"
              value={formData.deploymentPrice}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Frais service BM</label>
            <Input
              type="text"
              name="priceBM"
              value={formData.priceBM}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Frais service BL</label>
            <Input
              type="text"
              name="priceBL"
              value={formData.priceBL}
              onChange={handleChange}
            />
          </Form.Field>
          <Form.Field>
            <label>Expéditeur</label>
            <Dropdown
              placeholder="Expéditeur"
              fluid
              selection
              name="deliveryPerson"
              value={formData.deliveryPerson}
              options={warehouseOptions}
              onChange={handleChange}
            />
          </Form.Field>
        </Col>
        <Col sm={6} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <div style={{ fontWeight: 'bold', fontSize: '1.2em', marginTop: '25px', marginBottom: '25px' }}>Informations fournitures</div>
          <Row style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Col sm={5} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Form.Field>
            <label>Téléphone commandé</label>
            <Checkbox
              name="phoneOrdered"
              checked={formData.phoneOrdered}
              onChange={handleChange}
            />
          </Form.Field>
          </Col>
          <Col sm={5} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Form.Field>
            <label>Téléphone et SIM remis</label>
            <Checkbox
              name="phoneAndSIMDelivered"
              checked={formData.phoneAndSIMDelivered}
              onChange={handleChange}
            />
          </Form.Field>
          </Col>
          <Col sm={5} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <Form.Field>
            <label>Fourniture disponible CSM</label>
            <Checkbox
              name=" supplyReadyCSM"
              checked={formData.supplyReadyCSM}
              onChange={handleChange}
            />
          </Form.Field>
          </Col>
          </Row>
          
        </Col>
      </Row>
      <Form.Button type="submit">Créer le magasin</Form.Button>
    </Form>
  );
};

export default MyForm;
