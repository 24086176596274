import Fuse from "fuse.js";

const filterOptions = (options, searchText) => {
  const fuse = new Fuse(options, {
    keys: ["text"],
  });

  return searchText
    ? fuse.search(searchText).map((result) => result.item)
    : options;
};

export default filterOptions;
