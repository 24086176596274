import React from "react";
import { Dropdown, Nav, NavItem, NavLink } from "react-bootstrap";
import { IconContext } from "react-icons";
import {BsCurrencyDollar, BsBuilding, BsBook} from "react-icons/bs";
import { FiPackage } from "react-icons/fi";
import { GrUserSettings } from "react-icons/gr";
import {
    RiAlarmWarningFill,
    RiArrowGoBackLine, RiKnifeLine,
    RiOrganizationChart,
    RiRoadMapLine,
} from "react-icons/ri";

import { useKeycloak } from "react-keycloak";
import { LinkContainer } from "react-router-bootstrap";
import { SaladGenerator } from "./conponents/other/saladGenerator";
import {Divider} from "semantic-ui-react";
import {VscLibrary} from "react-icons/all";

const Menu = () => {
  const keycloak = useKeycloak().keycloak;
  const token = keycloak?.tokenParsed;

  const username = token?.preferred_username ?? "default";

  const picture =
    token?.picture ??
    `https://api.dicebear.com/6.x/thumbs/svg?seed=${username}`;

  return (
    <div className="leftMenu" style={{ borderStyle: "hidden", height: "100%" }}>
      <Nav defaultActiveKey="/" className="flex-column">
        <p className="text-center mt-5">
          <img
            src={picture}
            alt="profile"
            style={{
              width: "100px",
              height: "100px",
              borderRadius: "50%",
              marginBottom: "10px",
            }}
          />
          <br />
          <span style={{ fontWeight: "bold", fontSize: "2em" }}>
            {username}
          </span>
        </p>

        <SaladGenerator />
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink}>
            <IconContext.Provider value={{ size: "2em" }}>
              <div>
                <FiPackage />
              </div>
            </IconContext.Provider>
            <span style={{ paddingLeft: "10px" }}>Logistique</span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              background: "#005258",
              padding: "5px",
            }}
          >
            <LinkContainer
              to="/ecartLavageLivraison"
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
            >
              <Dropdown.Item>Ecart lavage et livraison </Dropdown.Item>
            </LinkContainer>
            <LinkContainer
              to="/livraisonProche"
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
            >
              <Dropdown.Item>Colis en attente de livraison</Dropdown.Item>
            </LinkContainer>
            <LinkContainer
              to="/livraisonPassees"
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
            >
              <Dropdown.Item>Historique des livraisons</Dropdown.Item>
            </LinkContainer>
            <LinkContainer
              to="/stockFournitures"
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
            >
              <Dropdown.Item>Stock fournitures</Dropdown.Item>
            </LinkContainer>
            <LinkContainer
              to="/receipt"
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
            >
              <Dropdown.Item>Réception laveur</Dropdown.Item>
            </LinkContainer>
            <LinkContainer
              to="/containers"
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
            >
              <Dropdown.Item>Caisses IFCO</Dropdown.Item>
            </LinkContainer>
          </Dropdown.Menu>
        </Dropdown>
        <Dropdown as={NavItem}>
          <Dropdown.Toggle as={NavLink}>
            <IconContext.Provider value={{ size: "2em" }}>
              <div>
                <RiOrganizationChart />
              </div>
            </IconContext.Provider>
            <span style={{ paddingLeft: "10px" }}>Opérations</span>
          </Dropdown.Toggle>
          <Dropdown.Menu
            style={{
              background: "#005258",
              padding: "5px",
            }}
          >
            <LinkContainer
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
              to="/operation"
            >
              <Dropdown.Item>Vue d'ensemble</Dropdown.Item>
            </LinkContainer>
            <LinkContainer
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
              to="/qrcodes"
            >
              <Dropdown.Item>QR Codes</Dropdown.Item>
            </LinkContainer>
            <LinkContainer
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
              to="/lavages"
            >
              <Dropdown.Item>Lavages</Dropdown.Item>
            </LinkContainer>
            <LinkContainer
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
              to="/transports"
            >
              <Dropdown.Item>Bacs de collecte</Dropdown.Item>
            </LinkContainer>
            <LinkContainer
              style={{
                marginBottom: "5px",
                backgroundColor: "#005258",
                color: "white",
              }}
              to="/new_items"
            >
              <Dropdown.Item>Initialisation de contenants</Dropdown.Item>
            </LinkContainer>
          </Dropdown.Menu>
        </Dropdown>
        <LinkContainer to="/retours">
          <Nav.Link eventKey="link-2">
            <IconContext.Provider value={{ size: "2em" }}>
              <div>
                <RiArrowGoBackLine />
              </div>
            </IconContext.Provider>
            <span style={{ paddingLeft: "10px" }}>Derniers retours</span>
          </Nav.Link>
        </LinkContainer>
          <LinkContainer to="/contenantsPolis">
              <Nav.Link eventKey="link-2">
                  <IconContext.Provider value={{ size: "2em" }}>
                      <div>
                          <RiKnifeLine />
                      </div>
                  </IconContext.Provider>
                  <span style={{ paddingLeft: "10px" }}>Contenants polis</span>
              </Nav.Link>
          </LinkContainer>

        <LinkContainer to="/carte">
          <Nav.Link eventKey="link-2">
            <IconContext.Provider value={{ size: "2em" }}>
              <div>
                <RiRoadMapLine />
              </div>
            </IconContext.Provider>
            <span style={{ paddingLeft: "10px" }}>Carte Berny</span>
          </Nav.Link>
        </LinkContainer>

        <LinkContainer to="/weekly-sales">
          <Nav.Link eventKey="link-2">
            <IconContext.Provider value={{ size: "2em" }}>
              <div>
                <BsCurrencyDollar />
              </div>
            </IconContext.Provider>
            <span style={{ paddingLeft: "10px" }}>Ventes magasins</span>
          </Nav.Link>
        </LinkContainer>
          <Divider/>
          <p style={{textAlign: "center "}}>Application pro </p>
          <LinkContainer
              to="/anomaly"
          >
              <Nav.Link eventKey="link-2">
                  <IconContext.Provider value={{ size: "2em" }}>
                      <div>
                          <RiAlarmWarningFill />
                      </div>
                  </IconContext.Provider>
                  <span style={{ paddingLeft: "10px" }}>Gestion des anomalies</span>
              </Nav.Link>
          </LinkContainer>
          <LinkContainer
              to="/fileCleaner"
          >
              <Nav.Link eventKey="link-2">
                  <IconContext.Provider value={{ size: "2em" }}>
                      <div>
                          <BsBook />
                      </div>
                  </IconContext.Provider>
                  <span style={{ paddingLeft: "10px" }}>Fiches de prod laveurs</span>
              </Nav.Link>
          </LinkContainer>
          <LinkContainer
              to="/defaults"
          >
              <Nav.Link eventKey="link-2">
                  <IconContext.Provider value={{ size: "2em" }}>
                      <div>
                          <VscLibrary />
                      </div>
                  </IconContext.Provider>
                  <span style={{ paddingLeft: "10px" }}>Défauthèque</span>
              </Nav.Link>
          </LinkContainer>
         <LinkContainer to="/add-shop">
          <Nav.Link eventKey="link-2">
            <IconContext.Provider value={{ size: "2em" }}>
              <div>
                <BsBuilding />
              </div>
            </IconContext.Provider>
            <span style={{ paddingLeft: "10px" }}>Nouveau magasin</span>
          </Nav.Link>
        </LinkContainer>
          <Divider/>
          <p style={{textAlign: "center "}}>Application consommateurs </p>
        <LinkContainer to="/manage_users">
          <Nav.Link eventKey="link-2">
            <IconContext.Provider value={{ size: "2em" }}>
              <div>
                <GrUserSettings />
              </div>
            </IconContext.Provider>
            <span style={{ paddingLeft: "10px" }}>Gérer les utilisateurs</span>
          </Nav.Link>
        </LinkContainer>
      </Nav>
    </div>
  );
};

export default Menu;
