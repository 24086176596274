import React, {useEffect, useState} from "react";
import {useLazyQuery, useMutation, useQuery} from "@apollo/client";
import {
    Col,
    Container,
    Form,
    Modal,
    OverlayTrigger,
    Popover,
    Row,
    ToggleButton,
    ToggleButtonGroup
} from "react-bootstrap";
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useKeycloak } from "react-keycloak";
import LeftMenu from "../LeftMenu";
import NavBar from "../Navbar";
import {
    Button,
    ButtonGroup,
    ButtonOr,
    Card,
    CardContent,
    Divider,
    Dropdown,
    DropdownDivider,
    DropdownHeader,
    DropdownItem,
    DropdownMenu,
    FormInput,
    Grid,
    GridColumn,
    GridRow,
    Icon,
    Image,
    Input,
    Label,
    List,
    ListContent,
    ListItem,
    Segment,
    Statistic,
    StatisticGroup,
    StatisticLabel,
    StatisticValue
} from "semantic-ui-react";
import {GET_CLEANERS, GET_CLEANINGS, UPDATE_ITEMS} from "./Query";
import {format} from "date-fns";
import {ADD_POLISHED_ITEMS} from "./Query";



const Nav = ({show}) =>  {

    const [dates, setDates] = useState([]);
    const [selectedCleaner, setSelectedCleaner] = useState("");
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const {
        loading: shopLoading,
        error: shopError,
        data: cleaners,
    } = useQuery(GET_CLEANERS, {
        suspend: true, // Enable Suspense for this query
    });

    const [getCleanings, {
        loading: itemsLoading,
        error: itemsError,
    }] = useLazyQuery(GET_CLEANINGS, {
        suspend: true, // Enable Suspense for this query
    });


    return (
        <Card style={{marginTop: "50px", marginRight: "50px", marginLeft: "50px", minWidth: "1000px", display: "flex"}}>
            <CardContent>
            <Divider horizontal>
                <Icon name='pin' />
                <h5 style={{ margin: "10px", textAlign: "left" }}>
                    Mode de visualisation
                </h5>
            </Divider>
            <Grid>
                <GridRow columns={1}>
                    <div style={{paddingLeft: "15%",display: "flex", textAlign: "center",
                        verticalAlign: 'middle', alignContent: "space-between", alignItems: "center", maxWidth: "max-content"}}>
                        <Label style={{marginLeft: "15px", marginRight: "15px"}}>Date unique</Label>
                        <SemanticDatepicker
                                type="range"
                                format="DD/MM/YYYY"
                                clearable={false}
                                firstDayOfWeek={1}
                                showOutsideDays={false}
                                required={true}
                                onChange={(e, data) => setDates(data.value)}
                                showToday={true}
                                value={dates}
                            />
                        <Label style={{marginLeft: "15px"}}>Interval de dates</Label>
                    </div>
                    <p style={{color: "red", textAlign: "center"}}> {showErrorMessage ? (dates === [] ? "Veuillez sélectionner au moins une date" : "") : ""} </p>
                </GridRow>
                <Divider horizontal>
                    <Icon name='warehouse' />
                <h5 style={{ margin: "10px", textAlign: "left" }}>
                    Choix du laveur
                </h5>
                </Divider>
                <GridRow columns={1}>
                    <div style={{display: "inline-grid", paddingLeft: "8%", paddingRight: "5%", textAlign: "center",
                        verticalAlign: 'middle', alignItems: "center"}}>

                    <Dropdown text={selectedCleaner !== "" ? selectedCleaner.enterprise : "Laveur"}
                              icon='filter'
                              labeled
                              scrolling
                              button
                              style={{backgroundColor: "#02b5ad", color: "white", minWidth: "100px", textAlign: "center"}}
                              className='icon'>
                        <DropdownMenu>
                            <DropdownHeader icon='tags' content='Laveur' />
                            <DropdownDivider />
                            {cleaners?.Cleaner?.map((item, index) => {
                                return <DropdownItem onClick={(e, value) => {
                                    setSelectedCleaner(item);
                                }}>{item.enterprise.toString()}
                                </DropdownItem>
                            })}
                        </DropdownMenu>
                    </Dropdown>
                        <p style={{color: "red", textAlign: "center"}}> {showErrorMessage ? (selectedCleaner === "" ? "Veuillez sélectionner un laveur" : "") : ""} </p>
                        <Segment basic floated={"right"} >
                            <ButtonGroup >
                                <Button
                                    color='red'
                                    key="red"
                                    icon
                                    labelPosition='left'
                                    size='small'
                                    onClick={()=> {

                                        if (show) {
                                            show([]);
                                            setSelectedCleaner("")
                                            setDates([])
                                        }
                                    }}
                                >
                                    <Icon name='trash' /> Effacer
                                </Button>
                                <ButtonOr text='ou' />
                                <Button
                                    color='green'
                                    key="green"
                                    icon
                                    labelPosition='right'
                                    size='small'
                                    onClick={() => {

                                            if(dates !== [] && selectedCleaner !== "") {
                                                let formattedDate;
                                                let formattedDateAnd1;
                                                if(dates.length === 1){
                                                    formattedDate = format(dates[0], "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
                                                    formattedDateAnd1 = format(dates[0].setDate(dates[0].getDate() + 1), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

                                                }
                                                else{
                                                    formattedDate = format(dates[0], "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");
                                                    formattedDateAnd1 = format(dates[1].setDate(dates[1].getDate() + 1), "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

                                                }
                                                getCleanings({
                                                    variables: {
                                                        date1:  formattedDate,
                                                        date2:  formattedDateAnd1,
                                                        cleaner: selectedCleaner.id,
                                                    },
                                                }).then((cleanings) => {
                                                    if (show) {
                                                        show(cleanings.data); // Call the callback function
                                                    }
                                                }).catch((e) => {
                                                    console.log(e)
                                                })
                                            }
                                            else{
                                                setShowErrorMessage(true)
                                            }

                                    }}
                                >
                                    <Icon name='check' /> Valider
                                </Button>
                            </ButtonGroup>

                        </Segment>

                    </div>
                </GridRow>
            </Grid>

            </CardContent>
        </Card>
    );
}

const ResultUi = (results) => {
        const [items, setItems] = useState(results.results.Cleaning)
        const type = [...new Set(items.map(item => item.Item.type))]
        const containers = [...new Set(items.map(item => item.scan_in))]
        const [successMessage, setSuccessMessage] = useState("")
        const [errorMessage, setErrorMessage] = useState("")
        let bernies = 0
        let IFCO = 0
        containers.forEach((element) => {
            if (element.length > 4) {
                IFCO += 1
            } else {
                bernies += 1
            }
        })

        const options = {
            weekday: 'short',
            year: 'numeric',
            month: 'short',
            day: 'numeric',
        };
    const [updateItems, { loading: mutationLoadingItems, error: mutationErrorItems }] = useMutation(UPDATE_ITEMS)
    const [addPolishedItems, { loading: mutationLoading, error: mutationError }] = useMutation(ADD_POLISHED_ITEMS);

    const handleFormSubmit = (e) => {
        e.preventDefault();
        updateItems({
            variables: {
                ids: items.map((item) => item.Item.id),
            },
        })
            .then(() => {
                addPolishedItems({
                    variables: {
                        objects: items.map((item) => {
                            return {"item_id": item.Item.id, "polished_date": item.cleaning_date, "actor": item.cleaner }
                        }),
                    },
                }).then(() => {
                        setSuccessMessage("Les contenants ont étés modifiés");
                    }).catch((error) => {
                    console.error("Error adding items:", error);
                    setErrorMessage("Erreur: Les contenants n'ont pas étés modifiés");
                });
            })
            .catch((error) => {
                console.error("Error adding items:", error);
                setErrorMessage("Erreur: Les contenants n'ont pas étés modifiés");
            });
    }
        return <div>
            <h4>Résultat recherche :</h4>
            <Segment>
                <StatisticGroup widths='3'>
                    <Statistic color='brown'>
                        <StatisticValue> <Icon name='pallet' style={{paddingRight: "10px"}}/>{containers.length}
                        </StatisticValue>
                        <StatisticLabel>COLIS</StatisticLabel>
                    </Statistic>

                    <Statistic color='teal'>
                        <StatisticValue>
                            <Image src='https://cdn-icons-png.flaticon.com/512/5790/5790735.png'
                                   style={{paddingRight: "10px"}} className='inline'/>
                            {bernies}
                        </StatisticValue>
                        <StatisticLabel>Cartons</StatisticLabel>
                    </Statistic>

                    <Statistic color='olive'>
                        <StatisticValue>
                            <Image src='https://www.ifco.com/theme/img/ifco-logo.svg' style={{paddingRight: "10px"}}
                                   className='inline'/>
                            {IFCO}
                        </StatisticValue>
                        <StatisticLabel>Caisses</StatisticLabel>
                    </Statistic>
                </StatisticGroup>
            </Segment>
            <Segment>
                <StatisticGroup style={{display: "flex ", alignContent: "center", float: "center", justifyContent: "center"}}>
                    <Statistic  color='violet'>
                        <StatisticValue> <Icon name='info circle' style={{paddingRight: "10px"}}/>{items.length}
                        </StatisticValue>
                        <StatisticLabel>Contenants</StatisticLabel>
                    </Statistic>
                    {
                        type.map((el) => {
                            return <Statistic >
                                <StatisticValue>{items.filter((item) => item.Item.type === el).length}</StatisticValue>
                                <StatisticLabel>{el}</StatisticLabel>
                            </Statistic>
                        })
                    }
                    <Statistic color='red' >
                        <StatisticValue>
                            <Icon name='cut' style={{paddingRight: "10px"}}/>
                            {items.filter((item) => item.Item.polished === false).length}
                        </StatisticValue>
                        <StatisticLabel>Coupants</StatisticLabel>
                    </Statistic>

                    <Statistic color='green' >
                        <StatisticValue>
                            <Icon name='paint brush' style={{paddingRight: "10px"}}/>
                            {items.filter((item) => item.Item.polished === true).length}
                        </StatisticValue>
                        <StatisticLabel>Polis</StatisticLabel>
                    </Statistic>
                </StatisticGroup>
            </Segment>
            <Segment>
                <List divided verticalAlign='middle'>
                    {containers.map((res, index) => {
                        return <ListItem>
                            <ListContent floated='right'>
                                <Button color={'red'} onClick={() => {
                                    setItems(items.filter((item) => item.scan_in !== res))

                                }}><Icon name={'delete'}/></Button>
                            </ListContent>

                            <ListContent>
                                <div style={{
                                    display: "flex", textAlign: "center",
                                    verticalAlign: 'middle', alignContent: "space-between", alignItems: "center"
                                }}>
                                    <Label tag color='blue' style={{marginLeft: "5px"}}>{index + 1}</Label>
                                    <Label style={{marginLeft: "15px", marginRight: "15px"}}>N° Colis</Label>
                                    <b>{res}</b>
                                    <Label style={{marginLeft: "15px", marginRight: "15px"}}>Type</Label>
                                    <b>{items.filter((item) => item.scan_in === res)[0].Item.type}</b>
                                    <Label style={{marginLeft: "15px", marginRight: "15px"}}>NB contenants</Label>
                                    <b>{items.filter((item) => item.scan_in === res).length}</b>
                                    <Label style={{marginLeft: "15px", marginRight: "15px"}}>Heure lavage</Label>
                                    <b>{new Date(items.filter((item) => item.scan_in === res)[0].cleaning_date).toLocaleTimeString('fr-FR', options)}</b>
                                    <Label style={{marginLeft: "15px", marginRight: "15px"}}>Déjà polis ?</Label>
                                    <b>{items.filter((item) => item.scan_in === res && item.Item.polished === true).length}</b>
                                </div>
                            </ListContent>
                        </ListItem>
                    })
                    }

                </List>
            </Segment>
            <Segment>
                <h4 style={{marginLeft: "25%", minWidth: "200px"}}>En appuyant sur "Valider", {items.filter((item) => item.Item.polished === false).length} contenants vont être considérés comme polis</h4>
                <Button color={'green'} style={{marginLeft: "40%", minWidth: "200px"}} onClick={handleFormSubmit}><Icon
                name={'check'}/> Valider</Button>
            <p style={{color: "red", textAlign: "center"}}>{errorMessage}</p>
                <p style={{color: "green", textAlign: "center"}}>{successMessage}</p>
            </Segment>
        </div>
}


const ScratchedItem = () => {
    const { initialized } = useKeycloak();
    const [toshow, setToshow] = useState(null)
    const [results, setResults] = useState([])

    const show = (value) => {
        if(value.Cleaning !== undefined) {
            if (value.Cleaning.length > 0) {
                setResults(value)
                setToshow(true)
            }

        }
        else{
            setToshow(false)
        }

    }

    return initialized ? (
        <Container className="container">
            <NavBar />
            <Row>
                <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <LeftMenu />
                </Col>
                <Col>
                    <Row>
                        <Col
                            xs={13}
                            xl={13}
                            style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
                        >
                            <h2>Recherche de contenants polis</h2>
                            <Nav show={show}/>
                            {toshow ? <Label pointing prompt style={{color: "green", textAlign: "center", marginLeft: "42%", marginTop: "-100px"}}>
                                Chargement réussi
                                <br/>
                                <Icon name={"arrow down"} />
                            </Label>:  toshow === false ? <Label pointing prompt style={{color: "red", textAlign: "center", marginLeft: "42%", marginTop: "-100px"}}>
                                Pas de résultats
                            </Label> : <p></p>}
                            {toshow ?
                                <ResultUi results={results} />
                                : toshow === false ? <p style={{color: "red", textAlign: "center"}}>Pas de résultats</p> : <p></p>}
                        </Col>
                    </Row>

                </Col>
            </Row>
        </Container>
    ) : null;
};

export default ScratchedItem;
