import specialEventShops from "./specialEventShops.json";
import shopData from "./shopData.json";

function convertDateToUTC(date) {
	return new Date(
		date.getTime() - date.getTimezoneOffset() * 60000,
	).toISOString();
}

function convertNumberBold(number) {
	return number.replace(/\d/g, (c) =>
		String.fromCodePoint(0x1d79e + c.charCodeAt(0)),
	);
}

/**
 * Convert an array to a string array readable for the Gql request
 * @param {*} array
 * @returns "["value of array"]"
 * @example
 *  ["hello", "world"] => "[\"hello\", \"world\"]"
 */
function convertArrayToGqlString(array) {
	let query = "[";

	for (const it in array) {
		query += `"${array[it]}"`;
		if (it < array.length - 1) query += ", ";
	}
	query += "]";
	return query;
}

function getSpecialEventData() {
	const data = [];

	for (const it in specialEventShops)
		data.push({
			name: it,
			colors: specialEventShops[it].colors,
			id: specialEventShops[it].id,
		});
	return data;
}

function getShopData() {
	const data = [];

	for (const it in shopData)
		data.push({
			name: it,
			colors: shopData[it].colors,
			id: shopData[it].id,
		});
	return data;
}

function getShopIdFromData(data) {
	const shopsId = [];

	for (const it in data) shopsId.push(data[it].id);
	return shopsId;
}

function getShopNameFromID(data, id) {
	for (const it in data) {
		if (data[it].id === id) return data[it].name;
	}
	return undefined;
}

function getColorFromID(data, id) {
	for (const it in data) {
		if (data[it].id === id) return data[it].colors;
	}
	return undefined;
}

function getColorsFromData(data) {
	const colors = [];

	for (const it in data) colors.push(data[it].colors);
	return colors;
}

function initColors() {
	return [
		{
			Return: "rgb(189,140,128, 1.3)",
			UnSold: "rgb(189,140,128, 0.7)",
		},
		{
			Return: "rgb(79,153,121, 1.3)",
			UnSold: "rgb(79,153,121, 0.7)",
		},
		{
			Return: "rgb(143,110,167, 1.3)",
			UnSold: "rgb(143,110,167, 0.7)",
		},
		{
			Return: "rgb(59,223,233, 1.3)",
			UnSold: "rgb(59,223,233, 0.7)",
		},
	];
}

function getWeekNumberFromDate(date) {
	const startDate = new Date(date.getFullYear(), 0, 1);
	const days = Math.floor((date - startDate) / (24 * 60 * 60 * 1000));
	const weekNumber = Math.ceil(days / 7);
	return weekNumber === 0 ? 52 : weekNumber;
}

export {
	convertDateToUTC,
	initColors,
	convertArrayToGqlString,
	getSpecialEventData,
	getShopIdFromData,
	getColorsFromData,
	getShopNameFromID,
	getColorFromID,
	getWeekNumberFromDate,
	convertNumberBold,
	getShopData,
};
