import React, {useEffect, useRef, useState} from "react";
import { initializeApp } from "firebase/app";
import {getStorage, ref, listAll, getMetadata, getDownloadURL} from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, signInAnonymously } from "firebase/auth";
import { firebaseConfig } from "../utils";
import {
    Button,
    Card,
    CardContent, CardDescription, CardMeta, Checkbox, FormField, FormInput,
    Grid,
    GridColumn, GridRow, Input, TextArea,
} from "semantic-ui-react";
import DefaultModalContent from "./defaultModalContent";
import {Modal} from "react-bootstrap";
import axios from "axios";
const DefaultFolderArray = (token) => {
    const app = initializeApp(firebaseConfig);
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
            "6Led7IQpAAAAACyLa4k7hh0YivDqRBCMwvaFhzDI" // Replace with your ReCaptcha Site Key
        ),
        isTokenAutoRefreshEnabled: true,
    });

    const auth = getAuth();
    const [selectedFile, setSelectedFile] = useState(null);
    const [show, setShow] = useState(false);
    const [results, setResults] = useState([]);
    const [resultsByCategory, setResultsByCategory] = useState([]);
    const [toShow, setToShow] = useState([]);
    const [desc, setDesc] = useState("")
    const [category, setCategory] = useState("")
    const [conformity, setConformity] = useState("Conforme")

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const data = new FormData();

    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    const onFileUpload = (item) => {
        try {
            data.append(
                "data",
                selectedFile
            );
            data.append("category", category)
            data.append("conformity", conformity)
            data.append("description", desc)
            handleShow();
            axios.post("https://europe-west1-bernypro-d3577.cloudfunctions.net/addFile", data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(r => {
                if (r.status === 200) {
                    window.location.reload()
                } else {
                   console.log('erreur')
                }
            }).catch((e) => {
                console.log(e)
            })
        }
        catch (e) {
            console.log(e)
        }
    };

    useEffect(() => {
        signInAnonymously(auth)
            .then(() => {
                const storage = getStorage(app,"gs://app-pro-56fcb.appspot.com");
                const listRef = ref(storage, '/Defautheque');
                listAll(listRef).then((res) => {
                    res.prefixes.forEach((folderRef) => {
                        setResults(results=> [...results, folderRef.name])
                        const listRefChildConforme = ref(storage, '/Defautheque/'+folderRef.name + "/Conforme");
                        listAll(listRefChildConforme)
                            .then((res) => {
                                res.items.forEach((item) => {
                                    getMetadata(ref(storage, item.fullPath))
                                        .then((metadata) => {
                                            getDownloadURL(ref(getStorage(app,"gs://app-pro-56fcb.appspot.com"), item.fullPath)).then((url) => {
                                                setResultsByCategory( resultsByCategory => [...resultsByCategory, {
                                                    type: "Conforme",
                                                    category: folderRef.name,
                                                    name: item.name,
                                                    url: url.toString(),
                                                    date: metadata.timeCreated,
                                                }])
                                            })

                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            // Uh-oh, an error occurred!
                                        });
                                });


                            }).catch((error) => {
                            // Uh-oh, an error occurred!
                        });
                        const listRefChildNonConforme = ref(storage, '/Defautheque/'+folderRef.name + "/Non conforme");
                        listAll(listRefChildNonConforme)
                            .then((res) => {
                                res.items.forEach((item) => {
                                    getMetadata(ref(storage, item.fullPath))
                                        .then((metadata) => {
                                            getDownloadURL(ref(getStorage(app,"gs://app-pro-56fcb.appspot.com"), item.fullPath)).then((url) => {
                                                setResultsByCategory( resultsByCategory => [...resultsByCategory, {
                                                    type: "Non conforme",
                                                    category: folderRef.name,
                                                    name: item.name,
                                                    url: url.toString(),
                                                    date: metadata.timeCreated,
                                                }])
                                            }).catch((error) => {
                                                console.log(error)
                                                // Uh-oh, an error occurred!
                                            });
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            // Uh-oh, an error occurred!
                                        });

                                });

                            }).catch((error) => {
                            console.log(error);
                            // Uh-oh, an error occurred!
                        });
                    })})
                    .catch((error) => {
                        console.log("error : ", error.toString());
                    })})
            .catch((error) => {
                console.log("error : ", error.toString());
            });
        setToShow(resultsByCategory);
        results.sort(custom_sort)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    function custom_sort(a, b) {
        return new Date(a.date) - new Date(b.date);
    }
    resultsByCategory.sort(custom_sort)
    console.log(resultsByCategory);
    return (
        <Grid columns={4}>
            {results.map((item, index) => {
                return <GridColumn>
                    <Card>
                        <Modal
                            show={show}
                            onHide={handleClose}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <Modal.Header closeButton>
                                <Modal.Title>Ajout de contenu à la catégorie : "{category}"</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <Grid columns={3}>
                                    <GridRow>
                                        <GridColumn width={1}/>
                                    <GridColumn width={10}>

                                    <Button
                                        as="label"
                                        htmlFor="choose-file"
                                        content={selectedFile !== null ? selectedFile.name: "Upload fichier"}
                                        compact={true}
                                        labelPosition="left"
                                        icon="file"

                                    />

                                    <input type="file" id="choose-file" hidden onInput={onFileChange} accept="image/png"/>
                                        <GridColumn/>
                                    </GridColumn>

                                        <GridColumn/>
                                    </GridRow>
                                    <GridRow>
                                        <GridColumn width={1}/>
                                        <GridColumn width={15}>
                                        <TextArea value={desc} onChange={((e) => {setDesc(e.target.value)})} placeholder='Description' style={{width: "100%"}}/>
                                        </GridColumn>
                                </GridRow>
                                    <GridRow>
                                        <GridColumn width={1}/>
                                        <GridColumn width={10}>
                                            <div style={{display: 'inline-flex', alignContent: "space-between"}}>
                                            <p style={{marginRight: "10px"}}>Conforme</p>
                                            <Checkbox toggle onChange={(value) => {
                                            setConformity(!value ? "Conforme": "Non conforme")}
                                            }/>
                                            <p style={{marginLeft: "10px"}}>Non conforme</p>
                                            </div>
                                    {selectedFile !== null ? <Button
                                        content="Valider"
                                        labelPosition="left"
                                        color="green"
                                        floated={"right"}
                                        style={{alignContent: "center"}}
                                        icon="check"
                                        onClick={onFileUpload}
                                    ></Button> : <p></p>}
                                        </GridColumn>
                                    </GridRow>

                                </Grid>
                            </Modal.Body>
                        </Modal>
                    <CardContent>
                                    <a className="header">{item}</a>
                                    <CardMeta>
                                    <span className="date">{resultsByCategory.filter((toFilter) => toFilter.category === item).length !== 0 ? "Modifié le " + resultsByCategory.filter((toFilter) =>
                                        toFilter.category === item).sort(custom_sort).at(-1).date : ""}</span>
                                    </CardMeta>

                    <CardDescription>
                                <span>
                                    <i className="image icon"></i>
                                    {resultsByCategory.filter((toFilter) => toFilter.category === item).length} images
                                </span>
                    </CardDescription>
                    </CardContent>
                                <CardContent extra style={{verticalAlign: "center", alignContent: "center"}}>
                                    <a href={`/detailsCategory/${item}`}>
                                        <button className="ui primary inverted button right floated" >Voir</button>
                                    </a>
                                        <button className="ui negative inverted button right floated" onClick={() => {
                                                setShow(true)
                                                setCategory(item)
                                        }
                                        }>Ajouter</button>
                                </CardContent>



                </Card>
                </GridColumn>
                })}
            </Grid>
    );

};

export default DefaultFolderArray;
