import React, { useEffect, useState, useCallback } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import LeftMenu from "../../LeftMenu";
import NavBar from "../../Navbar";
import UserArray from "./userArray";
import axios from "axios";
const qs = require("qs");

const ManageUsers = () => {
  const { initialized } = useKeycloak();
  const [users, setUsers] = useState([]);
  const [token, setToken] = useState("");

  const getToken = useCallback(async () => {
    try {
      let data = qs.stringify({
        client_id: "test",
        client_secret: "oKHff1cPh6U0kTgaT1Qz3e8OxaG3eciC",
        grant_type: "client_credentials",
      });
      const tokenResponse = await axios.post(
        "https://iam.berny.app/auth/realms/master/protocol/openid-connect/token",
        data,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            Accept: "*/*",
          },
        }
      );
      const accessToken = tokenResponse.data.access_token;
      localStorage.setItem("DashboardKeycloakToken", accessToken);
      setToken(accessToken);
      return accessToken;
    } catch (tokenError) {
      console.error("Token request error:", tokenError);
      return null;
    }
  }, []);

  const fetchUsersWithToken = async (token, retryCount = 0) => {
    try {
      const response = await axios.get(
        "https://iam.berny.app/auth/admin/realms/master/users",
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const sortedUsers = response.data.slice().sort((b, a) => {
        const dateA = new Date(parseInt(a.createdTimestamp));
        const dateB = new Date(parseInt(b.createdTimestamp));
        return dateA - dateB;
      });

      setUsers(sortedUsers);
    } catch (error) {
      if (retryCount < 3) {
        // Limit retries to avoid infinite loop due to keycloak calling CORS problem when token isn't good
        console.error("Error fetching users:", error);
        const newToken = await getToken();
        if (newToken) {
          await fetchUsersWithToken(newToken, retryCount + 1); // Increment the retry count
        }
      } else {
        console.error("Maximum retry limit reached.");
      }
    }
  };

  useEffect(() => {
    const checkToken = async () => {
      const dashboardKeycloakToken = localStorage.getItem(
        "DashboardKeycloakToken"
      );
      if (dashboardKeycloakToken) {
        setToken(dashboardKeycloakToken);
        try {
          await fetchUsersWithToken(dashboardKeycloakToken);
        } catch (error) {
          console.error("Error fetching users:", error);
        }
      } else {
        const newToken = await getToken();
        setToken(newToken);
        if (newToken) {
          await fetchUsersWithToken(newToken);
        }
      }
    };
    checkToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getToken]);

  return initialized ? (
    <Container className="container">
      <NavBar />
      <Row>
        <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <LeftMenu />
        </Col>
        <Col
          xs={10}
          style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
        >
          <h2>Gestion des utilisateurs</h2>
          <UserArray users={users} token={token} />
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default ManageUsers;
