import ExcellentExport from "excellentexport";
import { nanoid } from "nanoid";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import { Input, Table } from "semantic-ui-react";
import LeftMenu from "../../LeftMenu";
import NavBar from "../../Navbar";

const GenerationQRCode = ({ withLeftMenu }) => {
  const [range, setRange] = useState(1000);
  const [list, setList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [formattedDate, setFormattedDate] = useState(null);

  const [ellipsisizedList, setEllipsisizedList] = useState([]);

  useEffect(() => {
    // only keep the first 10 items, and the last 10 items, with an ellipsis in between
    const ellipsisizedList =
      list.length > 10 ? [...list.slice(0, 5), null, ...list.slice(-5)] : list;
    setEllipsisizedList(ellipsisizedList);
  }, [list]);

  const genCodes = () => {
    function formatDate(d, format) {
      const month = d.getMonth() + 1;
      const map = {
        mm: month < 10 ? `0${month}` : `${month}`,
        dd: d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`,
        yy: d.getFullYear().toString().slice(-2),
        yyyy: d.getFullYear(),
      };

      return format.replace(/mm|dd|yy|yyyy/gi, (matched) => map[matched]);
    }

    const specialFormat = formatDate(date, "yymmdd");

    setFormattedDate(specialFormat);

    const list = [];
    for (let i = 0; i < range; i++) {
      var id = nanoid(8);

      //   REMOVE - AND _ DUE TO OE CAPS PROBLEM
      while (id.includes("-") || id.includes("_")) {
        id = nanoid(8);
      }
      //   REMOVE - AND _ DUE TO OE PROBLEM CAPS PROBLEM

      list.push({
        index: i + 1,
        id: `${specialFormat}${id}`,
        qrCode: `https://berny.app/${specialFormat}${id}`,
      });
    }
    setList(list);
  };

  return (
    <Col sm={withLeftMenu ? 10 : 12} style={{ paddingLeft: "0px" }}>
      <Input
        label="Nombre de QR Code à générer"
        type="number"
        min="1"
        value={range}
        onChange={(e) => setRange(e.target.value)}
      />
      <br />
      <br />

      {/* date input with button to reset to today */}
      <Input
        label="Date"
        type="date"
        value={date.toISOString().slice(0, 10)}
        onChange={(e) => setDate(new Date(e.target.value))}
      />

      <br />
      <br />

      {/* //grey */}
      <Button
        onClick={genCodes}
        style={{ backgroundColor: "#cccccc", color: "black" }}
      >
        Générer les QR Codes
      </Button>

      <br />
      <br />
      <Button
        onClick={async () => {
          const bomIssueRegex = /^ï»¿/gm;

          const fileContents = ExcellentExport.convert(
            {
              openAsDownload: true,
              format: "csv",
              filename: `${formattedDate}-${range}`,
            },
            [
              {
                name: "Tableau",
                from: {
                  array: [
                    ["#", "Contenu QR Code", "Identifiant"],
                    ...list.map((item) => [item.index, item.qrCode, item.id]),
                  ],
                },
              },
            ]
          );

          const file = new File(
            [fileContents.replace(bomIssueRegex, "")],
            `${formattedDate}-${range}.csv`,
            {
              type: "text/csv",
            }
          );

          try {
            const body = new FormData();

            body.append("file", file);

            // POST the file to http://localhost:1337/slack/qr_codes_csv
            // The body must be form data with a file field called "file"
            await fetch(process.env.REACT_APP_SLACK_BOT_URL, {
              method: "POST",
              body: body,
            });
          } catch (error) {
            console.error(error);
          }
        }}
      >
        Exporter en CSV
      </Button>

      <br />
      <br />
      <Row>
        <Col>
          <Table id="table" celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>#</Table.HeaderCell>
                <Table.HeaderCell width={6}>Contenu QR Code</Table.HeaderCell>
                <Table.HeaderCell width={5}>Identifiant</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {ellipsisizedList.length > 0 ? (
                ellipsisizedList.map((item, index) => {
                  return item === null ? (
                    <Table.Row key={"null"}>
                      <Table.Cell>...</Table.Cell>
                      <Table.Cell>...</Table.Cell>
                      <Table.Cell>...</Table.Cell>
                    </Table.Row>
                  ) : (
                    <React.Fragment key={item.qrCode}>
                      <Table.Row>
                        <Table.Cell>{item.index}</Table.Cell>
                        <Table.Cell>{item.qrCode}</Table.Cell>
                        <Table.Cell>{item.id}</Table.Cell>
                      </Table.Row>
                    </React.Fragment>
                  );
                })
              ) : (
                <Table.Row disabled>
                  <Table.Cell>XX</Table.Cell>
                  <Table.Cell>
                    Générez des codes pour les voir apparaître ici
                  </Table.Cell>
                  <Table.Cell>
                    Générez des codes pour les voir apparaître ici
                  </Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Col>
      </Row>
    </Col>
  );
};

const CodeGenerator = () => {
  const { initialized, keycloak } = useKeycloak();

  const isTeam = keycloak.tokenParsed?.groups?.includes("Team");

  return initialized ? (
    isTeam ? (
      <Container className="container">
        <NavBar restricted={!isTeam} />
        <Row>
          <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <LeftMenu />
          </Col>
          <Col
            xs={12}
            style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
          >
            <h1>Générateur de QR Code</h1>
            <GenerationQRCode withLeftMenu={true} />
          </Col>
        </Row>
      </Container>
    ) : (
      <Container className="container">
        <NavBar restricted={!isTeam} title={"Générateur de QR codes"} />
        <Row>
          {/* no leftMenu */}
          <Col
            xs={12}
            style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
          >
            <GenerationQRCode withLeftMenu={false} />
          </Col>
        </Row>
      </Container>
    )
  ) : (
    <></>
  );
};

export default CodeGenerator;
