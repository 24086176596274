import gql from "graphql-tag";
import React, { useEffect } from "react";
import { useQuery } from "@apollo/client";
import "chart.js/auto";
import { useKeycloak } from "react-keycloak";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../../Navbar";
import Menu from "../../LeftMenu";
import { Table } from "semantic-ui-react";
import _ from "lodash";

const ContainersInfo = () => {
	const query = gql`
    query MyQuery2 {
      Container(
        where: {
          id: { _regex: "^[0-9]{6,}$" }
          status: { _in: ["ready", "full"] }
        }
      ) {
        choosen_shop
        choosen_size
        max_big
        max_small
        status
        id
        choosen_delivery_date
      }
      Shop {
        name
        brand
        id
      }
    }
  `;
	const { loading, data: queryData } = useQuery(query);

	const [state, dispatch] = React.useReducer(reducer, {
		column: null,
		data: null,
		direction: null,
	});

	const { column, data, direction } = state;

	useEffect(() => {
		if (queryData) {
			const tableData = queryData.Container.map((el) =>
				mapTableData(el, queryData.Shop),
			);
			dispatch({ type: "ADD_DATA", payload: { data: tableData } });
		}
	}, [loading, queryData]);

	return (
		<div
			style={{
				padding: "50px",
				display: "block",
				textAlign: "center",
				height: "400px",
			}}
		>
			<Table
				sortable
				celled
				structured
				color={"pink"}
				inverted
				className={"TableRecap"}
			>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell
							verticalAlign={"middle"}
							sorted={column === "caisse" ? direction : null}
							onClick={() =>
								dispatch({ type: "CHANGE_SORT", column: "caisse" })
							}
						>
							Nom caisse IFCO
						</Table.HeaderCell>
						<Table.HeaderCell
							verticalAlign={"middle"}
							sorted={column === "taille" ? direction : null}
							onClick={() =>
								dispatch({ type: "CHANGE_SORT", column: "taille" })
							}
						>
							Taille de contenants
						</Table.HeaderCell>
						<Table.HeaderCell
							verticalAlign={"middle"}
							sorted={column === "lieu" ? direction : null}
							onClick={() => dispatch({ type: "CHANGE_SORT", column: "lieu" })}
						>
							Lieu de livraison
						</Table.HeaderCell>
						<Table.HeaderCell
							verticalAlign={"middle"}
							sorted={column === "date" ? direction : null}
							onClick={() => dispatch({ type: "CHANGE_SORT", column: "date" })}
						>
							Date de livraison
						</Table.HeaderCell>
						<Table.HeaderCell
							verticalAlign={"middle"}
							sorted={column === "statut" ? direction : null}
							onClick={() =>
								dispatch({ type: "CHANGE_SORT", column: "statut" })
							}
						>
							Statut
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{data ? (
						data.map((el) => Tablebody(el))
					) : (
						<Table.Row> Chargement en cours ...</Table.Row>
					)}
				</Table.Body>
			</Table>
		</div>
	);
};

function reducer(state, action) {
	switch (action.type) {
		case "ADD_DATA":
			return {
				...state,
				data: action.payload.data,
			};
		case "CHANGE_SORT":
			if (state.column === action.column) {
				return {
					...state,
					column: action.column,
					data: [...state.data].reverse(),
					direction:
						state.direction === "ascending" ? "descending" : "ascending",
				};
			}

			return {
				...state,
				column: action.column,
				data: _.sortBy(state.data, (o) => o[action.column]),
				direction: "ascending",
			};
		default:
			return { ...state };
	}
}

function mapTableData(el, shops) {
	const orderDate = el.choosen_delivery_date
		? new Date(el.choosen_delivery_date)
		: null;
	const options = {
		weekday: "long",
		year: "numeric",
		month: "long",
		day: "numeric",
	};
	const test = shops.find((element) => element.id === el.choosen_shop);
	return {
		caisse: el.id,
		taille: el.choosen_size === "small" ? "Petits" : "Grands",
		lieu: test ? `${test.brand} - ${test.name}` : "Pas de magasin",
		date: orderDate?.toLocaleString("fr-FR", options) ?? "Date inconnue",
		statut: el.status === "ready" ? "Livré" : "Non livré",
	};
}

function Tablebody(el, shops) {
	return (
		<Table.Row key={el.caisse}>
			<Table.Cell>{el.caisse}</Table.Cell>
			<Table.Cell>{el.taille}</Table.Cell>
			<Table.Cell>{el.lieu}</Table.Cell>
			<Table.Cell>{el.date}</Table.Cell>
			<Table.Cell>{el.statut}</Table.Cell>
		</Table.Row>
	);
}

const Containers = () => {
	const { initialized } = useKeycloak();
	return initialized ? (
		<Container className="container">
			<div>
				<NavBar />
				<Row>
					<Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
						<Menu />
					</Col>
					<Col sm={11} xl={10}>
						<div>
							<div style={{ marginTop: "100px", marginLeft: "15px" }}>
								<div>
									<h5 style={{ margin: "10px", textAlign: "center" }}>
										Historique caisses IFCO
									</h5>
									<ContainersInfo />
								</div>
							</div>
						</div>
					</Col>
				</Row>
			</div>
		</Container>
	) : null;
};

export { Containers };
