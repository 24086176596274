import React from "react";
import { Bar } from "react-chartjs-2";
import graphql2chartjs from "graphql2chartjs";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import {
	convertDateToUTC,
	getWeekNumberFromDate,
	convertNumberBold,
} from "../Tools";

const options = {
	scales: {
		yAxes: [
			{
				ticks: {
					beginAtZero: true,
				},
			},
		],
		x: {
			ticks: {
				font: {
					size: 13,
					family: "Arial",
				},
				callback: function (index) {
					const arr = this.getLabelForValue(index).split("-");
					return `${arr[0]}-${convertNumberBold(arr[1])}`;
				},
			},
		},
	},
	plugins: {
		legend: {
			display: false,
		},
		datalabels: {
			display: true,
			color: "black",
			align: "end",
			anchor: "end",
			labels: {
				title: {
					font: {
						weight: "bold",
					},
				},
				value: {
					color: "white",
				},
			},
			formatter: function (value) {
				return parseFloat(value).toFixed(0);
			},
		},
	},
};

function updateByWeek(data) {
	let newData = null;

	if (data.Lavages.length < 1) return null;
	let lastDate = new Date(data.Lavages[0].label);
	let weekNb = getWeekNumberFromDate(lastDate) - 1;
	const weekArr = [];
	const cleanning = [];
	let cleanningTmp = [];

	newData = { Lavages: [] };
	data.Lavages.forEach((elem) => {
		const currentDate = new Date(elem.label);
		const weekNbel = getWeekNumberFromDate(currentDate);

		if (weekNbel !== weekNb) {
			weekArr.push(`${lastDate.getFullYear()}-${weekNb}`);
			cleanning.push(cleanningTmp.reduce((partialSum, a) => partialSum + a, 0));

			weekNb = weekNbel;
			cleanningTmp = [];
		}
		cleanningTmp.push(elem.data);
		lastDate = currentDate;
	});
	weekArr.push(`${lastDate.getFullYear()}-${weekNb}`);
	cleanning.push(cleanningTmp.reduce((partialSum, a) => partialSum + a, 0));
	for (let it = 0; it < cleanning.length; it++) {
		newData.Lavages.push({ label: weekArr[it], data: cleanning[it] });
	}
	return newData;
}

const Chart = ({ reason, date }) => {
	let query;
	if (reason === "Annee") {
		query = gql`
      query {
        Lavages: cleanings {
          label: date
          data: count
        }
      }
    `;
	} else if (reason === "Mois") {
		const da = new Date();
		da.setDate(1);
		const d = convertDateToUTC(da);
		query = gql`
  query {
    Lavages: cleanings(where: {date: {_gte: "${d.split("T")[0]}"}}){
    label: date
    data: count
    }
  }
`;
	} else {
		query = gql`
  query {
    Lavages: cleanings(where: {date: {_gte: "${
			convertDateToUTC(date[0]).split("T")[0]
		}"}, _and: {date: {_lte: "${convertDateToUTC(date[1]).split("T")[0]}"}}}){
    label: date
    data: count
    }
  }
`;
	}
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const g2c = new graphql2chartjs();
	const r = Math.floor(Math.random() * 255);
	const g = Math.floor(Math.random() * 255);
	const b = Math.floor(Math.random() * 255);
	const rgb = `rgb(${r},${g},${b}, 1)`;
	const newData = updateByWeek(data);

	g2c.add(newData ?? data, (dataSetName, dataPoint) => {
		return {
			...dataPoint,
			chartType: "bar",
			backgroundColor: rgb,
		};
	});
	return (
		<>
			<Bar data={g2c.data} options={options} />
		</>
	);
};

const Cleanings = ({ isAnnualView, date }) => {
	return (
		<div>
			<div style={{ margin: "10px", paddingTop: "65px" }}>
				<div key="bar">
					<div style={{ marginBottom: "20px" }} id="bar">
						<div className="bar">
							<h5>Lavage</h5>
							<Chart reason={isAnnualView} date={date} />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export { Cleanings };
