import { useMutation, useQuery } from "@apollo/client";
import momentTimezone from "moment-timezone";
import gql from "graphql-tag";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "react-circular-progressbar/dist/styles.css";
import { useKeycloak } from "react-keycloak";
import { useParams } from "react-router-dom";
import { Dropdown, Header, Icon, Image, Step, Tab } from "semantic-ui-react";
import Menu from "../../LeftMenu";
import NavBar from "../../Navbar";

/**
 * @param {string} d - Date
 * @returns {string} - Timezone
 */
function dateTimezone(d) {
  let dateTimezone;
  switch (d.split("+")) {
    case "01:00":
    case "02:00":
      dateTimezone = "Europe/Paris";
      break;
    default:
      dateTimezone = "UTC";
      break;
  }

  return dateTimezone;
}
const GET_ITEM_DETAILS = gql`
  query GetItemDetails($id: String!) {
    Item_by_pk(id: $id) {
      id
      size
      arrival_country
      arrival_date
      type
      is_defunct
      Format {
        name
      }
    }
  }
`;

const UPDATE_ITEM_DEFUNCT = gql`
  mutation UpdateItemDefunct($id: String!, $isDefunct: Boolean!) {
    update_Item_by_pk(
      pk_columns: { id: $id }
      _set: { is_defunct: $isDefunct }
    ) {
      id
      is_defunct
    }
  }
`;

const Description = ({ id }) => {
  const { loading, error, data, refetch } = useQuery(GET_ITEM_DETAILS, {
    variables: { id },
  });

  const [updateItemDefunct] = useMutation(UPDATE_ITEM_DEFUNCT);

  const handleCheckboxChange = () => {
    const newIsDefunct = !data.Item_by_pk.is_defunct;

    updateItemDefunct({
      variables: { id, isDefunct: newIsDefunct },
    });
    refetch();
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Header
      as="h1"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: "100%",
      }}
    >
      <Header.Content>
        {data.Item_by_pk.id} ({data.Item_by_pk.Format.name ?? "N/A"})
        <Header.Subheader>
          <div className="ui toggle checkbox" style={{ marginTop: "15px" }}>
            <input
              type="checkbox"
              name="defunct"
              onChange={handleCheckboxChange}
              checked={data.Item_by_pk.is_defunct}
            />
            <label>
              {data.Item_by_pk.is_defunct ? "Défectueux" : "Non défectueux"}
            </label>
          </div>
        </Header.Subheader>
      </Header.Content>
    </Header>
  );
};

const Details = ({ id }) => {
  const CREATE_DEFUNCT_ITEM = gql`
    mutation CreateDefunctItem($item_id: String!, $reason: uuid!) {
      insert_Defunct_item(
        objects: { item_id: $item_id, reason: $reason }
        on_conflict: {
          constraint: Defunct_item_item_id_key
          update_columns: [reason]
        }
      ) {
        affected_rows
      }
    }
  `;

  const query = gql`
    query MyQuery3 {
      Item_by_pk(id: "${id}") {
        id
        size
        arrival_country
        arrival_date
        type
        is_defunct
        Format {
          name
        }
      }
      defunct_reason {
        id
        reason_name
      }
    }
  `;
  // const GET_DEFUNCT_ITEM = gql`
  //   query get_defunct_item{
  //     Defunct_item(where: {item_id: {_eq: "${id}"}}){
  //       reason
  //     }
  //   }
  // `;

  const { loading, error, data } = useQuery(query);
  const [selectedReason, setSelectedReason] = useState(null);
  const [createDefunctItem] = useMutation(CREATE_DEFUNCT_ITEM);

  // const [dataDefunct] = useQuery(GET_DEFUNCT_ITEM);

  const handleReasonChange = (e, { value }) => {
    const selectedReasonObject = data.defunct_reason.find(
      (reason) => reason.reason_name === value
    );

    setSelectedReason(value);
    console.log(data.Item_by_pk.id);
    createDefunctItem({
      variables: {
        item_id: data.Item_by_pk.id,
        reason: selectedReasonObject.id,
      },
    })
      .then(() => {
        // Handle the result if needed
        console.log("New defunct_item created with ID:");
      })
      .catch((error) => {
        // Handle the error if the mutation fails
        console.error("Error creating defunct_item:", error.message);
      });
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const reasonOptions = data.defunct_reason.map((reason) => ({
    key: reason.id,
    text: reason.reason_name,
    value: reason.reason_name,
  }));

  const date = momentTimezone
    .tz(
      data.Item_by_pk.arrival_date,
      dateTimezone(data.Item_by_pk.arrival_date)
    )
    .toDate();

  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <>
      <Col
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
          marginBottom: "30px",
          marginTop: "30px",
        }}
      >
        <Image
          style={{
            margin: ".5em",
          }}
          src={`https://api.qrserver.com/v1/create-qr-code/?&data=${`https://berny.app/${data.Item_by_pk.id}`}`}
          alt="QR Code"
        />
      </Col>
      <Col
        style={{
          padding: "0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          marginBottom: "30px",
          marginTop: "30px",
        }}
      >
        <Row>
          <li
            style={{
              listStyle: "none",
              fontWeight: "bold",
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          >
            Barquette :
            <span style={{ fontWeight: "normal", marginLeft: "5px" }}>
              {data.Item_by_pk.id}
            </span>
          </li>
        </Row>
        <Row>
          <li
            style={{
              listStyle: "none",
              fontWeight: "bold",
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          >
            Date d'initialisation :
            <span style={{ fontWeight: "normal", marginLeft: "5px" }}>
              {date.toLocaleDateString("fr-FR", options)}
            </span>
          </li>
        </Row>
        <Row>
          <li
            style={{
              listStyle: "none",
              fontWeight: "bold",
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          >
            Format :
            <span style={{ fontWeight: "normal", marginLeft: "5px" }}>
              {data.Item_by_pk.Format.name ?? "N/A"}
            </span>
          </li>
        </Row>
        <Row>
          <li
            style={{
              listStyle: "none",
              fontWeight: "bold",
              paddingRight: "10px",
              paddingLeft: "10px",
            }}
          >
            Origine :
            <span style={{ fontWeight: "normal", marginLeft: "5px" }}>
              {data.Item_by_pk.arrival_country ?? "N/A"}
            </span>
          </li>
        </Row>
        {data.Item_by_pk.is_defunct ? (
          <Row>
            <li
              style={{
                listStyle: "none",
                fontWeight: "bold",
                paddingRight: "10px",
                paddingLeft: "10px",
              }}
            >
              Cause défaut :
              <Dropdown
                style={{ marginLeft: "10px" }}
                placeholder="Cause du défaut"
                search
                selection
                options={reasonOptions}
                value={selectedReason}
                onChange={handleReasonChange}
              />
            </li>
          </Row>
        ) : null}
      </Col>
    </>
  );
};

const Infos = ({ id }) => {
  const query = gql`
		query MyQuery4 {
			Item_by_pk(id: "${id}") {
				Cleanings(order_by: {cleaning_date: asc}) {
					Cleaner {
						enterprise
					}
					cleaning_date
				}
				Deliveries(order_by: {delivery_date: asc}) {
					delivery_date
					transportedIn
					Shop {
						name
						brand
					}
				}
				Delivery_backs(order_by: {date: asc}) {
					date
					is_unsold
					actor
					shop_end
					lot
					Shop {
						brand
						name
					}
				}
				transits(order_by: {date: asc}) {
					date
					transited_in
					Sender {
						brand
						name
					}
					warehouse {
						brand
						name
					}
				}
			}
			items_per_container(where: {item_id: {_eq: "${id}"}}) {
				container_id
			}
		}
	`;

  const { loading, error, data } = useQuery(query);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  // We want a unified format, with the name of the cleaner/shop, the date and the type of action, in chronological order
  const infos = [];
  data.Item_by_pk.Cleanings.forEach((el) => {
    infos.push({
      name: el.Cleaner.enterprise,
      date: el.cleaning_date,
      type: "Lavage",
    });
  });
  data.Item_by_pk.Deliveries.forEach((el) => {
    infos.push({
      name: `${el.Shop.brand} - ${el.Shop.name}`,
      date: el.delivery_date,
      parcel: el.transportedIn,
      type: "Livraison",
    });
  });
  data.Item_by_pk.Delivery_backs.forEach((el) => {
    infos.push({
      name: `${el.Shop.brand} - ${el.Shop.name}`,
      date: el.date,
      returnType: el.shop_end ? "shop_end" : el.is_unsold ? "unsold" : el.actor,
      type: "Retour",
      lot: el.lot
        ? momentTimezone.tz(el.lot, dateTimezone(el.lot)).toDate()
        : null,
    });
  });
  data.Item_by_pk.transits.forEach((el) => {
    infos.push({
      name: (
        <>
          {el.Sender.name} vers {el.warehouse.name}
        </>
      ),
      date: el.date,
      parcel: el.transited_in,
      type: "Transit",
    });
  });

  infos.sort((a, b) => {
    return new Date(a.date) - new Date(b.date);
  });

  const missingStep = (type, upcoming = false) => {
    return (
      <Step
        disabled={!upcoming}
        active={upcoming}
        style={{
          justifyContent: "flex-start",
          flexGrow: 0,
        }}
      >
        <Icon
          name={
            type === "Lavage"
              ? "shower"
              : type === "Retour"
              ? "recycle"
              : type === "Transit"
              ? "exchange"
              : "truck"
          }
        />
        <Step.Content>
          <Step.Title
            style={{
              paddingTop: "5px",
              textTransform: "uppercase",
            }}
          >
            {type}
          </Step.Title>
          <Step.Description>
            {upcoming
              ? "À venir"
              : type === "Transit"
              ? "Pas de transit"
              : "Manquant"}
          </Step.Description>
        </Step.Content>
      </Step>
    );
  };

  console.log(infos);

  const convertToStep = (el) => {
    return (
      <Step
        style={{
          justifyContent: "flex-start",
          flexGrow: 0,
        }}
      >
        <Icon
          color={
            el.type === "Lavage"
              ? "purple"
              : el.type === "Retour"
              ? "orange"
              : el.type === "Transit"
              ? "blue"
              : "green"
          }
          name={
            el.type === "Lavage"
              ? "shower"
              : el.type === "Retour"
              ? "recycle"
              : el.type === "Transit"
              ? "exchange"
              : "truck"
          }
        />
        <Step.Content>
          <Step.Title
            style={{
              paddingTop: "5px",
              textTransform: "uppercase",
              color:
                el.type === "Lavage"
                  ? "purple"
                  : el.type === "Retour"
                  ? "orange"
                  : el.type === "Transit"
                  ? "blue"
                  : "green",
            }}
          >
            {el.name}
          </Step.Title>
          <Step.Description>
            {el.returnType ? (
              <>
                <span style={{ fontWeight: "bold" }}>
                  {el.returnType === "unsold"
                    ? "Invendu"
                    : el.returnType === "collecteur"
                    ? "Retour collecteur"
                    : el.returnType === "shop_end"
                    ? "Arrêt magasin"
                    : "Retour client"}
                </span>
                <br />
              </>
            ) : el.parcel ? (
              <>
                <span style={{ fontWeight: "bold" }} title={el.parcel}>
                  {
                    // If longer than 10 characters, display "IFCO ...xxxx"
                    el.parcel.length > 10
                      ? `IFCO ...${el.parcel.slice(-4)}`
                      : el.parcel
                  }
                </span>
                <br />
              </>
            ) : null}
            {el.container &&
            el.returnType !== "collecteur" &&
            el.container.length <= 3 ? (
              <>
                <span style={{ fontWeight: "bold" }}>Bac {el.container}</span>
                <br />
              </>
            ) : null}
            {el.lot ? (
              <>
                Lot du{" "}
                {el.lot.toLocaleDateString("fr-FR", {
                  timeZone: "Europe/Paris",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
                <br />
              </>
            ) : null}
            Le{" "}
            {el.type === "Livraison" || el.type === "Transit"
              ? new momentTimezone.tz(el.date, dateTimezone(el.date))
                  .toDate()
                  .toLocaleDateString("fr-FR", {
                    timeZone: "Europe/Paris",
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  })
              : new momentTimezone.tz(el.date, dateTimezone(el.date))
                  .toDate()
                  .toLocaleString("fr-FR", {
                    timeZone: "Europe/Paris",
                    weekday: "long",
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour12: false,
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
          </Step.Description>
        </Step.Content>
      </Step>
    );
  };

  const groups = [];
  let currentGroup = {};

  function addGroup(upcoming = false) {
    if (upcoming) {
      const group = [];

      if (currentGroup.cleaning) {
        group.push(currentGroup.cleaning);
      } else if (
        !currentGroup.transit &&
        !currentGroup.delivery &&
        !currentGroup.return
      ) {
        group.push(
          missingStep(
            "Lavage",
            upcoming &&
              !currentGroup.transit &&
              !currentGroup.delivery &&
              !currentGroup.return
          )
        );
      }

      if (currentGroup.transit) {
        if (!currentGroup.cleaning) {
          group.push(
            missingStep(
              "Lavage",
              upcoming && !currentGroup.delivery && !currentGroup.return
            )
          );
        }
        group.push(currentGroup.transit);
      } else if (
        currentGroup.cleaning &&
        !currentGroup.delivery &&
        !currentGroup.return
      ) {
        group.push(missingStep("Transit", upcoming));
      }

      if (currentGroup.delivery) {
        if (!currentGroup.cleaning) {
          group.push(
            missingStep(
              "Lavage",
              upcoming && !currentGroup.delivery && !currentGroup.return
            )
          );
        }
        if (!currentGroup.transit) {
          group.push(
            missingStep(
              "Transit",
              upcoming && !currentGroup.delivery && !currentGroup.return
            )
          );
        }
        group.push(currentGroup.delivery);
      } else if (
        currentGroup.cleaning &&
        currentGroup.transit &&
        !currentGroup.return
      ) {
        group.push(missingStep("Livraison", upcoming && !currentGroup.return));
      } else {
        group.push(
          <Step disabled>
            <Icon name="ellipsis horizontal" disabled />
          </Step>
        );
      }

      if (currentGroup.return) {
        group.push(currentGroup.return);
      } else if (currentGroup.delivery) {
        group.push(missingStep("Retour", upcoming));
      } else if (currentGroup.cleaning && currentGroup.transit) {
        group.push(
          <Step disabled>
            <Icon name="ellipsis horizontal" disabled />
          </Step>
        );
      }

      groups.push(group);
    } else {
      groups.push([
        currentGroup.cleaning ?? missingStep("Lavage"),
        currentGroup.transit ?? missingStep("Transit"),
        currentGroup.delivery ?? missingStep("Livraison"),
        currentGroup.return ?? missingStep("Retour"),
      ]);
    }
    currentGroup = {};
  }

  infos.forEach((el, index) => {
    const isLast = index === infos.length - 1;

    switch (el.type) {
      case "Lavage":
        if (
          currentGroup.cleaning ||
          currentGroup.delivery ||
          currentGroup.return ||
          currentGroup.transit
        ) {
          addGroup();
        }
        currentGroup.cleaning = convertToStep(el);
        break;
      case "Transit":
        if (
          currentGroup.transit ||
          currentGroup.delivery ||
          currentGroup.return
        ) {
          addGroup();
        }
        currentGroup.transit = convertToStep(el);
        break;
      case "Livraison":
        if (currentGroup.delivery || currentGroup.return) {
          addGroup();
        }
        currentGroup.delivery = convertToStep(el);
        break;
      case "Retour":
        if (currentGroup.return) {
          addGroup();
        }
        if (isLast)
          el.container = data.items_per_container[0]?.container_id ?? "inconnu";
        currentGroup.return = convertToStep(el);
        break;
      default:
        break;
    }
  });

  if (currentGroup.return) {
    addGroup();
  }

  addGroup(true);

  return (
    <>
      {groups.reverse().map((group, index) => {
        return (
          <div
            key={groups.length - index}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "20px",
              width: "100%",
            }}
          >
            {/* display the number of the cycle, and display whether or not it is the last ("current") cycle */}
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "10px",
              }}
            >
              {/* large number */}
              <div
                style={{
                  fontSize: "2em",
                  fontWeight: "bold",
                  width: "4em",
                  color: index === 0 ? "green" : "grey",
                }}
              >
                Cycle {groups.length - index}
              </div>
              <Icon name="circle" color={index === 0 ? "green" : "grey"} />
            </div>

            <Step.Group widths={4}>
              {group[0]}
              {group[1]}
              {group[2]}
              {group[3]}
            </Step.Group>
          </div>
        );
      })}
    </>
  );
};

const Item = () => {
  const params = useParams();
  const contenant = params.id;
  const { initialized } = useKeycloak();
  return initialized ? (
    <Container className="container">
      <div>
        <NavBar />
        <Row>
          <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Menu />
          </Col>
          <Col sm={10}>
            <div>
              <div style={{ marginTop: "100px", marginLeft: "15px" }}>
                <div>
                  <Description id={contenant} />
                  <div
                    className="metrics"
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      paddingBottom: "50px",
                    }}
                  >
                    <Tab
                      panes={[
                        {
                          menuItem: {
                            key: "cycles",
                            content: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "1.5em",
                                  fontWeight: "bold",
                                }}
                              >
                                <Icon name="recycle" />
                                <div>Cycles</div>
                              </div>
                            ),
                          },
                          render: () => (
                            <Tab.Pane style={{ marginBottom: "20px" }}>
                              <Infos id={contenant} />
                            </Tab.Pane>
                          ),
                        },
                        {
                          menuItem: {
                            key: "details",
                            content: (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  fontSize: "1.5em",
                                  fontWeight: "bold",
                                }}
                              >
                                <Icon name="info" />
                                <div>Details</div>
                              </div>
                            ),
                          },
                          render: () => (
                            <Tab.Pane
                              style={{
                                display: "flex",
                                justifyContent: "center",
                              }}
                            >
                              <Details id={contenant} />
                            </Tab.Pane>
                          ),
                        },
                      ]}
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  ) : null;
};
export { Item };
