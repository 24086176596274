import "chart.js/auto";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { useState } from "react";
import "chart.js/auto";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "semantic-ui-react";
import { FaBox, FaShoppingCart } from "react-icons/fa";
import "react-circular-progressbar/dist/styles.css";
import { Col, Row } from "react-bootstrap";

const Item = ({ _package }) => {
  return (
    <Row
      style={{
        padding: "15px",
        justifyContent: "space-between",
        fontSize: "16px",
        fontWeight: "normal",
      }}
    >
      <Col sm={6} style={{display:"flex", columnGap:"15px"}}>
        <FaShoppingCart color="green" />
        <a
          href={`/operation/shop/${_package.shop}`}
          style={{ fontSize: "15px" }}
        >
          {`${_package.brand} - ${_package.name}`}
        </a>
        <br />
      </Col>
      <Col sm={6} style={{display:"flex", columnGap:"15px"}}>
        <FaBox color="brown" />
        Colis non-ouverts: {_package.count}
      </Col>
    </Row>
  );
};

const ListContainerByStatus = ({ data, status }) => {
  const listDefaultValue = 3;
  const [listLen, setListLien] = useState(listDefaultValue);
  const sortedItems = [...data.nonOpenContainerByShop].sort((a, b) => {
    if (a.brand < b.brand) return -1;
    if (a.brand > b.brand) return 1;
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });

  const html = sortedItems
    .slice(0, sortedItems.length)
    .map((_package) => (
      <Item _package={_package} key={_package.id} />
    ));

  return (
    <div
      style={{
        paddingLeft: "50px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        align: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontSize: "15px", fontWeight: "bold", width: "100%" }}>
        {status}
        <div style={{ height: "15px" }} />
        {data.length < 1 ? (
          <div
            style={{
              marginLeft: "10px",
              fontSize: "15px",
              fontWeight: "normal",
              width: "100%",
            }}
          >
            Pas de colis {status}
          </div>
        ) : (
          <div>
            {html}
            {data.length > listDefaultValue ? (
              <div>
                <div style={{ height: "10px" }} />
                <Button
                  onClick={() => {
                    listLen === listDefaultValue
                      ? setListLien(data.length)
                      : setListLien(listDefaultValue);
                  }}
                >
                  {" "}
                  {listLen === listDefaultValue ? "+" : "-"}{" "}
                </Button>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

const OperationAllInfos = ({ shopPackageStatus }) => {
  return (
    <div
      style={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        align: "center",
        justifyContent: "space-between",
        paddingTop: "2%",
      }}
    >
      <ListContainerByStatus
        data={shopPackageStatus.data}
        status={"Colis non-ouverts"}
      />
    </div>
  );
};

export { OperationAllInfos };
