import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import { useKeycloak } from "react-keycloak";
import { Redirect } from "react-router-dom";
import "./App.css";
import { OperationPage } from "./conponents/operation/OperationPage";
function App() {
	const { initialized, keycloak} = useKeycloak();
	if (keycloak.tokenParsed?.groups?.includes("Graveur")) {
		return <Redirect to={"/qrcodes"} />;
	}
	return initialized ? keycloak.authenticated ? <OperationPage /> : <h1> Unauthenticated </h1>: <h1> Uninitialized </h1>;
}

export default App;
