import "bootstrap/dist/css/bootstrap.min.css";
import "chart.js/auto";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import { useParams } from "react-router-dom";
import Menu from "../../../LeftMenu";
import NavBar from "../../../Navbar";
import { GetListOfContainerByShopID } from "../../logistic/dashboardBac/Query";
import { StateCollectionContainer } from "../../logistic/dashboardBac/StateCollectionsContainer";
import {
  GetPackagesDataByShop,
  GetShopDataByID,
  GetContainerShopEnd,
} from "./Query.js";
import { ShopPackageInfo } from "./ShopPackageInfo.js";
import { ShopPackagesCollectionStatus } from "./ShopPackagesCollectionStatus.js";
import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useState } from "react";

const OperationStatePackage = () => {
  const { initialized } = useKeycloak();
  const params = useParams();
  const shopID = params.id;

  const rsltShopData = GetShopDataByID(shopID);
  const rsltContainer = GetListOfContainerByShopID(shopID);

  const [containerForQuery, setContainerForQuery] = useState(null);

  const { loading: loadingContainerShopEnd, data: containerShopEnd } = useQuery(
    GetContainerShopEnd(shopID)
  );

  useEffect(() => {
    if (containerShopEnd && containerShopEnd.containerShopEnd.length > 0) {
      const container = containerShopEnd.containerShopEnd[0]?.container || null;
      setContainerForQuery(container);
    }
  }, [containerShopEnd]);

  const { loading: loadingRslt, data: rslt } = useQuery(
    GetPackagesDataByShop(shopID, containerForQuery)
  );

  if (loadingContainerShopEnd || loadingRslt) {
    return <p>Chargement...</p>;
  }

  return initialized ? (
    <Container className="container">
      <div>
        <NavBar />
        <Row>
          <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Menu />
          </Col>
          <Col sm={11} xl={10}>
            {rslt !== undefined ? (
              <div
                style={{
                  marginTop: "100px",
                  marginLeft: "15px",
                  marginRight: "15px",
                  marginBottom: "50px",
                }}
              >
                <StateCollectionContainer
                  rsltContainer={rsltContainer}
                  rsltShopData={rsltShopData}
                />
                <h2>
                  {" "}
                  Status des colis du magasin:{" "}
                  {rsltShopData.data !== undefined
                    ? rsltShopData.data.Shop[0].name
                    : "Loading . . ."}
                </h2>
                <div style={{ height: "20px" }} />
                <ShopPackageInfo shopPackageData={rslt} />
                <div style={{ height: "40px" }} />
                <hr />
                <div style={{ height: "30px" }} />
                <ShopPackagesCollectionStatus shopPackageData={rslt} />
              </div>
            ) : (
              rslt
            )}
          </Col>
        </Row>
      </div>
    </Container>
  ) : null;
};

export { OperationStatePackage };
