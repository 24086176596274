import React, {useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getDatabase, ref, child, get } from "firebase/database";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, signInAnonymously } from "firebase/auth";
import { firebaseConfig } from "../utils";
import { formatDate } from "../utils";
import reCAPTCHA from "react-google-recaptcha-v3"
import { LinkContainer } from "react-router-bootstrap";
import {
  DropdownItem,
  DropdownMenu,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
  Dropdown, Menu
} from "semantic-ui-react";


const AnomalyArray = (token) => {
  const app = initializeApp(firebaseConfig);
  console.log(token);
  initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider(
      "6Led7IQpAAAAACyLa4k7hh0YivDqRBCMwvaFhzDI" // Replace with your ReCaptcha Site Key
    ),
    isTokenAutoRefreshEnabled: true,
  });

  const auth = getAuth();
  const [anomalyData, setAnomalyData] = useState([]);
  const [creators, setCreators] = useState([]);
  const [selectedCleaner, setSelectedCleaner] = useState("");
  const [anomalyType, setAnomalyType] = useState([]);
  const [selectedAnomalyType, setSelectedAnomalyType] = useState("");
  const [toShow, setToShow] = useState([])

  useEffect(() => {
    setCreators([]);
    signInAnonymously(auth)
      .then(() => {
        const dbRef = ref(getDatabase(app, "https://app-pro-56fcb-default-rtdb.europe-west1.firebasedatabase.app/"));
        get(child(dbRef, `/suivi`)).then(async (snapshot) => {
            console.log(snapshot);
            if (snapshot.exists()) {
              const ref = await findDealtFalse(snapshot.val());
              setAnomalyData(ref);
              setToShow(ref);
            } else {
              console.log("No data available");
            }
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.log("error : ", error.toString());
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findDealtFalse = (data) => {
    const filteredItems = [];
    Object.keys(data).forEach((key) => {
      const currentItem = data[key];
      if (typeof currentItem === "object" && currentItem !== null) {
        if (currentItem.hasOwnProperty("dealt")) {
          filteredItems.push({ key, data: currentItem });
          setCreators(creators => [...creators, currentItem["creator"]])
          setAnomalyType(anomalyType => [...anomalyType, currentItem["dealt"]? "Traitées": "En cours"])
        }
        filteredItems.push(...findDealtFalse(currentItem));
      }
    });
    return filteredItems;
  };

  function custom_sort(a, b) {
    return new Date(a.data.date).getTime() - new Date(b.data.date).getTime();
  }

  anomalyData.sort(custom_sort)
  anomalyData.reverse()
  const arrayUniqueByKey = [...new Map(creators.map(item => [item, item])).values()];
  const arrayUniqueByKeyAnomalyType = [...new Map(anomalyType.map(item => [item, item])).values()];
  return (
      <>
       <div className={"inline-flex"}> <Dropdown text={selectedCleaner}
                  icon='filter'
                  labeled
                  button
                  placeholder='Sélectionner laveur'
                  selection
                  className='icon'>

          <DropdownMenu>
            {arrayUniqueByKey.map((item, index) => {
              return <DropdownItem onClick={() => {
                setToShow(anomalyData)
                setCreators(creators => [...creators, "Tous"])
                setSelectedCleaner(item);
                if(item === "Tous"){
                  setToShow(toShow => toShow.filter((toFilter) => selectedAnomalyType !== "" && selectedAnomalyType !== "Tous" ?
                      toFilter.data.dealt === selectedAnomalyType: toFilter.data.dealt !== ""))
                }
                else{
                  setToShow(toShow => toShow.filter((toFilter) => (selectedAnomalyType !== "" && selectedAnomalyType !== "Tous"?
                      toFilter.data.dealt === selectedAnomalyType : toFilter.data.dealt !== "")
                      && toFilter.data.creator === item))
                }

              }}>{item}</DropdownItem>
            })}
          </DropdownMenu>
        </Dropdown>
         <Dropdown text={selectedAnomalyType}
                   icon='filter'
                   labeled
                   button
                   placeholder='Sélectionner type anomalie'
                   selection
                   className='icon'>

           <DropdownMenu>
             {arrayUniqueByKeyAnomalyType.map((item, index) => {
               return <DropdownItem onClick={() => {
                 setToShow(anomalyData)
                 setAnomalyType(anomalyType => [...anomalyType, "Tous"])
                 setSelectedAnomalyType(item)
                 if(item === "Tous"){
                   setToShow(toShow => toShow.filter((toFilter) => selectedCleaner !== "" && selectedCleaner !== "Tous" ?
                       toFilter.data.creator === selectedCleaner: toFilter.data.creator!== ""))
                 }
                 else{
                   setToShow(toShow => toShow.filter((toFilter) => toFilter.data.dealt === (item.toString() === "Traitées")
                       && (selectedCleaner !== "" && selectedCleaner !== "Tous"? toFilter.data.creator === selectedCleaner: toFilter.data.creator !== "")))
                 }
               }}>{item}</DropdownItem>
             })}
           </DropdownMenu>
         </Dropdown>
       </div>

    <Table className="ui celled table" color={"olive"} >
      <TableHeader>
        <TableRow class="table-success bg-success">
          <TableHeaderCell>Date de création</TableHeaderCell>
          <TableHeaderCell>Nom</TableHeaderCell>
          <TableHeaderCell>Description</TableHeaderCell>
          <TableHeaderCell>Statut</TableHeaderCell>
          <TableHeaderCell>Détail dernier statut</TableHeaderCell>
          <TableHeaderCell>Détails</TableHeaderCell>
        </TableRow>
      </TableHeader>
      <TableBody>
        {toShow.length > 0 ? toShow.map((item) => {
          const stepKeys = Object.values(item.data.steps)
          const lastStepKey = stepKeys[stepKeys.length - 1];
          return (
              <TableRow className={item.data["dealt"] === false ? "negative" : "positive"}  >
                <TableCell data-label="createdDate">
                  {formatDate(item.data["date"]) ?? "Pas de date"}
                </TableCell>
                <TableCell data-label="name">{item.data["creator"] ?? "Pas de nom"}</TableCell>
                <TableCell data-label="comment">
                  {item.data["description"]}
                </TableCell>
                <TableCell data-label="status">
                  {item.data["dealt"] === true ? <img src={"https://cdn-icons-png.flaticon.com/512/6572/6572262.png"} width={32}/>  : <img src={"https://cdn-icons-png.flaticon.com/512/1029/1029925.png"}  width={32}/> }
                </TableCell>
                <TableCell data-label="detailStatus">
                  {lastStepKey.status ?? "Pas de status"}
                </TableCell>
                <TableCell data-label="details">
                  <LinkContainer
                      to={`/anomaly/${item.data["creator"]}/${item.data["date"]}/${item["key"]}`}
                  >
                    <button className="ui teal button">Voir plus</button>
                  </LinkContainer>
                </TableCell>
              </TableRow>
          );
        }): (<div></div>)}
      </TableBody>
    </Table>
        </>
  );
};

export default AnomalyArray;
