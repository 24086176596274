import React, { useState } from "react";
import { Modal, Button, Input } from "semantic-ui-react";
import axios from "axios";

const ModalUpdateBalance = ({ userId, open, onClose, header }) => {
  const [amount, setAmount] = useState("");
  const [successUpdateBalance, setsuccessUpdateBalance] = useState(false);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleUpdateBalance = async () => {
    try {
      const dashboardKeycloakToken = localStorage.getItem(
        "DashboardKeycloakToken"
      );
      const response = await axios.post(
        "https://europe-west1-bernypro-d3577.cloudfunctions.net/updateUserBalance",
        {
          amount: amount,
          customer: userId,
          type: "front",
        },
        {
          headers: {
            Accept: "*/*",
            Authorization: `Bearer ${dashboardKeycloakToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Balance update");
        setsuccessUpdateBalance(true);
      } else {
        console.error("Failed to  update balance");
      }
    } catch (error) {
      console.error("Error update balance", error);
    }
  };

  return (
    <Modal className="modal-container" open={open} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Montant à ajouter (en Cts)
        <Input
          value={amount}
          onChange={handleAmountChange}
          placeholder="Montant"
        />
      </Modal.Content>
      <Modal.Actions>
        {successUpdateBalance ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ fontWeight: "bold" }}
              role="img"
              aria-label="Success"
            >
              ✅ Cagnotte crédité ✅
            </span>
          </div>
        ) : (
          <Button color="green" onClick={handleUpdateBalance}>
            Envoyer
          </Button>
        )}
        <Button color="red" onClick={onClose}>
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalUpdateBalance;
