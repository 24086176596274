import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { MdRestaurant } from "react-icons/all";
import { Divider, Image, Step } from "semantic-ui-react";
const SaladGenerator = () => {
  const [smShow, setSmShow] = useState(false);
  return (
    <>
      <Button
        onClick={() => {
          setSmShow(true);
        }}
        style={{
          backgroundColor: "#198754",
          margin: "15px",
          borderStyle: "hidden",
        }}
      >
        <MdRestaurant />
      </Button>
      <Modal
        size="lg"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Proposition de salade Ferré
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Stepper />
        </Modal.Body>
      </Modal>
    </>
  );
};
const herbes = ["Ciboulette", "Persil", "Coriande"];
const sauce = ["Huile d'olive", "Balsamique", "Vinaigrette", "Miel moutarde"];
const base = [
  "Mesclin",
  "Roquette",
  "Quinoa",
  "Feuille de chêne",
  "Lentille",
  "Pâtes",
  "Riz",
];
const proteine = ["Poulet", "Saumon", "Jambon rouge", "Thon"];
const legumes = [
  "Haricots verts",
  "Tomates confites",
  "Poivrons",
  "Betterave",
  "Avocat",
  "Champignons",
  "Concombre",
  "Tomates cerises",
  "Carottes rapées",
  "Coeur artichauds",
];
const other = [
  "Noix",
  "Chèvre",
  "Fêta",
  "Fromage de brebis",
  "Raisins sec",
  "Mozzarella",
  "Emmental",
  "Pistaches",
  "Oeuf",
];
const Stepper = () => {
  return (
    <div style={{ marginLeft: "30%", marginRight: "30%" }}>
      <Step.Group vertical widths={1}>
        <Step completed>
          <Image
            src={"https://cdn-icons-png.flaticon.com/512/135/135715.png"}
            size="tiny"
            circular
          />
          <Step.Content>
            <Step.Title>Base salade</Step.Title>
            <Step.Description>
              {Math.floor(Math.random() * 3) > 1
                ? `${base[Math.floor(Math.random() * base.length)]} - ${
                    base[Math.floor(Math.random() * base.length)]
                  }`
                : base[Math.floor(Math.random() * base.length)]}
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
      <Divider />
      <Step.Group vertical widths={1}>
        <Step completed>
          <Image
            src={"https://cdn-icons-png.flaticon.com/512/2843/2843630.png"}
            size="tiny"
            circular
          />
          <Step.Content>
            <Step.Title>Protéine</Step.Title>
            <Step.Description>
              {proteine[Math.floor(Math.random() * proteine.length)]}
            </Step.Description>
          </Step.Content>
        </Step>

        <Step completed>
          <Image
            src={"https://cdn-icons-png.flaticon.com/512/2921/2921855.png"}
            size="tiny"
            circular
          />
          <Step.Content>
            <Step.Title>Crudités</Step.Title>
            <Step.Description>
              {legumes[Math.floor(Math.random() * legumes.length)]}
            </Step.Description>
          </Step.Content>
        </Step>

        <Step completed>
          <Image
            src={"https://cdn-icons-png.flaticon.com/512/1355/1355982.png"}
            size="tiny"
            circular
          />
          <Step.Content>
            <Step.Title>Ingrédient supplémentaire</Step.Title>
            <Step.Description>
              {other[Math.floor(Math.random() * other.length)]}
            </Step.Description>
          </Step.Content>
        </Step>

        <Step completed>
          <Image
            src={"https://cdn-icons-png.flaticon.com/512/2458/2458293.png"}
            size="tiny"
            circular
            spaced={"right"}
          />
          <Step.Content>
            <Step.Title>Ingrédient supplémentaire 2 </Step.Title>
            <Step.Description>
              {other[Math.floor(Math.random() * other.length)]}
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>

      <Divider />
      <Step.Group vertical widths={1}>
        <Step completed>
          <Image
            src={"https://cdn-icons-png.flaticon.com/512/3063/3063422.png"}
            size="tiny"
            circular
            spaced={"right"}
          />
          <Step.Content>
            <Step.Title>Assaisonement</Step.Title>
            <Step.Description>
              {sauce[Math.floor(Math.random() * sauce.length)]}
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>

      <Divider />
      <Step.Group vertical widths={1}>
        <Step completed>
          <Image
            src={"https://cdn-icons-png.flaticon.com/512/293/293921.png"}
            size="tiny"
            circular
            spaced={"right"}
          />
          <Step.Content>
            <Step.Title>Herbes</Step.Title>
            <Step.Description>
              {herbes[Math.floor(Math.random() * herbes.length)]}
            </Step.Description>
          </Step.Content>
        </Step>
      </Step.Group>
    </div>
  );
};
export { SaladGenerator };
