import {Modal} from "react-bootstrap";
import {
    Button, ButtonGroup, ButtonOr, Card, CardContent, CardDescription, CardHeader, CardMeta, Container,
    Dimmer, Form, FormField, FormInput, Grid, GridColumn, GridRow, Icon,
    Image,
    Loader,
    Segment,
    Table, TableBody, TableCell, TableFooter,
    TableHeader,
    TableHeaderCell,
    TableRow
} from "semantic-ui-react";
import React, {useEffect, useState} from "react";
import axios from "axios";
import {initializeApp} from "firebase/app";
import {firebaseConfig} from "../appPro/utils";
import {initializeAppCheck, ReCaptchaV3Provider} from "firebase/app-check";
import {getAuth, signInAnonymously} from "firebase/auth";
import {format} from "date-fns";
import {useMutation} from "@apollo/client";
import {ADD_SALE} from "./Query";
import { Player } from '@lottiefiles/react-lottie-player';

const IAModal = ({brand, shop, date}) => {
    const [selectedFile, setSelectedFile] = useState(null);
    //MODAL
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [showLoader, setShowLoader] = useState(false);
    const [columnNumber, setColumnNumber] = useState(0)
    const [rowsNumber, setRowsNumber] = useState(0)
    const data = new FormData();
    const [result, setResult] = useState([]);
    let checkedResults = []
    const possibleCategories = [
        { key: "boucherie", value: "Boucherie", text: "Boucherie" },
        { key: "poissonnerie", value: "Poissonnerie", text: "Poissonnerie" },
        { key: "traiteur", value: "Traiteur", text: "Traiteur" },
        { key: "fromage", value: "Fromage", text: "Fromage" },
        {
            key: "boulangerie",
            value: "Boulangerie-Patisserie",
            text: "Boulangerie-Patisserie",
        },
        { key: "charcuterie", value: "Charcuterie", text: "Charcuterie" },
        { key: "trad", value: "trad", text: "Rayon traditionnel" },
        { key: "sansdetails", value: "Sans détails", text: "Sans détails" },
    ];

    const app = initializeApp(firebaseConfig);
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
            "6Led7IQpAAAAACyLa4k7hh0YivDqRBCMwvaFhzDI" // Replace with your ReCaptcha Site Key
        ),
        isTokenAutoRefreshEnabled: true,
    });
    const auth = getAuth();

    useEffect(() => {
        signInAnonymously(auth)
            .then(() => {
            })}, [])

    const onFileChange = (event) => {
        setSelectedFile(event.target.files[0]);
    };

    function isNumeric(n) {
        return !isNaN(parseFloat(n)) && isFinite(n);
    }


    const onFileUpload = () => {
        try {
            data.append(
                "data",
                selectedFile
            );
            console.log(selectedFile)
            handleShow();
            axios.post("https://europe-west1-bernypro-d3577.cloudfunctions.net/addSales", data, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            }).then(r => {
                if (r.status === 200) {
                    console.log(r.data)
                    setColumnNumber(r.data.columns)
                    setRowsNumber(r.data.rows)
                    setResult(r.data.result)
                } else {
                    setResult(["error"])
                }
            }).catch((e) => {
                console.log(e)
                setResult(["error"])
            })
        }
        catch (e) {
            setResult(["error"])
        }
    };

    const updateFieldChanged = (index) => e => {
        let newArr = [...result];
        newArr[index].label = e.target.value
        setResult(newArr);
        console.log(result)
    }

    const checkCategory = (value, nextValue) => {
        console.log(result)
        console.log(value, nextValue)
        const regex = RegExp(value, 'i');
        const filtered = possibleCategories.filter((val) => regex.test(val.value))
        if(filtered.length > 0 ){
            if(!checkedResults.find(e => e.category === filtered[0].text)){
                checkedResults.push({"category": filtered[0].text, "value": Number(nextValue), "check": true})
            }
            return <p>Catégorie: {filtered[0].text} <Icon name={"check"} color={"green"}/></p>
        }
        else{
            return <p>Catégorie inconnue  <Icon name={"warning"} color={"red"}/></p>
        }
    }

    const handleFormSubmit = (e) => {
        e.preventDefault();
        const formattedDate = format(date, "yyyy-MM-dd'T'HH:mm:ss.SSSxxx");

        checkedResults.forEach((item, index) => {
            addSale({
                variables: {
                    amount: item.value,
                    Shop: shop.key,
                    week: formattedDate,
                    section: item.category,
                },
            })
                .then(() => {
                    if(index === 0){
                        setShowLoader(true)
                    }
                })
                .catch((error) => {
                    console.error("Error adding sale:", error);
                });
        })
        setShowLoader(false);
    };

    const [addSale, { loading: mutationLoading, error: mutationError }] =
        useMutation(ADD_SALE);

    const Arrayheader = () => {
        return <TableHeader style={{textAlign: "center", backgroundColor: "grey"}}>
            <TableRow>
                { result.slice(0, columnNumber).map((item) => {
                     return <TableHeaderCell key={item.label}>
                         {item.label}
                     </TableHeaderCell>
                })}
            </TableRow>
        </TableHeader>

    }

    const ArrayRender = (rowInput) => {
        const row = rowInput.row
        let maxIndex = (columnNumber * (row + 1))
         try{
             return result.slice(columnNumber * row, maxIndex).sort((a, b) => Number(a.x1) - Number(b.x1)).map((p, index, array) => {
                if (index !== 0) {
                    return <TableCell verticalAlign={"middle"} style={{textAlign: "center"}}>
                        <Grid>
                            <GridColumn/>
                            <GridColumn>
                                <FormField>
                                    {
                                        isNumeric(p.label) ? <FormInput action={{
                                            color: 'teal',
                                            icon: 'cart',
                                        }}
                                                                        onChange={updateFieldChanged(result[(columnNumber * row) + index].label === p.label ? ((columnNumber * row) + index) : result[(columnNumber * row) + index +1].label === p.label ? (columnNumber * row) + index + 1 : (columnNumber * row) + index -1)}
                                                                        type={"number"} size={"large"} value={p.label}
                                                                        style={{
                                                                            textAlign: "center",
                                                                            verticalAlign: 'middle',
                                                                            minWidth: '100px'
                                                                        }}/> : <p>{p.label}</p>

                                    }
                                </FormField>
                            </GridColumn>
                            <GridColumn/>
                        </Grid>
                    </TableCell>
                } else {

                    return <TableCell verticalAlign={"middle"}>
                        <FormField>
                            <FormInput type={"text"} size={"small"} value={p.label}
                                       onChange={updateFieldChanged((columnNumber * row) + index)}/>
                        </FormField>
                        {checkCategory(p.label, array[index + 1].label)}
                    </TableCell>
                }
            })
        }
        catch(e){
            return <TableCell verticalAlign={"middle"}>
                    <Player
                src='/Animation - 1718705683855.json'
                className="player"
                autoplay
            />
                </TableCell>
        }
    }
    return <Grid columns={1}>
        <GridRow/>
        <GridRow>
            <GridColumn>
                <Card fluid color={'orange'}>
                    <CardContent>
                        <CardHeader>Ajout automatique avec l'IA</CardHeader>
                    </CardContent>
                    <CardContent>
                        <CardDescription>
                            <div style={{display: "inline-flex", justifyContent: "space-between"}}>
                                <Button
                                    as="label"
                                    htmlFor="choose-file"
                                    content={selectedFile !== null ? selectedFile.name: "Upload fichier"}
                                    compact={true}
                                    labelPosition="left"
                                    icon="file"
                                    color={shop === "magasin" ? "grey" : "green"}
                                    disabled={shop === "magasin"}
                                />
                                <input type="file" id="choose-file" hidden onInput={onFileChange} accept="image/png"/>

                                {selectedFile !== null ? <Button
                                    content="Valider"
                                    labelPosition="left"
                                    color="green"
                                    floated={"right"}
                                    style={{alignContent: "center"}}
                                    icon="check"
                                    onClick={onFileUpload}
                                ></Button> : <p></p>}
                            </div>
                        </CardDescription>
                    </CardContent>


                    <Modal
                        show={show}
                        onHide={handleClose}
                        size="fullscreen"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Berny IA {brand} {shop.text}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            {
                                showLoader ? <Player
                                    src='/Animation - 1718628161955.json'
                                    className="player"
                                    style={{width: "400px"}}
                                    autoplay
                                    onEvent={event => {
                                        if(event === "complete") {
                                            handleClose()
                                            window.location.reload();
                                        }
                                    }}
                                /> : (result.length === 0 ? <Segment>
                                    <Dimmer active>
                                        <Loader indeterminate>Preparing Files</Loader>
                                    </Dimmer>

                                    <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
                                </Segment> :
                                    (result[0] !== "error" ?<Form> <Table celled  key={"blue"} striped color={"blue"} inverted>
                                        <Arrayheader />
                                        <TableBody>
                                            {
                                                Array.from(Array(rowsNumber).keys()).map((item, index) => {
                                                    return item > 0 ?<TableRow>
                                                        <ArrayRender row={item}/>
                                                    </TableRow>: <></>
                                                })
                                            }

                                        </TableBody>
                                    <TableFooter fullWidth>
                                        <TableRow>
                                            <TableHeaderCell colSpan={columnNumber}>
                                                <Segment basic textAlign={"center"}>
                                                    <ButtonGroup >
                                                        <Button
                                                            color='red'
                                                            key="red"
                                                            icon
                                                            labelPosition='left'
                                                            size='small'
                                                            onClick={() => {
                                                                setShow(false)
                                                            }}
                                                        >
                                                            <Icon name='trash' /> Annuler
                                                        </Button>
                                                        <ButtonOr text='ou' />
                                                        <Button
                                                            color='green'
                                                            key="green"
                                                            icon
                                                            labelPosition='right'
                                                            size='small'
                                                            onClick={handleFormSubmit}
                                                        >
                                                            <Icon name='check' /> Valider
                                                        </Button>
                                                    </ButtonGroup>
                                                </Segment>
                                            </TableHeaderCell>
                                        </TableRow>
                                    </TableFooter>

                                </Table>
                                </Form>
                                : <>
                                            <Player
                                                src='/Animation - 1718705683855.json'
                                                className="player"
                                                autoplay
                                            />
                                        </>))
                            }
                        </Modal.Body>
                    </Modal>
                </Card>
            </GridColumn>
        </GridRow>
    </Grid>

}

export default IAModal;