import React, { useState, useEffect } from "react";
import {
  Button,
  Input,
  Dropdown,
  Form,
  Modal,
  Divider,
  Header,
  List,
} from "semantic-ui-react";
import { INSERT_ITEM_MUTATION } from "./Query";
import { useMutation } from "@apollo/client";
import * as XLSX from "xlsx";

const NewItemsForm = () => {
  const [selectedType, setSelectedType] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [size, setSize] = useState("");
  const [mutationCount, setMutationCount] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isUpload, setIsUpload] = useState(false);
  const [twiceItem, setTwiceItem] = useState([]);

  const handleFileChange = (e) => {
    // Get the selected file from the event
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const sizeOptions = [
    { key: "bm30", value: "BM30", text: "BM30" },
    { key: "bm45", value: "BM45", text: "BM45" },
    { key: "bl30", value: "BL30", text: "BL30" },
    { key: "bl45", value: "BL45", text: "BL45" },
  ];

  useEffect(() => {
    if (selectedType?.startsWith("BM")) {
      setSize("Petit");
    } else if (selectedType?.startsWith("BL")) {
      setSize("Grand");
    }
  }, [selectedType]);

  const extractIdFromURL = (content) => {
    const match = content.match(/https:\/\/berny\.app\/([^/"]+)/);
    return match ? match[1] : "";
  };

  const [insertItemMutation] = useMutation(INSERT_ITEM_MUTATION, {
    onCompleted: () => {
      setMutationCount((prevCount) => prevCount + 1);
    },
  });

  const handleSubmit = async (e) => {
    if (
      selectedFile.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setIsModalOpen(true);
      const reader = new FileReader();

      reader.onload = async (e) => {
        const newTwiceItem = [];
        const data = e.target.result;

        const workbook = XLSX.read(data, { type: "binary" });

        // Assuming you want to read the first sheet (you can change it if needed)
        const sheetName = workbook.SheetNames[0];

        const sheet = workbook.Sheets[sheetName];

        // Convert the sheet to an array of objects
        const sheetData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Extract the first column
        const firstColumn = sheetData.map((row) => row[0]);

        for (const ids of firstColumn) {
          try {
            const extractedId = ids?.split("/");
            const id = extractedId[3];
            const res = await insertItemMutation({
              variables: { id, size, type: selectedType },
            });
            if (res.data.insert_Item.affected_rows === 0) {
              newTwiceItem.push(id);
            }
          } catch (error) {
            console.error(error);
          }
        }
        setTwiceItem(newTwiceItem);
        setIsModalOpen(false);
        setIsUpload(true);
      };

      reader.readAsBinaryString(selectedFile);
    } else {
      setIsModalOpen(true);
      if (selectedFile) {
        e.preventDefault();
        const reader = new FileReader();
        reader.onload = async (e) => {
          const content = e.target.result;
          const lines = content.split(/\r\n|\n/); // Split the content into lines

          const newTwiceItem = [];

          // Extract IDs from each line's first column
          const extractedIds = lines.map((line) => {
            const columns = line.split(","); // Split the line into columns
            if (columns.length > 0) {
              const firstColumn = columns[0].trim();
              return extractIdFromURL(firstColumn);
            }
            return "";
          });

          // Filter out empty IDs (if needed)
          const nonEmptyIds = extractedIds.filter((id) => id !== "");

          // Perform the mutation for each ID
          for (const id of nonEmptyIds) {
            try {
              const res = await insertItemMutation({
                variables: { id, size, type: selectedType },
              });
              if (res.data.insert_Item.affected_rows === 0) {
                newTwiceItem.push(id);
              }
            } catch (error) {
              console.error(error);
            }
          }
          setTwiceItem(newTwiceItem);
          setIsModalOpen(false);
          setIsUpload(true);
        };
        reader.readAsText(selectedFile);
      }
    }
  };

  return (
    <div>
      <Form style={{ width: "400px", padding: "20px" }} onSubmit={handleSubmit}>
        <Form.Field>
          <label>Taille du contenant </label>
          <Dropdown
            placeholder="Taille du contenant"
            selection
            options={sizeOptions}
            value={selectedType}
            onChange={(e, { value }) => setSelectedType(value)}
          />
        </Form.Field>
        <Form.Field>
          <label>Uploader un fichier CSV</label>
          <Input type="file" onChange={handleFileChange} />
        </Form.Field>
        <Button type="submit">Envoyer</Button>
        {isUpload ? (
          <div>
            <Divider horizontal>
              <Header as="h3">Initialisation terminée</Header>
            </Divider>
            {twiceItem.length !== 0 ? (
              <div>
                <Header as="h4">
                  Ces contenants sont déjà en base de données :
                </Header>
                <List>
                  {twiceItem.map((item, i) => (
                    <List.Item key={i}>{item}</List.Item>
                  ))}
                </List>
              </div>
            ) : null}
          </div>
        ) : null}
        <Modal className="modal-container" open={isModalOpen}>
          <Modal.Header></Modal.Header>
          <Modal.Content>
            <div>
              <div className="ui active inverted dimmer">
                <div className="ui large text loader">
                  <h3>Initialisation de contenants...</h3>
                  <p> {`${mutationCount} initialisations déjà réalisées`}</p>
                </div>
              </div>
              <p></p>
            </div>
          </Modal.Content>
        </Modal>
      </Form>
    </div>
  );
};

export default NewItemsForm;
