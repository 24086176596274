import { useApolloClient, useQuery } from "@apollo/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "chart.js/auto";
import gql from "graphql-tag";
import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  FaBox,
  FaCalendarAlt,
  FaCircle,
  FaExclamationTriangle,
  FaFeather,
  FaFingerprint,
  FaFireAlt,
  FaSnowflake,
  FaSpinner,
  FaSquare,
} from "react-icons/fa";
import { Button, Icon, Popup } from "semantic-ui-react";
import { _gqlStringToArray } from "../../logistic/dashboardBac/Tools";

const EndWidgetItemOpen = ({ _package }) => {
  console.log({ _package });
  const textPercentage = Math.round(
    (_package.returned / _package.delivery.toFixed(2)) * 100
  );
  console.log({ textPercentage });

  const percentage = textPercentage > 100 ? 100 : textPercentage;
  let color = "orange";

  if (percentage < 30) color = "red";
  else if (percentage < 75) color = "green";
  else if (percentage >= 95) color = "#72B5FF";

  return (
    <div
      style={{
        background: "#73AD21",
        padding: "15px",
        width: "23%",
        height: "75px",
        borderSize: "1",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "16px",
        fontWeight: "normal",
      }}
    >
      <div
        style={{
          width: "95%",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <div
          style={{
            width: "15%",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          {color === "red" || color === "orange" ? (
            <FaFireAlt size={30} color={color} />
          ) : color === "green" ? (
            <FaFeather size={30} color={color} />
          ) : (
            <FaSnowflake size={30} color={color} />
          )}
        </div>
        Retours:
        <div style={{ width: "25px" }} />
        <div
          style={{
            width: "70px",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <CircularProgressbar
            value={percentage}
            text={`${textPercentage}%`}
            styles={buildStyles({
              textColor: color,
              pathColor: color,
            })}
          />
        </div>
      </div>
    </div>
  );
};

function getDiff(date1, date2) {
  return (date2.getTime() - date1.getTime()) / (1000 * 3600 * 24);
}

const EndWidgetItemClose = ({ diff }) => {
  let color = "red";

  if (diff > 30) color = "red";
  else if (diff > 20) color = "orange";
  else if (diff > 10) color = "green";
  else color = "#72B5FF";

  return (
    <div
      style={{
        background: "#73AD21",
        padding: "15px",
        width: "23%",
        height: "75px",
        borderSize: "1",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "16px",
        fontWeight: "normal",
      }}
    >
      {color === "red" || color === "orange" ? (
        <FaFireAlt size={30} color={color} />
      ) : color === "green" ? (
        <FaSpinner size={30} color={color} />
      ) : (
        <FaSnowflake size={30} color={color} />
      )}
      <div style={{ width: "10px" }} />
      Depuis dernière livraisons: {diff} J
    </div>
  );
};

const EndWidgetItemLimiteClose = ({ diff }) => {
  const color = "red";

  return (
    <div
      style={{
        background: "#73AD21",
        padding: "15px",
        height: "75px",
        borderSize: "1",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: "normal",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <FaExclamationTriangle size={30} color={color} />
        <div style={{ width: "20px" }} />
        {diff} Jours
      </div>
    </div>
  );
};

function IdEllipsisWithTooltip({ id }) {
  const ellipsis = `...${id.slice(-3)}`;

  return (
    <Popup
      trigger={
        <span>
          ID: {id.length > 4 ? "IFCO" : "COLIS"}{" "}
          <span
            style={{
              color: "black",
              fontWeight: "bold",
            }}
          >
            {" "}
            ({id.length > 4 ? ellipsis : id})
          </span>
        </span>
      }
      hoverable
      position="top center"
      size="mini"
    >
      <Popup.Header
        style={{
          color: "black",
        }}
      >
        {id}
      </Popup.Header>
    </Popup>
  );
}

function ContainerItemType({ items }) {
  const query = gql`
    query {
      Item(where: {id: {_in: [${_gqlStringToArray(items)
        .map((item) => `"${item}"`)
        .join(",")}]}}) {
        id
        Format {
          name
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(query);

  if (loading) return <div>Chargement...</div>;
  if (error) return <div>Erreur...</div>;
  else return <div>{data.Item[0]?.Format?.name ?? "Inconnu"}</div>;
}

const Item = ({ _package, status, endWidget, client }) => {
  const query = gql`
    query {
      Container_by_pk(id: "${_package.id}") {
        id
        items
      }
    }
  `;

  const { loading, error, data } = useQuery(query);

  let deleted = false;
  const style = {
    borderRadius: 0,
    opacity: 1,
    padding: "2em",
  };

  return (
    <div
      style={{
        background: "#73AD21",
        padding: "15px",
        width: "100%",
        height: "75px",
        borderSize: "1",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "16px",
        fontWeight: "normal",
      }}
    >
      <div
        style={{
          width: "15%",
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaFingerprint color="blue" />
        <div style={{ width: "8px" }} />
        <IdEllipsisWithTooltip id={_package.id} />
      </div>
      <div
        style={{
          width: "10%",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaBox color="#FFC0CB" />
        <div style={{ width: "8px" }} />
        {loading ? (
          <div>Chargement...</div>
        ) : error ? (
          <div>Erreur!</div>
        ) : (
          <ContainerItemType items={data.Container_by_pk.items} />
        )}
      </div>
      <div
        style={{
          width: "15%",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaCircle color="#FFE0C0" />
        <div style={{ width: "8px" }} />
        ToT consigne: {_package.delivery}
      </div>
      <div
        style={{
          width: "15%",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaSquare color="#AA5566" />
        <div style={{ width: "8px" }} />
        Retours: {_package.returned}
      </div>
      <div
        style={{
          width: "25%",
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaCalendarAlt />
        <div style={{ width: "8px" }} />
        Livraison:
        <div style={{ width: "10px" }} />
        <div
          style={{
            color: status === "fermé" ? "red" : "black",
          }}
        >
          {_package.date !== undefined
            ? _package.date.toLocaleString("fr-FR", {
                weekday: "long",
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : ""}
        </div>
      </div>
      {endWidget}
      {
        <Popup
          trigger={
            <Button
              circular
              icon
              onClick={() => {
                Mutate(_package.id, client).then((el) => {
                  deleted = true;
                });
              }}
            >
              <Icon name="delete" color={deleted ? "green" : "red"} />
            </Button>
          }
          content={"Colis consommé ?"}
          style={style}
          inverted
        />
      }
    </div>
  );
};

const ListContainerByStatus = ({ data, status, title, client }) => {
  const listDefaultValue = 5;
  const [listLen, setListLien] = useState(listDefaultValue);

  return (
    <div
      style={{
        paddingLeft: "50px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        align: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div style={{ fontSize: "15px", fontWeight: "bold", width: "100%" }}>
        Colis {title}
        <div style={{ height: "15px" }} />
        {data.length < 1 ? (
          <div
            style={{
              marginLeft: "10px",
              fontSize: "15px",
              fontWeight: "normal",
              width: "100%",
            }}
          >
            Pas de colis {title}
          </div>
        ) : (
          <div>
            {data.slice(0, listLen).map((_package) => {
              const diff = Math.round(
                getDiff(new Date(_package.date), new Date())
              );

              if (status === "ouvert")
                return (
                  <Item
                    _package={_package}
                    key={_package.id}
                    status={status}
                    endWidget={
                      <EndWidgetItemOpen
                        _package={_package}
                        key={_package.id}
                        status={status}
                      />
                    }
                    client={client}
                  />
                );
              else if (diff < 30) {
                return (
                  <Item
                    _package={_package}
                    key={_package.id}
                    status={status}
                    endWidget={
                      <EndWidgetItemClose
                        _package={_package}
                        key={_package.id}
                        status={status}
                        diff={diff}
                      />
                    }
                    client={client}
                  />
                );
              } else {
                return (
                  <Item
                    _package={_package}
                    key={_package.id}
                    status={status}
                    endWidget={
                      <EndWidgetItemLimiteClose
                        _package={_package}
                        key={_package.id}
                        status={status}
                        diff={diff}
                      />
                    }
                    client={client}
                  />
                );
              }
            })}
            {data.length > listDefaultValue ? (
              <div>
                <div style={{ height: "10px" }} />
                <Button
                  onClick={() => {
                    listLen === listDefaultValue
                      ? setListLien(data.length)
                      : setListLien(listDefaultValue);
                  }}
                >
                  {" "}
                  {listLen === listDefaultValue ? "+" : "-"}{" "}
                </Button>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};
async function Mutate(container, client) {
  const mutation = gql`mutation MyMutation3 {
  update_Container_by_pk(pk_columns: {id: "${container}"}, _set: {status: "consumed"}) {
    id
  }
}`;
  await client.mutate({
    mutation: mutation,
  });
  window.location.reload(false);
}

function sortByDate(array, order) {
  return array.sort(function (a, b) {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    if (order === "desc") {
      return dateA - dateB;
    } else {
      return dateB - dateA;
    }
  });
}

function createCustomList(shopPackageData) {
  const dataOpen = [];
  const dataClose = [];
  const dataConsumed = [];

  for (const it of shopPackageData) {
    const createdData = {
      id: it.container,
      delivery: it.sumdelivery,
      returned: it.sumreturned,
      date: new Date(it.delivery_date),
      items: it.items,
    };
    if (it.status === "consumed") {
      dataConsumed.push(createdData);
    } else {
      if (it.sumreturned > 0) {
        dataOpen.push(createdData);
      } else {
        dataClose.push(createdData);
      }
    }
  }
  return {
    open: sortByDate(dataOpen, "asc"),
    close: sortByDate(dataClose, "desc"),
    consumed: sortByDate(dataConsumed, "desc"),
  };
}

const ShopPackagesCollectionStatus = ({ shopPackageData }) => {
  const client = useApolloClient();

  if (shopPackageData === undefined) {
    return <div>Chargement...</div>;
  }

  const data = createCustomList(shopPackageData.usedContainerByShopGetStatus);

  return (
    <div
      style={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        align: "center",
        justifyContent: "space-between",
      }}
    >
      <ListContainerByStatus
        data={data.open}
        status={"ouvert"}
        title={"ouverts"}
        client={client}
      />
      <div style={{ height: "30px" }} />
      <ListContainerByStatus
        data={data.close}
        status={"fermé"}
        title={"non ouverts"}
        client={client}
      />
      <div style={{ height: "30px" }} />
      <ListContainerByStatus
        data={data.consumed}
        status={"consommés"}
        title={"consommés"}
        client={client}
      />
    </div>
  );
};

export { ShopPackagesCollectionStatus };
