import { useQuery } from "@apollo/client";
import "chart.js/auto";
import gql from "graphql-tag";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import momentTimezone from "moment-timezone";
import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import {
  FaCaretDown,
  FaCaretUp,
  FaFingerprint,
  FaGlobeEurope,
  FaHandsWash,
  FaTruck,
  FaUndo,
} from "react-icons/fa";
import { useKeycloak } from "react-keycloak";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { useParams } from "react-router-dom";
import { Icon as StatIcon, Statistic } from "semantic-ui-react";
import Menu from "../../LeftMenu";
import NavBar from "../../Navbar";
import pin from "../map/pin.svg";

function Table({ id: timestamp, bac }) {
  // reformat the timestamp string from dd-mm-yyyy hh:mm to
  const date = momentTimezone.tz(timestamp, "UTC");

  const start = date.startOf("minute").toISOString();
  const end = date.endOf("minute").toISOString();

  const query = gql`
      query GetReceiptsByDate {
  receipt(where: {receiptDate: {_gte: "${start}", _lte: "${end}"}, transportedIn: {_eq: "${bac}"}}) {
    receiptDate
    transportedIn
    Item {
      id
      size
      arrival_country
      arrival_date
      type
      returns: Delivery_backs_aggregate(where: {date: {_lte: "${end}"}}) {
        aggregate {
          count
        }
      }
      deliveries: Deliveries_aggregate(where: {delivery_date: {_lte: "${end}"}}) {
        aggregate {
          count
        }
      }
      cleanings: Cleanings_aggregate(where: {cleaning_date: {_lte: "${end}"}}) {
        aggregate {
          count
        }
      }
    }
    Warehouse {
      name
			brand
      id
      lat
      long
    }
  }
}

  `;

  const { loading, error, data } = useQuery(query);

  const [displayData, setDisplayData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [EllipsisButton, setEllipsisButton] = useState(
    <>
      <FaCaretDown color="grey" />
      Afficher tout
    </>
  );

  useEffect(() => {
    if (data) {
      const items = data.receipt.map((receipt) => receipt.Item);
      setDisplayData(items.slice(0, showAll ? items.length : 5));
      setEllipsisButton(
        showAll ? (
          <>
            <FaCaretUp color="grey" />
            Afficher moins
          </>
        ) : (
          <>
            <FaCaretDown color="grey" />
            Afficher tout
          </>
        )
      );
    }
  }, [data, showAll]);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const cleaner = data.receipt[0]?.Warehouse;

  if (!cleaner) {
    return (
      <div style={{ textAlign: "center", marginTop: "2em" }}>
        <h3>Aucun résultat pour cette date</h3>
      </div>
    );
  }

  const cleanerMarker = (
    <Marker
      position={[cleaner.lat, cleaner.long]}
      icon={
        new Icon({
          iconUrl: pin,
          iconSize: [75, 102],
          iconAnchor: [24, 82],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        })
      }
    >
      <Popup>
        <div>
          <h3>
            Laveur {cleaner.brand} {cleaner.name}
          </h3>
        </div>
      </Popup>
    </Marker>
  );

  const itemsPerSize = data.receipt
    .map((receipt) => receipt.Item)
    .reduce((acc, item) => {
      if (!acc[`${item.size} ${item.type ?? "degrenne"}`]) {
        acc[`${item.size} ${item.type ?? "degrenne"}`] = 0;
      }
      acc[`${item.size} ${item.type ?? "degrenne"}`]++;
      return acc;
    }, {});

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Statistic.Group
          horizontal
          style={{
            width: "50%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="calendar" color="grey" />
              Le{" "}
              {new Date(timestamp).toLocaleDateString("fr-FR", {
                timeZone: "Europe/Paris",
              })}{" "}
              à{" "}
              {new Date(timestamp).toLocaleTimeString("fr-FR", {
                timeZone: "Europe/Paris",
              })}
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="archive" color="grey" />
              Bac{" "}
              <a href={`/bac/${data.receipt[0].transportedIn}`}>
                {data.receipt[0].transportedIn}
              </a>
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="building" color="grey" />
              {`${data.receipt[0].Warehouse?.brand} ${data.receipt[0].Warehouse?.name}`}
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="box" color="grey" />
              {data.receipt.length}
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              {/*<StatIcon name="expand" color="grey" />*/}
              {Object.keys(itemsPerSize).map((size) => {
                return (
                  <>
                    {`${itemsPerSize[size]} ${size}(s)`}
                    <br />
                  </>
                );
              })}
            </Statistic.Value>
          </Statistic>
        </Statistic.Group>
        <div
          style={{
            width: "50%",
            height: "500px",
            alignItems: "center",
          }}
        >
          <MapContainer
            center={[
              data.receipt[0].Warehouse?.lat,
              data.receipt[0].Warehouse?.long,
            ]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {cleanerMarker}
          </MapContainer>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          fontColor: "grey",
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        {displayData?.map((item) => (
          <Item
            key={item.id}
            id={item.id}
            arrival_country={item.arrival_country}
            arrival_date={item.arrival_date}
            deliveries={item.deliveries.aggregate.count}
            returns={item.returns.aggregate.count}
            cleanings={item.cleanings.aggregate.count}
          />
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={() => {
              setShowAll(!showAll);
            }}
            onKeyPress={() => {
              setShowAll(!showAll);
            }}
          >
            {EllipsisButton}
          </div>
        </div>
      </div>
    </div>
  );
}

const Item = ({
  id,
  arrival_country,
  arrival_date,
  deliveries,
  returns,
  cleanings,
}) => {
  return (
    <div
      style={{
        background: "#73ad21",
        padding: "15px",
        width: "100%",
        height: "75px",
        borderSize: "1",
        backgroundColor: "white",
        // boxShadow: "1px 0.5px 0.5px grey",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        gap: "1em",
        fontSize: "16px",
        fontWeight: "normal",
      }}
    >
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaFingerprint color="blue" />
        <span style={{ fontWeight: "bold" }}>Contenant : </span>
        <a href={`/contenant/${id}`}>{id}</a>
      </div>
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaGlobeEurope color="purple" />
        <div>{arrival_country}</div>
      </div>
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaHandsWash color="darkorange" />
        <div>
          Lavages : <span style={{ fontWeight: "bold" }}>{cleanings}</span>
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaTruck color="darkred" />
        <div>
          Livraisons : <span style={{ fontWeight: "bold" }}>{deliveries}</span>
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaUndo color="green" />
        <div>
          Retours : <span style={{ fontWeight: "bold" }}>{returns}</span>
        </div>
      </div>
    </div>
  );
};

function Receipt({ date }) {
  const params = useParams();
  const timestamp = params.timestamp;
  const bac = params.bac;
  const { initialized } = useKeycloak();
  return initialized ? (
    <Container className="container">
      <div>
        <NavBar />
        <Row>
          <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Menu />
          </Col>
          <Col sm={10}>
            <div>
              <div style={{ marginTop: "100px", marginLeft: "15px" }}>
                <h4 style={{ margin: "10px", textAlign: "center" }}>
                  <Table id={timestamp} bac={bac} />
                </h4>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  ) : null;
}

export default Receipt;
