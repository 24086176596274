import React from "react";
import { Bar } from "react-chartjs-2";
import graphql2chartjs from "graphql2chartjs";
import gql from "graphql-tag";
import NavBar from "../../Navbar";
import { useQuery } from "@apollo/client";
import {
	convertDateToUTC,
	getWeekNumberFromDate,
	convertNumberBold,
} from "../Tools";

const da = new Date();
da.setDate(1);
da.setHours(1);
// const d = convertDateToUTC(da);

function options(view) {
	return {
		scales: {
			x: {
				ticks: {
					font: {
						size: 13,
						family: "Arial",
					},
					callback: function (index) {
						const arr = this.getLabelForValue(index).split("-");
						return view === "years"
							? `${arr[0]}-${convertNumberBold(arr[1])}`
							: this.getLabelForValue(index);
					},
				},
			},
		},
		plugins: {
			legend: {
				display: true,
			},
			datalabels: {
				display: true,
				color: "black",
				align: "end",
				anchor: "end",
				labels: {
					title: {
						font: {
							weight: "bold",
						},
					},
					value: {
						color: "white",
					},
				},
				formatter: function (value) {
					return parseFloat(value).toFixed(0);
				},
			},
		},
	};
}
// Chart component
const Chart = ({ date, reason }) => {
	let query;
	if (reason === "Mois") {
		const da = new Date();
		da.setDate(1);
		const d = convertDateToUTC(da);
		query = gql`
  query {
    Retours: delivery_back_by_day(where: {is_unsold: {_eq: false}, date: {_gte: "${
			d.split("T")[0]
		}"}}) {
    label: date
    data: count
    }
    Invendus: delivery_back_by_day(where: {is_unsold: {_eq: true}, date: {_gte: "${
			d.split("T")[0]
		}"}}) {
    label: date
    data: count
    }
  }
`;
	} else {
		query = gql`
  query {
    Retours: delivery_back_by_day(
        where: {
            is_unsold: {_eq: false},
            _and: [
                {date: {_gte: "${convertDateToUTC(date[0]).split("T")[0]}"}},
                {date: {_lte: "${convertDateToUTC(date[1]).split("T")[0]}"}}
            ]}) {
    label: date
    data: count
    }
    Invendus: delivery_back_by_day(
        where: {
            is_unsold: {_eq: true},
            _and: [
                {date: {_gte: "${convertDateToUTC(date[0]).split("T")[0]}"}},
                {date: {_lte: "${convertDateToUTC(date[1]).split("T")[0]}"}}
            ]}) {
    label: date
    data: count
    }
  }
`;
	}
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	// create graphql2chartjs instance
	const g2c = new graphql2chartjs();
	const r = Math.floor(Math.random() * 255);
	const g = Math.floor(Math.random() * 255);
	const b = Math.floor(Math.random() * 255);
	const rbg = `rgb(${r},${g},${b}, 1)`;
	const r1 = Math.floor(Math.random() * 255);
	const g1 = Math.floor(Math.random() * 255);
	const b1 = Math.floor(Math.random() * 255);
	const rbg1 = `rgb(${r1},${g1},${b1}, 1)`;
	g2c.add(data, (dataSetName, dataPoint) => {
		if (dataSetName === "Retours") {
			return {
				...dataPoint,
				chartType: "bar",
				backgroundColor: rbg,
			};
		}
		return {
			...dataPoint,
			chartType: "bar",
			backgroundColor: rbg1,
		};
	});
	return <Bar data={g2c.data} options={options("selected")} />;
};

const GetReturnYearData = () => {
	const queryYear = gql`
    query {
      Retours: delivery_back_by_day(where: { is_unsold: { _eq: false } }) {
        label: date
        data: count
      }
      Invendus: delivery_back_by_day(where: { is_unsold: { _eq: true } }) {
        label: date
        data: count
      }
    }
  `;

	const { loading, error, data } = useQuery(queryYear);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const lab = [];
	const retours = [];
	const invendus = [];
	data.Retours.forEach((e) => {
		lab.push(e.label);
	});
	data.Invendus.forEach((e) => {
		lab.push(e.label);
	});
	lab.sort();
	const labls = lab.filter((value, index) => lab.indexOf(value) === index);

	let lastDate = new Date(lab[0]);
	let weekNb = getWeekNumberFromDate(lastDate) - 1;
	const weekArr = [];

	let returnTmp = [];
	let unsoldTmp = [];
	labls.forEach((el) => {
		const currentDate = new Date(el);
		const weekNbel = getWeekNumberFromDate(currentDate);
		if (weekNbel !== weekNb) {
			weekArr.push(`${lastDate.getFullYear()}-${weekNb}`);
			retours.push(returnTmp.reduce((partialSum, a) => partialSum + a, 0));
			invendus.push(unsoldTmp.reduce((partialSum, a) => partialSum + a, 0));

			weekNb = weekNbel;
			returnTmp = [];
			unsoldTmp = [];
		}
		if (data.Retours.find((element) => element.label === el) !== undefined) {
			returnTmp.push(data.Retours.find((element) => element.label === el).data);
		} else {
			returnTmp.push(0);
		}
		if (data.Invendus.find((element) => element.label === el) !== undefined) {
			unsoldTmp.push(
				data.Invendus.find((element) => element.label === el).data,
			);
		} else {
			unsoldTmp.push(0);
		}
		lastDate = currentDate;
	});
	weekArr.push(`${lastDate.getFullYear()}-${weekNb}`);
	retours.push(returnTmp.reduce((partialSum, a) => partialSum + a, 0));
	invendus.push(unsoldTmp.reduce((partialSum, a) => partialSum + a, 0));
	return {
		weekArr: weekArr,
		retours: retours,
		invendus: invendus,
	};
};

const ChartYear = () => {
	const { weekArr, retours, invendus } = GetReturnYearData();
	const r = Math.floor(Math.random() * 255);
	const g = Math.floor(Math.random() * 255);
	const b = Math.floor(Math.random() * 255);
	const rbg = `rgb(${r},${g},${b}, 1)`;
	const r1 = Math.floor(Math.random() * 255);
	const g1 = Math.floor(Math.random() * 255);
	const b1 = Math.floor(Math.random() * 255);
	const rbg1 = `rgb(${r1},${g1},${b1}, 1)`;
	const dta = {
		labels: weekArr,
		datasets: [
			{
				label: "Retours",
				data: retours,
				backgroundColor: rbg,
			},
			{
				label: "Invendus",
				data: invendus,
				backgroundColor: rbg1,
			},
		],
	};
	return <Bar data={dta} options={options("years")} />;
};

const RetoursConsignes = ({ isAnnualView, date }) => {
	return (
		<div>
			<NavBar />
			<div style={{ margin: "10px", paddingTop: "65px" }}>
				<div key="bar">
					<div style={{ marginBottom: "20px" }} id="bar">
						<div className="bar">
							<h5>Retours</h5>
							{isAnnualView === "Annee" ? (
								<ChartYear />
							) : (
								<Chart date={date} reason={isAnnualView} />
							)}
						</div>
					</div>
					<hr />
				</div>
			</div>
		</div>
	);
};

export { RetoursConsignes };
