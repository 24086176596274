import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import dotenv from "dotenv";
import Keycloak from "keycloak-js";
import moment from "moment";
import React, {useCallback, useEffect} from "react";
import ReactDOM from "react-dom";

import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import App from "./App";
import ProtectedRoute from "./ProtectedRoute";
import { Map as MapComponent, ShippingClose } from "./conponents";
import CleaningsHistoryPage from "./conponents/cleaningsHistory/CleaningsHistoryPage";
import CodeGenerator from "./conponents/codeGenerator/Generator";
import { SelectShopComponent } from "./conponents/generic/SelectShopComponent";
import { TableLastDeliveryBacks } from "./conponents/lastDeliveryBacks/TableLastDeliveryBacks";
import { Containers } from "./conponents/logistic/Containers";
import Receipt from "./conponents/logistic/Receipt";
import { LivraisonsPassees } from "./conponents/logistic/Shipped";
import { StepCleaningAndDelivery } from "./conponents/logistic/StepCleaningAndDelivery";
import { OperationPage } from "./conponents/operation/OperationPage";
import { OperationStatePackage } from "./conponents/operation/dashboardPackage/OperationStatePackage";
import DeliveryDate from "./conponents/receiptsHistory/DeliveryDate";
import ReceiptDate from "./conponents/receiptsHistory/ReceiptDate";
import ReceiptsHistoryPage from "./conponents/receiptsHistory/ReceiptsHistoryPage";
import { TableauRemboursement } from "./conponents/refunds/TableRefunds";
import BatchCleaningDate from "./conponents/searchedContainer/BatchCleaningDate";
import { CollectContainerInfos } from "./conponents/searchedContainer/CollectContainerInfos";
import { ContainerInfos } from "./conponents/searchedContainer/ContainerInfos";
import Delivery2 from "./conponents/searchedContainer/Delivery";
import Receipt2 from "./conponents/searchedContainer/Receipt";
import { Item } from "./conponents/searchedItem/Item";
import { Supply } from "./conponents/supply/Supply";
import WeeklySales from "./conponents/weeklySales/weeklySales";
import NewItems from "./conponents/newItems/NewItems";
import ManageUsers from "./conponents/manageUsers/manageUsers";
import UserInfos from "./conponents/manageUsers/UserInfo";
import addShop from "./conponents/addShop/addShop";
import anomaly from "./conponents/appPro/manageAnomaly/ManageAnomaly";
import anomalyDetails from "./conponents/appPro/manageAnomaly/AnomalyDetails";
import FilesCleaner from "./conponents/appPro/filesCleaner/filesCleaner"
import DefaultFolder from "./conponents/appPro/defaultsFolder/defaultsFolder"
import "./index.css";
import {GoogleReCaptchaProvider, useGoogleReCaptcha} from "react-google-recaptcha-v3";
import DefaultsFolder from "./conponents/appPro/defaultsFolder/defaultsFolder";
import DefaultByCategoryContent from "./conponents/appPro/defaultsFolder/defaultModalContent";
import { KeycloakProvider } from "react-keycloak";
import ScratchedItem from "./scratchedItem/ScratchedItem";
const keycloak = Keycloak("/keycloak.json");
moment.locale("fr");
dotenv.config();

export const client = new ApolloClient({
  uri: process.env.REACT_APP_HASURA_URI,
  cache: new InMemoryCache(),
  headers: {
    "x-hasura-admin-secret": process.env.REACT_APP_HASURA_SECRET,
  },
});



ReactDOM.render(
  <React.StrictMode>
    <Router>
      <KeycloakProvider
        keycloak={keycloak}
        initConfig={{
          onLoad: "login-required",
          promiseType: "native",
        }}
      >
        <ApolloProvider client={client}>
          <GoogleReCaptchaProvider
              reCaptchaKey="6Led7IQpAAAAACyLa4k7hh0YivDqRBCMwvaFhzDI"
          >
          <Switch>
            <Route exact path="/" component={App} />
            <ProtectedRoute
              roles={["Team"]}
              path="/carte"
              component={MapComponent}
            />
            <ProtectedRoute
                roles={["Team"]}
                path="/contenantsPolis"
                component={ScratchedItem}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/retours"
              component={TableLastDeliveryBacks}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/operation/shop/:id"
              component={OperationStatePackage}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/contenant/:id"
              component={Item}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/colis/:id"
              component={ContainerInfos}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/bac/:id"
              component={CollectContainerInfos}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/livraisonProche"
              component={ShippingClose}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/bacDashboard"
              component={SelectShopComponent}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/livraisonPassees"
              component={LivraisonsPassees}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/remboursements"
              component={TableauRemboursement}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/stockFournitures"
              component={Supply}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/containers"
              component={Containers}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/receipt"
              component={Receipt}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/operation"
              component={OperationPage}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/ecartLavageLivraison"
              component={StepCleaningAndDelivery}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/dateLavage/:timestamp"
              component={BatchCleaningDate}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/lavages"
              component={CleaningsHistoryPage}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/transports"
              component={ReceiptsHistoryPage}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/weekly-sales"
              component={WeeklySales}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/manage_users/:id"
              component={UserInfos}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/manage_users"
              component={ManageUsers}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/new_items"
              component={NewItems}
            />
            {/* PATH FOR ADDING SHOP FORM */}
            <ProtectedRoute
              roles={["Team"]}
              path="/add-shop"
              component={addShop}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/anomaly/:creator/:child/:id"
              component={anomalyDetails}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/anomaly"
              component={anomaly}
            />
            <ProtectedRoute
                roles={["Team"]}
                path="/fileCleaner"
                component={FilesCleaner}
            />
            <ProtectedRoute
                roles={["Team"]}
                path="/defaults"
                component={DefaultsFolder}
            />
            <ProtectedRoute
                roles={["Team"]}
                path="/detailsCategory/:category"
                component={DefaultByCategoryContent}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/receptions/:timestamp/:bac"
              component={Receipt2}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/reception/:timestamp"
              component={ReceiptDate}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/livraisons/:timestamp/:bac"
              component={Delivery2}
            />
            <ProtectedRoute
              roles={["Team"]}
              path="/livraison/:timestamp"
              component={DeliveryDate}
            />
            <ProtectedRoute
              path="/qrcodes"
              roles={["Graveur", "Team"]}
              component={CodeGenerator}
            />
          </Switch>
          </GoogleReCaptchaProvider>
        </ApolloProvider>
      </KeycloakProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);
