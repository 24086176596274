import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import LeftMenu from "../../LeftMenu";
import AddShopForm from "./AddShopForm";
import NavBar from "../../Navbar";

function AddShop() {
  const { initialized } = useKeycloak();

  return initialized ? (
    <Container className="container">
      <NavBar />
      <Row>
        <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <LeftMenu />
        </Col>
        <Col xs={12} style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}>
          <h2>Ajout magasin</h2>
          <AddShopForm />
        </Col>
      </Row>
    </Container>
  ) : null;
}

export default AddShop;
