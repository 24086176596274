import React, {useEffect, useState} from "react";
import { useQuery } from "@apollo/client";
import {Col, Container, Modal, Row} from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import LeftMenu from "../../LeftMenu";
import NavBar from "../../Navbar";
import WeeklySalesForm from "./WeeklySalesForm";
import { GET_SHOPS, GET_SALES } from "./Query";
import ListSales from "./ListSales";


const WeeklySales = () => {
  const { initialized } = useKeycloak();
  const [refreshData, setRefreshData] = useState(false);


  const {
    loading: shopLoading,
    error: shopError,
    data: shopData,
  } = useQuery(GET_SHOPS, {
    suspend: true, // Enable Suspense for this query
  });

  const {
    loading: salesLoading,
    error: salesError,
    data: salesData,
    refetch: refetchSalesData,
  } = useQuery(GET_SALES, {
    suspend: true, // Enable Suspense for this query
  });

  const handleListSalesDataRefresh = () => {
    // Called when the ListSales component has fetched the latest data
    if (salesLoading) {
      return;
    }
    refetchSalesData();
    setRefreshData(false);
  };

  return initialized ? (
    <Container className="container">
      <NavBar />
      <Row>
        <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <LeftMenu />
        </Col>
        <Col>
          <Row>
        <Col
          xs={13}
          xl={13}
          style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
        >
          <h2>Ventes Magasins</h2>
          <WeeklySalesForm
            shopOptions={shopData?.Shop?.map((shop) => ({
              key: shop.id,
              value: shop.id,
              text: shop.name,
              brand: shop.brand,
            }))}
            brandOptions={Array.from(
              new Set(
                shopData?.Shop?.map((shop) => shop.brand) // Create a set of unique brands
              )
            ).map((brand) => ({
              key: brand,
              value: brand,
              text: brand,
            }))}
            onFormSubmitSuccess={handleListSalesDataRefresh}
          />

          </Col>
          </Row>
          <h2>Liste des ventes</h2>
          {salesData && (
              <ListSales
                  data={salesData?.Sales || []}
                  onRefresh={handleListSalesDataRefresh}
              />
          )}
        </Col>
      </Row>
    </Container>
  ) : null;
};

export default WeeklySales;
