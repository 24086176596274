import { gql } from "@apollo/client";

// QUERY //
export const GET_LOANS_BY_USER_ID = gql`
  query GetLoans($id_user: String!) {
    loans(where: { id_user: { _eq: $id_user } }) {
      id
      date_loan
      date_to_return
      is_returned
      item_id
      notified
      payed
      price
      shop_id
    }
  }
`;

export const GET_SHOP_BY_ID = gql`
  query GetShopById($id: uuid!) {
    Shop_by_pk(id: $id) {
      id
      name
      brand
    }
  }
`;

export const SHOP_QUERY = gql`
  query GetShops {
    Shop(where: { app_compatible: { _eq: true } }) {
      id
      name
      brand
    }
  }
`;
// QUERY //

// MUTATION //
export const UPDATE_LOAN_BY_PK = gql`
  mutation UpdateLoans($itemId: String!, $newDateToReturn: date) {
    update_loans(
      where: { item_id: { _eq: $itemId } }
      _set: { date_to_return: $newDateToReturn }
    ) {
      affected_rows
    }
  }
`;

export const INSERT_DELIVERY_MUTATION = gql`
  mutation InsertDelivery($input: Delivery_back_insert_input!) {
    insert_Delivery_back(objects: [$input]) {
      affected_rows
      returning {
        id
      }
    }
  }
`;

export const UPDATE_LOANS_MUTATION = gql`
  mutation UpdateLoans($id: uuid!, $is_returned: Boolean!, $return_id: uuid!) {
    update_loans_by_pk(
      pk_columns: { id: $id }
      _set: { is_returned: $is_returned, return_id: $return_id }
    ) {
      id
    }
  }
`;
// MUTATION //
