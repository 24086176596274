import React, { useState } from "react";
import { Modal, Button, Dropdown } from "semantic-ui-react";
import {
  UPDATE_LOAN_BY_PK,
  SHOP_QUERY,
  INSERT_DELIVERY_MUTATION,
  UPDATE_LOANS_MUTATION,
} from "./Query";
import { useMutation, useQuery } from "@apollo/client";

const ModalLoans = ({
  loanId,
  id,
  shopId,
  date_to_return,
  open,
  onClose,
  header,
}) => {
  const [updateLoanByPk] = useMutation(UPDATE_LOAN_BY_PK);
  const [successAddDayLoan, setSuccessAddDayLoan] = useState(false);
  const [successDelivery, setSuccessDelivery] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [insertDelivery] = useMutation(INSERT_DELIVERY_MUTATION);
  const [updateLoans] = useMutation(UPDATE_LOANS_MUTATION);

  const { loading, error, data } = useQuery(SHOP_QUERY);

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error: {error.message}</p>;

  const options = data.Shop.map((shop) => ({
    key: shop.id,
    text: `${shop.brand} ${shop.name}`,
    value: shop.id,
  }));

  const handleDropdownChange = (e, { value }) => {
    setSelectedOption(value);
  };

  const add10DaysLoans = async () => {
    try {
      const newDateToReturn = new Date(date_to_return);
      newDateToReturn.setDate(newDateToReturn.getDate() + 10);
      await updateLoanByPk({
        variables: {
          itemId: id,
          newDateToReturn: newDateToReturn,
        },
      });
      setSuccessAddDayLoan(true);
    } catch (error) {
      alert("N'a pas fonctionné");
    }
  };

  const addDeliveryBackLoan = async () => {
    try {
      var isSameShop;
      shopId === selectedOption ? (isSameShop = true) : (isSameShop = false);
      const { data: deliveryData } = await insertDelivery({
        variables: {
          input: {
            actor: "cashier",
            date: new Date().toISOString(),
            is_notified: false,
            is_unsold: false,
            item_id: id,
            same_place_as_delivery: isSameShop,
            shop: selectedOption,
            shop_end: false,
          },
        },
      });
      if (deliveryData.insert_Delivery_back.affected_rows > 0) {
        const insertedId = deliveryData.insert_Delivery_back.returning[0].id;
        const { data: updateLoansData } = await updateLoans({
          variables: {
            id: loanId,
            is_returned: true,
            return_id: insertedId,
          },
        });
        if (updateLoansData.update_loans_by_pk) {
          setSuccessDelivery(true);
        }
      }
    } catch (error) {
      alert("N'a pas fonctionné");
    }
  };

  return (
    <Modal className="modal-container" open={open} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-evenly",
          alignItems: "center",
        }}
      >
        Ajouter 10 jours d'emprunts
        {successAddDayLoan ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ fontWeight: "bold" }}
              role="img"
              aria-label="Success"
            >
              ✅ Modification effectué ✅
            </span>
          </div>
        ) : (
          <Modal.Actions>
            <button
              style={{ margin: "0" }}
              className="ui teal button"
              onClick={add10DaysLoans}
            >
              Ajouter
            </button>
          </Modal.Actions>
        )}
      </Modal.Content>

      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Valider le retour en magasin
        {successDelivery ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ fontWeight: "bold" }}
              role="img"
              aria-label="Success"
            >
              ✅ Modification effectué ✅
            </span>
          </div>
        ) : (
          <>
            <Dropdown
              placeholder="Magasin"
              options={options}
              value={selectedOption}
              onChange={handleDropdownChange}
            />
            <Modal.Actions>
              <button className="ui teal button" onClick={addDeliveryBackLoan}>
                Valider
              </button>
            </Modal.Actions>
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="red" onClick={onClose}>
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalLoans;
