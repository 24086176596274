import { Table } from "semantic-ui-react";
import React, { useEffect, useReducer } from "react";
import _ from "lodash";
import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import NavBar from "../../Navbar";
import { Col, Container, Row } from "react-bootstrap";
import Menu from "../../LeftMenu";
import { useKeycloak } from "react-keycloak";

const TableLastDeliveryBacks = () => {
  const queryshop = gql`
    query test1 {
      Delivery_back(
        limit: 100
        order_by: { date: desc }
        where: { shop_end: { _eq: false } }
      ) {
        Shop {
          brand
          name
        }
        Item {
          arrival_country
          size
          type
        }
        date
        is_unsold
        item_id
        lot
        actor
      }
    }
  `;
  const { loading, data: queryData } = useQuery(queryshop);
  const initialState = {
    column: null,
    data: null,
    direction: null,
  };

  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case "ADD_DATA":
        return {
          ...state,
          data: action.payload.queryData,
        };

      case "CHANGE_SORT":
        if (state.column === action.column) {
          return {
            ...state,
            data: state.data.slice().reverse(),
            direction:
              state.direction === "ascending" ? "descending" : "ascending",
          };
        }
        return {
          column: action.column,
          data: _.sortBy(
            state.data.Delivery_back !== undefined
              ? state.data.Delivery_back
              : state.data,
            [
              (o) => {
                if (action.column === "date") {
                  return o.date || "";
                } else if (action.column === "brand") {
                  return o.brand;
                } else if (action.column === "Item.arrival_country") {
                  return o.Item.arrival_country;
                } else if (action.column === "Item.size") {
                  return o.Item.size;
                } else if (action.column === "is_unsold") {
                  return o.is_unsold;
                } else if (action.column === "item_id") {
                  return o.item_id;
                } else if (action.column === "lot") {
                  return o.lot;
                } else if (action.column === "actor") {
                  return o.actor;
                } else {
                  return o;
                }
              },
            ],
            ["asc", "desc"]
          ),
          direction: "ascending",
        };
      default:
        return {
          ...state,
        };
    }
  }, initialState);

  useEffect(() => {
    dispatch({ type: "ADD_DATA", payload: { queryData } });
  }, [loading, queryData]);

  const { column, data, direction } = state;
  const { initialized } = useKeycloak();
  return initialized ? (
    data !== null ? (
      <Container className="container">
        <NavBar />
        <Row>
          <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Menu />
          </Col>
          <Col
            sm={11}
            xl={10}
            style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
          >
            <Table
              celled
              structured
              color={"blue"}
              inverted
              className={"TableRecap"}
            >
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell
                    sorted={column === "Shop.brand" ? direction : null}
                    onClick={() =>
                      dispatch({
                        type: "CHANGE_SORT",
                        column: "Shop.brand",
                      })
                    }
                    verticalAlign={"middle"}
                  >
                    Magasin
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    verticalAlign={"middle"}
                    sorted={column === "date" ? direction : null}
                    onClick={() =>
                      dispatch({
                        type: "CHANGE_SORT",
                        column: "date",
                      })
                    }
                  >
                    Date retour
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    verticalAlign={"middle"}
                    sorted={column === "is_unsold" ? direction : null}
                    onClick={() =>
                      dispatch({
                        type: "CHANGE_SORT",
                        column: "is_unsold",
                      })
                    }
                  >
                    Type retour
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={
                      column === "Item.arrival_country" ? direction : null
                    }
                    onClick={() =>
                      dispatch({
                        type: "CHANGE_SORT",
                        column: "Item.arrival_country",
                      })
                    }
                    verticalAlign={"middle"}
                  >
                    Type contenant
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "Item.size" ? direction : null}
                    onClick={() =>
                      dispatch({
                        type: "CHANGE_SORT",
                        column: "Item.size",
                      })
                    }
                    verticalAlign={"middle"}
                  >
                    Taille contenant
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "item_id" ? direction : null}
                    onClick={() =>
                      dispatch({
                        type: "CHANGE_SORT",
                        column: "item_id",
                      })
                    }
                    verticalAlign={"middle"}
                  >
                    Nom contenant
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "lot" ? direction : null}
                    onClick={() =>
                      dispatch({
                        type: "CHANGE_SORT",
                        column: "lot",
                      })
                    }
                    verticalAlign={"middle"}
                  >
                    Date de livraison
                  </Table.HeaderCell>
                  <Table.HeaderCell
                    sorted={column === "actor" ? direction : null}
                    onClick={() =>
                      dispatch({
                        type: "CHANGE_SORT",
                        column: "actor",
                      })
                    }
                    verticalAlign={"middle"}
                  >
                    Acteur retour
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data !== undefined && column === null ? (
                  data.Delivery_back.map((el) => Tablebody(el))
                ) : column !== null ? (
                  data.map((el) => Tablebody(el))
                ) : (
                  <Table.Row> Chargement en cours ...</Table.Row>
                )}
              </Table.Body>
            </Table>
          </Col>
        </Row>
      </Container>
    ) : (
      <p>En chargement ....</p>
    )
  ) : null;
};

function Tablebody(el) {
  const dateretour = new Date(el.date);
  const datelivraison = new Date(el.lot);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };
  const optionsDate = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  return (
    <>
      <Table.Row>
        <Table.Cell verticalAlign={"middle"}>
          {el.Shop.brand} - {el.Shop.name}
        </Table.Cell>
        <Table.Cell verticalAlign={"middle"}>
          {dateretour.toLocaleString("fr-FR", options)}
        </Table.Cell>
        <Table.Cell verticalAlign={"middle"}>
          {el.is_unsold !== null
            ? el.is_unsold === false
              ? "Retour"
              : "Invendu"
            : null}
        </Table.Cell>
        <Table.Cell verticalAlign={"middle"}>
          {el.Item.arrival_country !== null ? el.Item.arrival_country : null}
        </Table.Cell>
        <Table.Cell verticalAlign={"middle"}>
          {/* {`${el.Item.size} ${el.Item.type !== "BM45" ? <span style={{ color: "red", fontWeight: "bold" }}>BM45</span> : el.Item.type ?? "degrenne"}`}
           */}
          {el.Item.size} {el.Item.type ?? "degrenne"}
        </Table.Cell>
        <Table.Cell verticalAlign={"middle"}>
          {el.item_id !== null ? el.item_id : null}
        </Table.Cell>
        <Table.Cell verticalAlign={"middle"}>
          {el.lot !== null
            ? datelivraison.toLocaleString("fr-FR", optionsDate)
            : dateretour.getTime() > Date.now() - 300000
            ? "En attente de calcul"
            : "Date inconnue"}
        </Table.Cell>
        <Table.Cell verticalAlign={"middle"}>{el.actor}</Table.Cell>
      </Table.Row>
    </>
  );
}

export { TableLastDeliveryBacks };
