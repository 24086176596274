import React, {useCallback, useEffect, useRef, useState} from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import LeftMenu from "../../../LeftMenu";
import NavBar from "../../../Navbar";
import AnomalyArray from "./AnomalyArray";
import {
    GoogleReCaptcha
} from "react-google-recaptcha-v3"



const ManageAnomaly = (callback, deps) => {
  const { initialized } = useKeycloak();
    const [token, setToken] = useState();
    const [refreshReCaptcha, setRefreshReCaptcha] = useState(false);

    const onVerify = useCallback((token) => {
        setToken(token);
    }, deps);

    const doSomething = () => {
        /* do something like submit a form and then refresh recaptcha */
        setRefreshReCaptcha(r => !r);
    }
  return initialized ? (
    <Container className="container">
      <NavBar />
      <Row>
        <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <LeftMenu />
        </Col>
        <Col
          xs={10}
          style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
        >
            <p>Si pas de données appuyez <button onClick={doSomething}>ici</button></p>

            <GoogleReCaptcha
                onVerify={onVerify}
                refreshReCaptcha={refreshReCaptcha}
            />
          <h2>Liste des anomalies</h2>
            <AnomalyArray token={token}/>
        </Col>
      </Row>

    </Container>
  ) : null;
};


export default ManageAnomaly;
