import "bootstrap/dist/css/bootstrap.min.css";
import "chart.js/auto";
import React from "react";
import { IoFastFood } from "react-icons/all";
import { FaBox, FaBoxOpen, FaCheck, FaLock, FaSpinner } from "react-icons/fa";
import { Popup, Statistic } from "semantic-ui-react";

const BoxInfo = ({ title, number, icon, details }) => {
  const style = {
    borderRadius: 0,
    opacity: 1,
    padding: "2em",
  };
  return details !== undefined ? (
    <>
      <Popup
        trigger={
          <Statistic>
            <Statistic.Value>
              {icon} {number}
            </Statistic.Value>
            <Statistic.Label>{title}</Statistic.Label>
          </Statistic>
        }
        content={`Petits: ${
          title === "Ouverts"
            ? details.nbOpenSmall
            : title === "Consommés"
            ? details.nbConsumedSmall
            : details.nbCloseSmall
        }\nGrands: ${
          title === "Ouverts"
            ? details.nbOpenBig
            : title === "Consommés"
            ? details.nbConsumedBig
            : details.nbCloseBig
        }`}
        style={style}
        inverted
      />
    </>
  ) : (
    <Statistic>
      <Statistic.Value>
        {icon} {number}
      </Statistic.Value>
      <Statistic.Label>{title}</Statistic.Label>
    </Statistic>
  );
};

function _getShopPackageStatus(packageList) {
  const shopPackageStatus = {
    nbClose: 0,
    nbConsumed: 0,
    nbConsumedSmall: 0,
    nbConsumedBig: 0,
    nbOpen: 0,
    nbOpenSmall: 0,
    nbCloseSmall: 0,
    nbOpenBig: 0,
    nbCloseBig: 0,
  };
  packageList.forEach((it) => {
    if (it.status === "consumed") {
      shopPackageStatus.nbConsumed += 1;
      it.sumdelivery === 50
        ? (shopPackageStatus.nbConsumedBig += 1)
        : (shopPackageStatus.nbConsumedSmall += 1);
    } else {
      if (it.sumreturned !== 0) {
        shopPackageStatus.nbOpen += 1;
        it.sumdelivery === 50
          ? (shopPackageStatus.nbOpenBig += 1)
          : (shopPackageStatus.nbOpenSmall += 1);
      } else {
        shopPackageStatus.nbClose += 1;
        it.sumdelivery === 50
          ? (shopPackageStatus.nbCloseBig += 1)
          : (shopPackageStatus.nbCloseSmall += 1);
      }
    }
  });
  return shopPackageStatus;
}

const ShopPackageInfo = ({ shopPackageData }) => {
  if (shopPackageData === undefined) {
    return <div>Loading...</div>;
  }

  const shopPackageStatus = _getShopPackageStatus(
    shopPackageData.usedContainerByShopGetStatus
  );

  return (
    <div
      style={{
        paddingLeft: "40px",
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        align: "center",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <BoxInfo
        title="Nombre total de colis"
        number={shopPackageData.usedContainerByShopGetStatus.length}
        icon={<FaBox color="brown" />}
      />
      <BoxInfo
        title="Ouverts"
        number={shopPackageStatus.nbOpen}
        icon={<FaBoxOpen color="green" />}
        details={shopPackageStatus}
      />
      <BoxInfo
        title="Non ouverts"
        number={shopPackageStatus.nbClose}
        icon={<FaLock color="grey" />}
        details={shopPackageStatus}
      />
      <BoxInfo
        title="Consommés"
        number={shopPackageStatus.nbConsumed}
        icon={<IoFastFood color="blue" />}
        details={shopPackageStatus}
      />
      <BoxInfo
        title="Pourcentage ouvert"
        number={`${
          Math.round(
            (shopPackageStatus.nbOpen /
              shopPackageData.usedContainerByShopGetStatus.length) *
              100 *
              100
          ) / 100
        }%`}
        icon={
          Math.round(
            (shopPackageStatus.nbOpen /
              shopPackageData.usedContainerByShopGetStatus.length) *
              100 *
              100
          ) /
            100 >=
          90 ? (
            <FaCheck color="green" />
          ) : (
            <FaSpinner color="orange" />
          )
        }
      />
    </div>
  );
};

export { ShopPackageInfo };
