import { useParams } from "react-router-dom";
import { useKeycloak } from "react-keycloak";
import { Col, Container, Row } from "react-bootstrap";
import NavBar from "../../Navbar";
import Menu from "../../LeftMenu";
import React from "react";
import {
  FaFingerprint,
  FaTruck,
  FaGlobeEurope,
  FaCaretDown,
  FaCaretUp,
  FaHandsWash,
  FaUndo,
} from "react-icons/fa";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import { Icon } from "leaflet";
import "leaflet/dist/leaflet.css";
import pin from "../map/pin.svg";
import "chart.js/auto";
import { useState } from "react";
import { useEffect } from "react";
import { Statistic, Icon as StatIcon } from "semantic-ui-react";

function Table({ id: timestamp }) {
  const query = gql`
    query GetCleaningsByDate {
      Cleaning(where: {cleaning_date: {_eq: "${timestamp}"}}) {
        cleaning_date
        Item {
          id
          size
          arrival_country
          arrival_date
          type
          returns: Delivery_backs_aggregate {
            aggregate {
              count
            }
          }
          deliveries: Deliveries_aggregate {
            aggregate {
              count
            }
          }
          cleanings: Cleanings_aggregate {
            aggregate {
              count
            }
          }
        }
        Cleaner {
          enterprise
          id
          lat
          long
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(query);

  const [displayData, setDisplayData] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [EllipsisButton, setEllipsisButton] = useState(
    <>
      <FaCaretDown color="grey" />
      Afficher tout
    </>
  );

  useEffect(() => {
    if (data) {
      const items = data.Cleaning.map((cleaning) => cleaning.Item);
      setDisplayData(items.slice(0, showAll ? items.length : 5));
      setEllipsisButton(
        showAll ? (
          <>
            <FaCaretUp color="grey" />
            Afficher moins
          </>
        ) : (
          <>
            <FaCaretDown color="grey" />
            Afficher tout
          </>
        )
      );
    }
  }, [data, showAll]);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  const cleaner = data.Cleaning[0].Cleaner;
  const cleanerMarker = (
    <Marker
      position={[cleaner.lat, cleaner.long]}
      icon={
        new Icon({
          iconUrl: pin,
          iconSize: [75, 102],
          iconAnchor: [24, 82],
          popupAnchor: [1, -34],
          shadowSize: [41, 41],
        })
      }
    >
      <Popup>
        <div>
          <h3>Laveur {cleaner.enterprise}</h3>
        </div>
      </Popup>
    </Marker>
  );

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Statistic.Group
          horizontal
          style={{
            width: "50%",
            height: "100%",
            alignItems: "center",
          }}
        >
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="calendar" color="grey" />
              Le{" "}
              {new Date(timestamp).toLocaleDateString("fr-FR", {
                timeZone: "Europe/Paris",
              })}{" "}
              à{" "}
              {new Date(timestamp).toLocaleTimeString("fr-FR", {
                timeZone: "Europe/Paris",
              })}
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="building" color="grey" />
              {data.Cleaning[0].Cleaner.enterprise}
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="box" color="grey" />
              {data.Cleaning.length}
            </Statistic.Value>
          </Statistic>
          <Statistic>
            <Statistic.Value text>
              <StatIcon name="expand" color="grey" />
              {data.Cleaning[0].Item.size}{" "}
              {data.Cleaning[0].Item.type ?? " degrenne"}
            </Statistic.Value>
          </Statistic>
        </Statistic.Group>
        <div
          style={{
            width: "50%",
            height: "500px",
            alignItems: "center",
          }}
        >
          <MapContainer
            center={[
              data.Cleaning[0].Cleaner.lat,
              data.Cleaning[0].Cleaner.long,
            ]}
            zoom={13}
            scrollWheelZoom={false}
            style={{ height: "100%", width: "100%" }}
          >
            <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
            {cleanerMarker}
          </MapContainer>
        </div>
      </div>
      <div
        style={{
          marginTop: "20px",
          fontColor: "grey",
          fontSize: "30px",
          fontWeight: "bold",
        }}
      >
        {displayData?.map((item) => (
          <Item
            key={item.id}
            id={item.id}
            arrival_country={item.arrival_country}
            arrival_date={item.arrival_date}
            deliveries={item.deliveries.aggregate.count}
            returns={item.returns.aggregate.count}
            cleanings={item.cleanings.aggregate.count}
          />
        ))}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              cursor: "pointer",
              fontSize: "20px",
            }}
            onClick={() => {
              setShowAll(!showAll);
            }}
            onKeyPress={() => {
              setShowAll(!showAll);
            }}
          >
            {EllipsisButton}
          </div>
        </div>
      </div>
    </div>
  );
}

const Item = ({ id, arrival_country, deliveries, returns, cleanings }) => {
  return (
    <div
      style={{
        background: "#73AD21",
        padding: "15px",
        width: "100%",
        height: "75px",
        borderSize: "1",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-evenly",
        gap: "1em",
        fontSize: "16px",
        fontWeight: "normal",
      }}
    >
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaFingerprint color="blue" />
        <span style={{ fontWeight: "bold" }}>Contenant : </span>
        <a href={`/contenant/${id}`}>{id}</a>
      </div>
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaGlobeEurope color="purple" />
        <div>{arrival_country}</div>
      </div>
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaHandsWash color="darkorange" />
        <div>
          Lavages : <span style={{ fontWeight: "bold" }}>{cleanings}</span>
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaTruck color="darkred" />
        <div>
          Livraisons : <span style={{ fontWeight: "bold" }}>{deliveries}</span>
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          overflow: "hidden",
          backgroundColor: "white",
          display: "flex",
          flexDirection: "row",
          gap: "8px",
        }}
      >
        <FaUndo color="green" />
        <div>
          Retours : <span style={{ fontWeight: "bold" }}>{returns}</span>
        </div>
      </div>
    </div>
  );
};

function BatchCleaningDate({ date }) {
  const params = useParams();
  const timestamp = params.timestamp.replace(/ (\d\d:\d\d)$/, "+$1");
  const { initialized } = useKeycloak();
  return initialized ? (
    <Container className="container">
      <div>
        <NavBar />
        <Row>
          <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Menu />
          </Col>
          <Col sm={10}>
            <div>
              <div style={{ marginTop: "100px", marginLeft: "15px" }}>
                <h4 style={{ margin: "10px", textAlign: "center" }}>
                  <Table id={timestamp} />
                </h4>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  ) : null;
}

export default BatchCleaningDate;
