import { gql } from "@apollo/client";

export const GET_WAREHOUSE = gql`
  query GetWarehouse {
    warehouse {
      id
      name
      brand
    }
  }
`;