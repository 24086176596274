import React, { useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { Icon, Popup } from "semantic-ui-react";
import gql from "graphql-tag";
import { useApolloClient } from "@apollo/client";

function ChangeStatusButton({ bac, status }) {
  const [smShow, setSmShow] = useState(false);
  const style = {
    borderRadius: 0,
    opacity: 1,
    padding: "2em",
  };
  const modifyList = [];
  const returnTypeList = [];
  const onChange = (check, status) => {
    const tempObject = {
      id: bac,
      check: check.target.checked,
      status: status,
    };

    if (modifyList.length > 0) {
      if (modifyList[0].status === status) {
        modifyList.shift();
      } else if (modifyList[modifyList.length - 1].status === status) {
        modifyList.pop();
      } else if (modifyList.some((e) => e.status === status)) {
        modifyList.splice(
          modifyList.findIndex((e) => e.status === status),
          1
        );
      }
    }
    if (check.target.checked) {
      modifyList.push(tempObject);
    }
  };
  const onChangeReturnType = (check, return_type) => {
    const tempObject = {
      id: bac,
      check: check.target.checked,
      return_type: return_type,
    };
    if (returnTypeList.length > 0) {
      if (modifyList[0].status === status) {
        modifyList.shift();
      } else if (modifyList[modifyList.length - 1].status === status) {
        modifyList.pop();
      } else if (modifyList.some((e) => e.status === status)) {
        modifyList.splice(
          modifyList.findIndex((e) => e.status === status),
          1
        );
      }
    }
    if (check.target.checked) {
      returnTypeList.push(tempObject);
    }
  };
  const client = useApolloClient();
  const onClick = () => {
    if (modifyList.length > 1) {
      alert("Veuillez sélectionner qu'un seul statut pour le bac");
    } else if (modifyList.length === 1) {
      if (
        modifyList[0].status === "opened" ||
        modifyList[0].status === "close"
      ) {
        if (returnTypeList.length === 1) {
          const mutateSupply = gql`mutation MyMutation3 {
  update_Container(where: {id: {_eq: "${bac}"}}, _set: {status: "${modifyList[0].status}", usedFor: "${returnTypeList[0].return_type}"}) {
    affected_rows
  }
}`;
          client
            .mutate({
              mutation: mutateSupply,
            })
            .then((r) => setSmShow(false));
          window.location.reload(false);
        } else {
          alert("Veuillez sélectionner un type de retour pour le bac");
        }
      } else {
        const mutateSupply = gql`mutation MyMutation3 {
  update_Container(where: {id: {_eq: "${bac}"}}, _set: {status: "${modifyList[0].status}"}) {
    affected_rows
  }
}`;
        client
          .mutate({
            mutation: mutateSupply,
          })
          .then((r) => setSmShow(false));
        window.location.reload(false);
      }
    } else {
      alert("Veuillez sélectionner 1 statut pour le bac");
    }
  };

  return (
    <>
      <Popup
        trigger={
          <Button
            onClick={() => {
              setSmShow(true);
            }}
            style={{
              borderColor: "transparent",
              backgroundColor: "#198754",
              margin: "15px",
            }}
          >
            <Icon name={"exchange"} />
          </Button>
        }
        content={"Changer le statut"}
        style={style}
        inverted
      />
      <Modal
        size="lg"
        show={smShow}
        onHide={() => setSmShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">
            Changements pour le bac {bac}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <h3>Statut du bac</h3>
            <Form.Check
              type={"checkbox"}
              id={"shipped"}
              label={"Livré (en magasin)?"}
              onChange={(e) => onChange(e, "shipped")}
            />
            <Form.Check
              type={"checkbox"}
              id={"opened"}
              label={"Ouvert ?"}
              onChange={(value) => onChange(value, "opened")}
            />
            <Form.Check
              type={"checkbox"}
              id={"close"}
              label={"Fermé ?"}
              onChange={(value) => onChange(value, "close")}
            />
            <Form.Check
              type={"checkbox"}
              id={"received"}
              label={"En stock laveur ?"}
              onChange={(value) => onChange(value, "received")}
            />
            <hr />
            <h3>Type de retours (si statut du bac = 'ouvert' )</h3>
            <Form.Check
              type={"checkbox"}
              id={"Retour_client"}
              label={"Retour clients"}
              onChange={(value) => {
                onChangeReturnType(value, "Retour_client");
              }}
            />
            <Form.Check
              type={"checkbox"}
              id={"Invendu"}
              label={"Invendus"}
              onChange={(value) => {
                onChangeReturnType(value, "Invendu");
              }}
            />
            <Form.Check
              type={"checkbox"}
              id={"both"}
              label={"Les 2"}
              onChange={(value) => {
                onChangeReturnType(value, "both");
              }}
            />
            <Button variant="primary" type="submit" onClick={onClick}>
              Valider
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}
export { ChangeStatusButton };
