import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useKeycloak } from "react-keycloak";
import { useParams } from "react-router-dom";
import { Divider, Grid, Icon } from "semantic-ui-react";
import Menu from "../../LeftMenu";
import NavBar from "../../Navbar";
import { ItemsInContainerTable } from "./CollectContainerInfos";

const ContainerLocation = ({ item_id }) => {
  const query = gql`
    query ItemLocation {
      Item_by_pk(id: "${item_id}") {
        Deliveries(order_by: {delivery_date: desc}, limit: 1) {
          Shop {
            name
            brand
          }
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(query);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  console.log(query.loc.source.body);

  return data.Item_by_pk.Deliveries[0] ? (
    <span>
      {data.Item_by_pk.Deliveries[0].Shop.brand} -{" "}
      {data.Item_by_pk.Deliveries[0].Shop.name}
    </span>
  ) : (
    <span>Pas de traçabilité</span>
  );
};

const ItemTypeInfos = ({ item_id }) => {
  const query = gql`
    query ItemTypeInfos {
      Item_by_pk(id: "${item_id}") {
        Format {
          name
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(query);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return <span>{data.Item_by_pk?.Format.name ?? "N/A"}</span>;
};

const GridExampleDividedPhrase = ({ id }) => {
  const query = gql`
      query MyQuery3 {
          Container_by_pk(id: "${id}") {
              choosen_shop
              choosen_delivery_date
              choosen_size
              items
              id
              max_big
              max_small
              status
              updatedDate
              Shop {
                  brand
                  name
                  id
              }
          }
      }
  `;

  const { loading, error, data } = useQuery(query);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  let nbContainers = 0;
  if (data.Container_by_pk.items !== null) {
    let items = data.Container_by_pk.items.replace("[");
    items = items.replace("]");
    nbContainers = items.split(",").length;
  }
  let statusFr;
  console.log(data.Container_by_pk.status);
  if (data.Container_by_pk.status === "empty") {
    statusFr = "Non entamé";
  } else if (data.Container_by_pk.status === "full") {
    statusFr = "Plein";
  } else if (
    data.Container_by_pk.status === "ready" &&
    data.Container_by_pk.choosen_delivery_date === null
  ) {
    statusFr = "Prêt pour livraison";
  } else if (
    data.Container_by_pk.status === "ready" &&
    data.Container_by_pk.choosen_delivery_date !== null
  ) {
    statusFr = "Livré en magasin";
  } else if (data.Container_by_pk.status === "consumed") {
    statusFr = "Consommé";
  } else if (data.Container_by_pk.status === "transit") {
    statusFr = "En transit";
  }
  const shopFr = `${data.Container_by_pk.Shop?.brand} - ${data.Container_by_pk.Shop?.name}`;
  const lastActivity = new Date(data.Container_by_pk.updatedDate);
  const deliveryDate = new Date(data.Container_by_pk.choosen_delivery_date);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };

  return (
    <Grid columns="three" divided>
      <Grid.Row>
        <Grid.Column width={2}>
          {data.Container_by_pk.status === "full" ? (
            <Icon name="warehouse" size="huge" color="purple" />
          ) : data.Container_by_pk.status === "empty" ? (
            <Icon name="warehouse" size="huge" color="purple" />
          ) : (
            <Icon name="shop" size="huge" color="purple" />
          )}
        </Grid.Column>
        <Grid.Column width={3}>
          {data.Container_by_pk.status === "full" ? (
            <>
              <p style={{ textDecoration: "underline" }}>Stocké à : </p>
              <ContainerLocation
                item_id={
                  data.Container_by_pk.items
                    .replace("[", "")
                    .replace("]", "")
                    .split(", ")[0]
                }
              />
            </>
          ) : data.Container_by_pk.status === "empty" ? (
            <>
              <p style={{ textDecoration: "underline" }}>Stocké à : </p>
              <span>Pas encore de traçabilité</span>
            </>
          ) : (
            <>
              <p style={{ textDecoration: "underline" }}>Livré à : </p>
              <span>
                {data.Container_by_pk.choosen_shop === null
                  ? "pas de magasin associé"
                  : shopFr}
              </span>
            </>
          )}
        </Grid.Column>
        <Grid.Column width={2}>
          <Icon name="calendar alternate" size="huge" color="red" />
        </Grid.Column>
        <Grid.Column width={3}>
          <p style={{ textDecoration: "underline" }}>Livré le: </p>
          <span style={{ fontWeight: "bold" }}>
            {data.Container_by_pk.choosen_delivery_date === null
              ? "pas de date de livraison"
              : deliveryDate.toLocaleString("fr-FR", options)}
          </span>
        </Grid.Column>
        <Grid.Column width={2}>
          <Icon name="resize horizontal" size="huge" color="green" />
        </Grid.Column>
        <Grid.Column width={3}>
          <p style={{ textDecoration: "underline" }}>Taille contenants: </p>
          <span style={{ fontWeight: "bold" }}>
            {data.Container_by_pk.choosen_size === null ? (
              "non renseigné"
            ) : (
              <ItemTypeInfos
                item_id={
                  data.Container_by_pk.items
                    ?.replace("[", "")
                    .replace("]", "")
                    .split(", ")[0] ?? null
                }
              />
            )}
          </span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        <Grid.Column width={2}>
          <Icon name="zoom" size="huge" color="blue" />
        </Grid.Column>
        <Grid.Column width={3}>
          <p style={{ textDecoration: "underline" }}>Nombre contenants: </p>
          <span style={{ fontWeight: "bold" }}>
            {data.Container_by_pk.items === "" ? "vide" : nbContainers}
          </span>
        </Grid.Column>
        <Grid.Column width={2}>
          <Icon name="info circle" size="huge" color="grey" />
        </Grid.Column>
        <Grid.Column width={3}>
          <p style={{ textDecoration: "underline" }}>Statut: </p>{" "}
          <span style={{ fontWeight: "bold" }}>
            {data.Container_by_pk.status === null ? "non renseigné" : statusFr}
          </span>
        </Grid.Column>
        <Grid.Column width={2}>
          <Icon name="pallet" size="huge" color="pink" />
        </Grid.Column>
        <Grid.Column width={3}>
          <p style={{ textDecoration: "underline" }}>
            Données mises à jour le :{" "}
          </p>{" "}
          <span style={{ fontWeight: "bold" }}>
            {data.Container_by_pk.updatedDate === null
              ? "non renseigné"
              : lastActivity.toLocaleString("fr-FR", options) ===
                "jeudi 30 juin 2022, 16:18"
              ? "Non tracée"
              : lastActivity.toLocaleString("fr-FR", options)}
          </span>
        </Grid.Column>
      </Grid.Row>
      {data.Container_by_pk.items !== null && (
        <ItemsInContainerTable items={data.Container_by_pk.items} />
      )}
    </Grid>
  );
};

const ContainerAggregate = ({ container }) => {
  const query = gql`
      query MyQuery {
          usedContainerByShop(where: {container: {_eq: "${container}"}}) {
              sumreturned
              sumdelivery
          }
      }
  `;
  const { loading, error, data } = useQuery(query);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  if (data.usedContainerByShop.length === 0) {
    return <></>;
  } else {
    console.log("usedContainerByShop", data.usedContainerByShop);
    const textPercentage = Math.round(
      (parseInt(data.usedContainerByShop[0].sumreturned) /
        parseInt(data.usedContainerByShop[0].sumdelivery)) *
        100
    ).toFixed(2);
    const percentage = textPercentage > 100 ? 100 : textPercentage;
    let color = "orange";
    if (percentage < 30) color = "red";
    else if (percentage < 75) color = "green";
    else if (percentage >= 95) color = "#72b5ff";

    return (
      <div
        style={{
          padding: "15px",
          width: "100%",
          height: "75px",
          borderSize: "1",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          fontSize: "16px",
          fontWeight: "normal",
        }}
      >
        <div
          style={{
            width: "95%",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          Barquettes retournées:
          <div style={{ width: "25px" }} />
          <div
            style={{
              width: "70px",
              alignItems: "center",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <CircularProgressbar
              value={percentage}
              text={`${textPercentage}%`}
              styles={buildStyles({
                textColor: color,
                pathColor: color,
              })}
            />
          </div>
        </div>
      </div>
    );
  }
};

const ContainerInfos = () => {
  const params = useParams();
  const container = params.id;
  const { initialized } = useKeycloak();
  return initialized ? (
    <Container className="container">
      <div>
        <NavBar />
        <Row>
          <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Menu />
          </Col>
          <Col sm={10}>
            <div>
              <div style={{ marginTop: "100px", marginLeft: "15px" }}>
                <div>
                  <h2 style={{ margin: "10px", textAlign: "center" }}>
                    Colis/caisse IFCO - {container}
                  </h2>
                  <Divider horizontal>
                    <Icon name="info circle" />
                    État du colis
                  </Divider>
                  <ContainerAggregate container={container} />
                  <Divider horizontal>
                    <Icon name="info circle" />
                    Informations générales
                  </Divider>

                  <h4 style={{ margin: "10px", textAlign: "center" }}>
                    <GridExampleDividedPhrase id={container} />
                  </h4>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  ) : null;
};
export { ContainerInfos };
