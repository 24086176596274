import { gql } from "@apollo/client";

export const GET_CLEANERS = gql`
  query GetCleaners {
    Cleaner {
      id
      enterprise
    }
  }
`;


export const GET_CLEANINGS = gql`
  query GetCleanings($cleaner: uuid!, $date1: timestamptz!, $date2: timestamptz!) {
  Cleaning(where: {cleaner: {_eq: $cleaner}, cleaning_date: {_gt: $date1}, _and: {cleaning_date: {_lt: $date2}}}, order_by: {cleaning_date: asc}) {
    id
    scan_in
    cleaning_date
    cleaner
    Item {
      type
      id
      polished
    }
  }
}

`;


export const UPDATE_ITEMS = gql`
    
    mutation UpdateItems($ids: [String!]) {
  update_Item(where: {id: {_in: $ids}}, _set: {polished: true}) {
    affected_rows
  }
}
`;

export const ADD_POLISHED_ITEMS = gql`
    
    mutation MyMutation($objects: [polished_items_insert_input!]!) {
  insert_polished_items(objects: $objects, on_conflict: {constraint: polished_items_item_id_key, update_columns: item_id}) {
    affected_rows
  }
}
`;