import React, { useState } from "react";
import { Modal, Button, Input } from "semantic-ui-react";
import axios from "axios";

const ModalNotification = ({ userId, open, onClose, header }) => {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [successSendNotif, setSuccessSendNotif] = useState(false);

  const handleTitleChange = (e) => {
    setTitle(e.target.value);
  };

  const handleContentChange = (e) => {
    setContent(e.target.value);
  };

  const handleSend = async () => {
    try {
      const dashboardKeycloakToken = localStorage.getItem(
        "DashboardKeycloakToken"
      );
      const response = await axios.post(
        "https://europe-west1-bernypro-d3577.cloudfunctions.net/notifications",
        {
          textMessage: content,
          textTitle: title,
          clientId: userId,
          origin: "Dashboard",
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${dashboardKeycloakToken}`,
          },
        }
      );
      if (response.status === 200) {
        console.log("Notification sent successfully!");
        setSuccessSendNotif(true);
      } else {
        console.error("Failed to send notification.");
      }
    } catch (error) {
      console.error("Error sending notification:", error);
    }
  };

  return (
    <Modal className="modal-container" open={open} onClose={onClose}>
      <Modal.Header>{header}</Modal.Header>
      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Titre de la notification
        <Input value={title} onChange={handleTitleChange} placeholder="Titre" />
      </Modal.Content>
      <Modal.Content
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        Contenu de la notification
        <Input
          value={content}
          onChange={handleContentChange}
          placeholder="Texte"
        />
      </Modal.Content>
      <Modal.Actions>
        {successSendNotif ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <span
              style={{ fontWeight: "bold" }}
              role="img"
              aria-label="Success"
            >
              ✅ Notification envoyé ✅
            </span>
          </div>
        ) : (
          <Button color="green" onClick={handleSend}>
            Envoyer
          </Button>
        )}
        <Button color="red" onClick={onClose}>
          Fermer
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ModalNotification;
