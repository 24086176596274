import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import moment from "moment";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import {Card, Divider, Table} from "semantic-ui-react";
import LeftMenu from "../../LeftMenu";
import NavBar from "../../Navbar";
import { Accordion } from "react-bootstrap";

function containerEllipsis(name) {
  return name.length > 10 ? `${name.slice(0, 5)}...${name.slice(-5)}` : name;
}

function getDateRange() {
  const currentDate = moment();
  const oneMonthAgo = moment(currentDate).subtract(1, "months");

  return {
    startDate: oneMonthAgo.format("YYYY-MM-DDTHH:mm:ss"),
  };
}

function getDate2Range() {
  const currentDate = moment();
  const oneMonthAgo = moment(currentDate).subtract(1, "months");

  return {
    startDate: oneMonthAgo.format("YYYY-MM-DD"),
  };
}

function ReceiptsCardContent() {
  const { startDate } = getDateRange();

  const query = gql`
    query ($startDate: String) {
      receipts_V2(
        where: { datetime: { _gte: $startDate } }
        order_by: { datetime: desc, transportedIn: asc }
        distinct_on: transportedIn
      ) {
        datetime
        bm30
        bm45
        bl30
        bl45
        transportedIn
        enterprise
        brand
        name
      }
      Shop {
        id
        name
        brand
      }
    }
  `;

  const { loading, error, data } = useQuery(query, {
    variables: { startDate },
  });

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.error(error);
    return <p>Error :(</p>;
  }

  const sortedData = data.receipts_V2
    .slice()
    .sort((a, b) => new Date(b.datetime) - new Date(a.datetime));

  const receiptsByDay = sortedData.reduce((acc, receipt) => {
    const date = moment(receipt.datetime).format("DD/MM/YYYY");
    if (!acc.has(date)) {
      acc.set(date, {
        shops: new Map(), // Keep count of how many time each shop (`${brand} ${name}`) appears | name -> count
        containers: new Set(), // Keep a list of containers
        cleaners: new Map(), // Keep count of how many time each cleaner enterprise appears | name -> count
        bm30: [],
        bm45: [],
        bl30: [],
        bl45: [],
        total: []
      });
    }
    const day = acc.get(date);
    const shop = receipt.brand
      ? `${receipt.brand} ${receipt.name}`
      : "[Erreur]";
    if (!day.shops.has(shop)) {
      day.shops.set(shop, {
        id:
          data.Shop.find(
            (s) => s.brand === receipt.brand && s.name === receipt.name
          )?.id ?? null,
        count: 0,
      });
    }
    day.shops.set(shop, {
      ...day.shops.get(shop),
      count: day.shops.get(shop).count + 1,
    });

    day.bm30.push(receipt.bm30);
    day.bm45.push(receipt.bm45);
    day.bl30.push(receipt.bl30);
    day.bl45.push(receipt.bl45);
    day.total.push(receipt.bm30 +  receipt.bm45 + receipt.bl30 + receipt.bl45);

        day.containers.add(receipt.transportedIn);
    if (!day.cleaners.has(receipt.enterprise)) {
      day.cleaners.set(receipt.enterprise, 0);
    }
    day.cleaners.set(
      receipt.enterprise,
      day.cleaners.get(receipt.enterprise) + 1
    );
    return acc;
  }, new Map());

  return (
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey={0}>
        <Accordion.Header>📥 Historique des réceptions</Accordion.Header>
        <Accordion.Body>
          <Card.Content>
            <Card.Description>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Magasin</Table.HeaderCell>
                    <Table.HeaderCell>Laveur</Table.HeaderCell>
                    <Table.HeaderCell>Bacs</Table.HeaderCell>
                    <Table.HeaderCell>BM30</Table.HeaderCell>
                    <Table.HeaderCell>BM45</Table.HeaderCell>
                    <Table.HeaderCell>BL30</Table.HeaderCell>
                    <Table.HeaderCell>BL45</Table.HeaderCell>
                    <Table.HeaderCell>Total</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Array.from(receiptsByDay.entries()).map(([date, day]) => {
                        let total = 0;
                        let totalBm30 = 0;
                        let totalBl30 = 0;
                        let totalBm45 = 0;
                        let totalBl45 = 0;
                        let totalContainer = 0;
                        return <><Table.Row key={date}>
                          <Table.Cell>
                            <a
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  display: "flex",
                                  justifyContent: "Center",
                                }}
                                href={`/reception/${moment(date, "DD/MM/YYYY").format(
                                    "YYYY-MM-DD"
                                )}`}
                            >
                              {date}
                            </a>
                          </Table.Cell>
                          <Table.Cell>
                            <ul
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  display: "flex",
                                  justifyContent: "Center",
                                  flexDirection: "column",
                                }}
                            >
                              {Array.from(day.shops.entries()).map(
                                  ([shop, count]) => (
                                      <li style={{listStyleType: "none"}} key={shop}>
                                        <a href={`/operation/shop/${count.id}`}>
                                          {shop}
                                        </a>{" "}
                                        ({count.count})
                                      </li>
                                  )
                              )}
                            </ul>
                          </Table.Cell>
                          <Table.Cell>
                            <ul
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  display: "flex",
                                  justifyContent: "Center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                            >
                              {Array.from(day.cleaners.entries()).map(
                                  ([cleaner, count]) => (
                                      <li
                                          style={{listStyleType: "none"}}
                                          key={cleaner}
                                      >
                                        {cleaner ? (
                                            <>
                                              {cleaner} ({count})
                                            </>
                                        ) : (
                                            <>En transit ({count})</>
                                        )}
                                      </li>
                                  )
                              )}
                            </ul>
                          </Table.Cell>
                          <Table.Cell>
                            <ul
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                            >
                              {Array.from(day.containers.values()).map(
                                  (container) => {
                                    totalContainer += 1;
                                    return <li
                                        style={{listStyleType: "none"}}
                                        key={container}
                                    >
                                      <a href={`/bac/${container}`}>
                                        {containerEllipsis(container)}
                                      </a>
                                    </li>
                                  }
                              )}
                              <li style={{listStyleType: "none", fontWeight: "bold"}}>Total : {totalContainer}</li>
                            </ul>
                          </Table.Cell>
                          <Table.Cell>
                            <ul
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  display: "flex",
                                  justifyContent: "Center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                            >
                              {Array.from(day.bm30).map((bm30) => {
                                totalBm30 += bm30
                                return <li style={{listStyleType: "none"}}>{bm30}</li>
                              })}
                              <li style={{listStyleType: "none", fontWeight: "bold"}}>Total : {totalBm30}</li>
                            </ul>
                          </Table.Cell>
                          <Table.Cell>
                            <ul
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  display: "flex",
                                  justifyContent: "Center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                            >
                              {Array.from(day.bm45).map((bm45) => {
                                totalBm45 += bm45;
                                return <li style={{listStyleType: "none"}}>{bm45}</li>
                              })}
                              <li style={{listStyleType: "none", fontWeight: "bold"}}>Total : {totalBm45}</li>
                            </ul>
                          </Table.Cell>
                          <Table.Cell>
                            <ul
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  display: "flex",
                                  justifyContent: "Center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                            >
                              {Array.from(day.bl30).map((bl30) => {
                                    totalBl30 += bl30;
                                    return <li style={{listStyleType: "none"}}>{bl30}</li>
                                  }
                              )}
                              <li style={{listStyleType: "none", fontWeight: "bold"}}>Total : {totalBl30}</li>
                            </ul>
                          </Table.Cell>
                          <Table.Cell>
                            <ul
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  display: "flex",
                                  justifyContent: "Center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                            >
                              {Array.from(day.bl45).map((bl45) => {
                                    totalBl45 += bl45;
                                    return <li style={{listStyleType: "none"}}>{bl45}</li>
                                  }
                              )}
                              <li style={{listStyleType: "none", fontWeight: "bold"}}>Total : {totalBl45}</li>
                            </ul>
                          </Table.Cell>
                          <Table.Cell className={"warning"}>
                            <ul
                                style={{
                                  margin: "0",
                                  padding: "0",
                                  display: "flex",
                                  justifyContent: "Center",
                                  alignItems: "center",
                                  flexDirection: "column",
                                }}
                            >
                              {Array.from(day.total).map((count) => {
                                  total += count;
                                  return <li style={{listStyleType: "none", fontWeight: "bold"}}>{count}</li>}
                              )}
                              <li style={{listStyleType: "none", fontWeight: "bold"}}>Total : {total}</li>
                            </ul>
                          </Table.Cell>
                        </Table.Row>

                  </>
                  })}
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function DeliveriesCardContent() {
  const { startDate } = getDate2Range();

  const query = gql`
    query MyQuery($startDate: date) {
      Container_shipped(
        where: { date: { _gte: $startDate } }
        order_by: { date: desc }
      ) {
        Shop {
          brand
          name
          id
        }
        Warehouse_receiver {
          id
          brand
          name
        }
        Cleaner {
          enterprise
          id
        }
        Warehouse_sender {
          id
          brand
          name
        }
        container_id
        date
      }
    }
  `;

  const { loading, error, data } = useQuery(query, {
    variables: { startDate },
  });

  if (loading) return <p>Loading...</p>;
  if (error) {
    console.error(error);
    return <p>Error :(</p>;
  }

  const deliveriesByDay = data.Container_shipped.reduce((acc, delivery) => {
    const date = moment(delivery.date).format("DD/MM/YYYY");
    if (!acc.has(date)) {
      acc.set(date, {
        shops: new Map(), // Keep count of how many time each shop (`${brand} ${name}`) appears | name -> count
        containers: new Set(), // Keep a list of containers
        cleaners: new Map(), // Keep count of how many time each cleaner (`${enterprise}`) appears | name -> count
      });
    }
    const day = acc.get(date);
    const shop = delivery.Shop
      ? `${delivery.Shop.brand} ${delivery.Shop.name}`
      : `${delivery.Warehouse_receiver.brand} ${delivery.Warehouse_receiver.name}`;
    if (!day.shops.has(shop)) {
      day.shops.set(shop, {
        id: delivery.Shop ? delivery.Shop.id : delivery.Warehouse_receiver.id,
        is_warehouse: !delivery.Shop,
        count: 0,
      });
    }
    day.shops.set(shop, {
      ...day.shops.get(shop),
      count: day.shops.get(shop).count + 1,
    });
    day.containers.add(delivery.container_id);
    const cleaner = delivery.Warehouse_sender
      ? `${delivery.Warehouse_sender.brand} ${delivery.Warehouse_sender.name}`
      : delivery.Cleaner.enterprise;
    if (!day.cleaners.has(cleaner)) {
      day.cleaners.set(cleaner, {
        id: delivery.Cleaner
          ? delivery.Cleaner.id
          : delivery.Warehouse_sender.id,
        is_warehouse: !delivery.Cleaner,
        count: 0,
      });
    }
    day.cleaners.set(cleaner, {
      ...day.cleaners.get(cleaner),
      count: day.cleaners.get(cleaner).count + 1,
    });
    return acc;
  }, new Map());

  return (
    <Accordion defaultActiveKey="0" flush>
      <Accordion.Item eventKey={0}>
        <Accordion.Header>📥 Historique des livraisons</Accordion.Header>
        <Accordion.Body>
          <Card.Content>
            <Card.Description>
              <Table celled>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Magasin(s)</Table.HeaderCell>
                    <Table.HeaderCell>Laveur</Table.HeaderCell>
                    <Table.HeaderCell>Bacs</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {Array.from(deliveriesByDay.entries()).map(([date, day]) => (
                    <Table.Row key={date}>
                      <Table.Cell>
                        <a
                          href={`/livraison/${moment(date, "DD/MM/YYYY").format(
                            "YYYY-MM-DD"
                          )}`}
                        >
                          {date}
                        </a>
                      </Table.Cell>
                      <Table.Cell>
                        <ul>
                          {Array.from(day.shops.entries()).map(
                            ([shop, count], i) => (
                              <li key={shop}>
                                <a
                                  href={
                                    count.is_warehouse
                                      ? `/operation/warehouse/${count.id}`
                                      : `/operation/shop/${count.id}`
                                  }
                                >
                                  {shop}
                                </a>{" "}
                                ({count.count})
                              </li>
                            )
                          )}
                        </ul>
                      </Table.Cell>
                      <Table.Cell>
                        <ul>
                          {Array.from(day.cleaners.entries()).map(
                            ([cleaner, count]) => (
                              <li key={cleaner}>
                                {cleaner} ({count.count})
                              </li>
                            )
                          )}
                        </ul>
                      </Table.Cell>
                      <Table.Cell>
                        <ul>
                          {Array.from(day.containers.values()).map(
                            (container) => (
                              <li key={container}>
                                <a href={`/bac/${container}`}>
                                  {containerEllipsis(container)}
                                </a>
                              </li>
                            )
                          )}
                        </ul>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Description>
          </Card.Content>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

function Content() {
  return (
    <Col>
      <Col sm={10} style={{ marginBottom: "2rem" }}>
        <Card fluid>
          <ReceiptsCardContent />
        </Card>
      </Col>
      <Col sm={10}>
        <Card fluid>
          <DeliveriesCardContent />
        </Card>
      </Col>
    </Col>
  );
}

const ReceiptsHistoryPage = () => {
  const { initialized } = useKeycloak();

  return initialized ? (
    <Container className="container">
      <NavBar />
      <Row>
        <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
          <LeftMenu />
        </Col>
        <Col
          xs={12}
          style={{ paddingTop: 100, paddingLeft: 50, paddingRight: 50 }}
        >
          <h1>Historique des transports de bacs</h1>
          <Content />
        </Col>
      </Row>
    </Container>
  ) : (
    <></>
  );
};

export default ReceiptsHistoryPage;
