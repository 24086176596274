import { useApolloClient } from "@apollo/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "chart.js/auto";
import React, { useState } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { FaFingerprint, FaWeightHanging } from "react-icons/fa";
import { Button } from "semantic-ui-react";
import { ChangeStatusButton } from "../../other/changeStatusButton";
import { GetItemsData } from "./Query";
import { _getBacByStatus, _getSize, _gqlStringToArray } from "./Tools";

const maxWeight = 17500;

const Item = ({ bacID, usedFor, status, items, updated_date }) => {
  function formatDate(dateString) {
    const options = { year: "numeric", month: "long", day: "numeric" };
    const formattedDate = new Date(dateString).toLocaleDateString(
      undefined,
      options
    );
    return formattedDate;
  }

  const query = GetItemsData(_gqlStringToArray(items));
  const { data } = query;

  const formatRepartition = data?.Item.reduce((acc, item) => {
    const formatName = item.Format?.name ?? "Non défini";
    if (formatName in acc) {
      acc[formatName].count++;
      acc[formatName].weight += item.Format?.weight ?? 0;
    } else
      acc[formatName] = {
        count: 1,
        weight: item.Format?.weight ?? 0,
      };
    return acc;
  }, {});

  const weight = data?.Item.reduce(
    (acc, item) => acc + (item.Format?.weight ?? 0),
    0
  );

  if (!formatRepartition || !weight) {
    return <></>;
  }

  const textPercentage = ((weight / maxWeight) * 100).toFixed(2);
  const percentage = weight > maxWeight ? 100 : textPercentage;
  let color = "orange";

  if (percentage < 30) color = "#72B5FF";
  else if (percentage < 75) color = "green";
  else if (weight >= maxWeight) color = "red";

  return (
    <div
      style={{
        borderSize: "1",
        borderRadius: "10px",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontSize: "16px",
        fontWeight: "normal",
      }}
    >
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <FaFingerprint style={{ marginRight: "10px" }} color="blue" />
        Bac id: {bacID}
      </div>
      <ul
        style={{
          padding: "0",
          marginBottom: "0",
          listStyleType: "none",
        }}
      >
        {Object.keys(formatRepartition).map((format) => {
          return (
            <li>
              {formatRepartition[format].count} x {format}
            </li>
          );
        })}
      </ul>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
          fontWeight: "bold",
        }}
      >
        <FaWeightHanging style={{ marginRight: "5px" }} color="grey" />
        {(
          data.Item.reduce((acc, item) => {
            acc += item.Format?.weight ?? 0;
            return acc;
          }, 0) /
            1000 +
          2.5
        ).toFixed(2)}
        kg
      </div>

      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        Espace utilisé :
        <div
          style={{
            width: "70px",
            marginLeft: "10px",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <CircularProgressbar
            value={percentage}
            text={`${textPercentage}%`}
            styles={buildStyles({
              textColor: color,
              pathColor: color,
            })}
          />
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        Utilisé pour:
        <div style={{ width: "10px" }} />
        <div
          style={{
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <span style={{ fontWeight: "bold" }}>
            {usedFor === "both" ? "Retours et invendus" : usedFor}
          </span>
        </div>
      </div>
      <div
        style={{
          alignItems: "center",
          display: "flex",
          flexDirection: "row",
        }}
      >
        Dernier retour:
        <span style={{ padding: "5px", fontWeight: "bold" }}>
          {formatDate(updated_date)}
        </span>
      </div>
      <ChangeStatusButton
        style={{ borderColor: "transparent !important" }}
        bac={bacID}
        status={status}
      />
    </div>
  );
};

function getBacData(bacData) {
  const data = [];

  bacData.forEach((bac) => {
    const rslt = GetItemsData(_gqlStringToArray(bac.items));
    if (rslt.success) {
      const size = _getSize(rslt.data);
      data.push({
        id: bac.id,
        size: size,
        usedFor: bac.usedFor,
        items: bac.items,
        updated_date: bac.updatedDate,
      });
    }
  });

  return data;
}

const ListContainerByStatus = ({ data, status, client }) => {
  const listDefaultValue = 5;
  const [listLen, setListLien] = useState(listDefaultValue);
  return (
    <div style={{ fontSize: "15px", fontWeight: "bold", width: "100%" }}>
      Bacs
      <span style={{ marginLeft: "5px" }}>
        {status === "en attente de livraison (collecte demandée)"
          ? status
          : `${status}s`}
      </span>
      {data.length < 1 ? (
        <div
          style={{
            marginLeft: "10px",
            fontSize: "15px",
            fontWeight: "normal",
            width: "100%",
          }}
        >
          Pas de bac{" "}
          {status === "en attente de livraison" ? status : `${status}s`}
        </div>
      ) : (
        <div>
          {data.slice(0, listLen).map((bac) => {
            return (
              <Item
                bacID={bac.id}
                size={bac.size}
                key={bac.id}
                usedFor={bac.usedFor}
                client={client}
                status={status}
                items={bac.items}
                updated_date={bac.updated_date}
              />
            );
          })}
          {data.length > listDefaultValue ? (
            <div>
              <Button
                onClick={() => {
                  listLen === listDefaultValue
                    ? setListLien(data.length)
                    : setListLien(listDefaultValue);
                }}
              >
                {" "}
                {listLen === listDefaultValue ? "+" : "-"}{" "}
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const ShopContainerCollectionStatus = ({ shopBacData }) => {
  const client = useApolloClient();
  const fullData = getBacData(_getBacByStatus(shopBacData, "close"));
  const openedData = getBacData(_getBacByStatus(shopBacData, "opened"));

  return (
    <div
      style={{
        width: "100%",
        marginLeft: "auto",
        marginRight: "auto",
        align: "center",
        justifyContent: "space-between",
      }}
    >
      <ListContainerByStatus data={fullData} status={"fermé"} client={client} />
      <div style={{ height: "30px" }} />
      <ListContainerByStatus
        data={openedData}
        status={"ouvert"}
        client={client}
      />
    </div>
  );
};

export { ShopContainerCollectionStatus };
