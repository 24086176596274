import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { GET_LOANS_BY_USER_ID } from "./Query";
import { Link } from "react-router-dom";

const UserArray = ({ users, token }) => {
  const [userStatuses, setUserStatuses] = useState([]);
  const [providerInfo, setProviderInfo] = useState({});

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear().toString();
    return `${day}/${month}/${year}`;
  };

  const { refetch: refetchLoans } = useQuery(GET_LOANS_BY_USER_ID, {
    variables: { id_user: "" },
    skip: true,
  });

  const fetchUserLoans = async (userId) => {
    try {
      const result = await refetchLoans({ id_user: userId });
      return result.data.loans.length;
    } catch (error) {
      console.error("Error fetching user loans:", error);
      return 0;
    }
  };

  const getUserProvider = async (id, token) => {
    const queryParams = `${id}`;
    const API_ENDPOINT = `https://europe-west1-bernypro-d3577.cloudfunctions.net/getUserProvider?id=${queryParams}`;

    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const response = await fetch(API_ENDPOINT, requestOptions);
      const jsonString = await response.json();
      const userData = JSON.parse(jsonString);

      if (userData && userData.length > 0) {
        const providerId = userData[0].providerId;
        return providerId;
      } else {
        console.log("userData is empty or doesn't contain expected data.");
        return null; // Or handle the case where userData is empty
      }
    } catch (error) {
      console.error("Error:", error.message);
      throw error;
    }
  };

  const getUserStatus = async (emailVerified, userId) => {
    if (!emailVerified) {
      return "❌ Inscription non terminée ❌";
    } else if (emailVerified) {
      const loansCount = await fetchUserLoans(userId);
      return loansCount > 0
        ? "🎊 Utilisateur actif 🎊"
        : "✅ Inscription terminée ✅";
    }
    return "";
  };

  useEffect(() => {
    const fetchData = async () => {
      // const authToken = token; NOT NEEDED DUE TO CLOUD FUNCTION : HAVING CORS ISSUE WHEN TRYING TO USE JWT 1 DAY WASTE FOR TRYING THIS, ++ WASTED DAYS IF YOU TRIED AND FAILED
      const providerInfoMap = {};
      sortedUsers.forEach((user, index) => {
        if (user.username.includes("appleid")) {
          providerInfoMap[user.username] = " Apple ";
          return;
        }
        if (user.attributes?.account_type?.length > 0) {
          if (user.attributes.account_type[0] === "berny") {
            providerInfoMap[user.username] = "📧 Email 📧";
          } else if (user.attributes.account_type[0] === "phone") {
            providerInfoMap[user.username] = "📱 Téléphone 📱";
          } else {
            providerInfoMap[user.username] = user.attributes.account_type;
          }
        }
        if (providerInfoMap[user.username] === "") {
          providerInfoMap[user.username] = providerData[index];
        }
      });
      const providerData = await Promise.all(
        sortedUsers.map(async (user) => {
          if (providerInfoMap[user.username] === "") {
            try {
              const userData = await getUserProvider(user.username);
              return userData;
            } catch (error) {
              console.log({ error });
              return { providerId: "Error Fetching Data" };
            }
          }
        })
      );
      setProviderInfo(providerInfoMap);
    };

    const fetchStatuses = async () => {
      const statuses = await Promise.all(
        users.map((user) => getUserStatus(user.emailVerified, user.id))
      );
      setUserStatuses(statuses);
    };

    fetchStatuses();
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps, no-use-before-define
  }, [users, sortedUsers]);

  const sortedUsers = users.slice().sort((b, a) => {
    const dateA = new Date(parseInt(a.createdTimestamp));
    const dateB = new Date(parseInt(b.createdTimestamp));
    return dateA - dateB;
  });

  return (
    <table className="ui celled table">
      <thead>
        <tr>
          <th>Type de compte</th>
          <th>Nom d'utilisateur</th>
          <th>Statut</th>
          <th>Date de création</th>
          <th>Détails</th>
        </tr>
      </thead>
      <tbody>
        {sortedUsers.map((user, index) => {
          const modifiedUsername =
            user.username.startsWith("33") && !user.username.includes("@")
              ? `0${user.username.slice(2)}`
              : user.username;

          return (
            <tr key={index}>
              <td data-label="provider">
                {providerInfo[user.username] || "Non renseigné"}
              </td>
              <td data-label="user">{modifiedUsername}</td>
              <td data-label="status">{userStatuses[index]}</td>
              <td data-label="createdDate">
                {formatDate(user.createdTimestamp)}
              </td>
              <td data-label="details">
                <Link to={`/manage_users/${user.username}`}>
                  <button className="ui teal button">Voir plus</button>
                </Link>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default UserArray;
