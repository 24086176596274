import React from "react";
import { Statistic, Popup } from "semantic-ui-react";
import "chart.js/auto";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaBox, FaSpinner, FaLock } from "react-icons/fa";
import { _getBacByStatus } from "./Tools";

const BoxInfo = ({ title, icon, details, type }) => {
	const style = {
		borderRadius: 0,
		opacity: 1,
		padding: "2em",
	};
	const containerName = [];
	if (type === 1) {
		details.Container.forEach((el) => {
			if (el.status === "shipped") {
				containerName.push(el.id);
			}
		});
	} else {
		details.forEach((el) => {
			containerName.push(el.id);
		});
	}
	return (
		<div
			style={{
				borderRadius: "25px",
				background: "#73AD21",
				padding: "20px",
				width: "33%",
				height: "130px",
				borderSize: "5",
				backgroundColor: "#EBD2BE",
				display: "flex",
				alignItems: "center",
				justifyContent: "space-between",
			}}
		>
			<div
				style={{
					width: "70%",
					height: "100px",
					alignItems: "center",
				}}
			>
				<div
					style={{
						height: "25%",
						fontColor: "grey",
						fontSize: "15px",
					}}
				>
					{title}
				</div>
				<div
					style={{
						marginTop: "10px",
						fontColor: "grey",
						fontSize: "30px",
						fontWeight: "bold",
					}}
				>
					<Popup
						trigger={
							<Statistic>
								<Statistic.Value>{containerName.length}</Statistic.Value>
							</Statistic>
						}
						content={`Détail bacs: ${containerName}`}
						style={style}
						inverted
					/>
				</div>
			</div>
			<div
				style={{
					width: "20%",
					height: "100px",
					alignItems: "center",
					alignContent: "center",
					verticalAlign: "middle",
					display: "flex",
				}}
			>
				{icon}
			</div>
		</div>
	);
};

const ShopContainerInfo = ({ shopBacData }) => {
	const bacOpen = _getBacByStatus(shopBacData, "opened");
	const bacClose = _getBacByStatus(shopBacData, "close");

	return (
		<div
			style={{
				paddingLeft: "0px",
				width: "100%",
				marginLeft: "auto",
				marginRight: "auto",
				align: "center",
				display: "flex",
				justifyContent: "space-between",
			}}
		>
			<BoxInfo
				title="Nombre de bacs en réserve: "
				icon={<FaBox size="100%" color="brown" />}
				details={shopBacData}
				type={1}
			/>
			<BoxInfo
				title="Nombre ouverts: "
				icon={<FaSpinner size="100%" color="orange" />}
				details={bacOpen}
				type={2}
			/>
			<BoxInfo
				title="Nombre fermés: "
				icon={<FaLock size="100%" color="grey" />}
				details={bacClose}
				type={2}
			/>
		</div>
	);
};

export { ShopContainerInfo };
