import React from "react";
import { Statistic, Icon, Feed, Divider, Popup } from "semantic-ui-react";
import { Col, Container, Row } from "react-bootstrap";
import gql from "graphql-tag";
import { useQuery } from "@apollo/client";

const ContainerInShops = () => {
	const query = gql`
    query MyQuery {
      Container_aggregate(
        where: {
          status: { _in: ["opened", "close", "waitingDelivery", "shipped"] }
        }
      ) {
        aggregate {
          count
        }
      }
    }
  `;
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	return (
		<Statistic.Group widths="one">
			<Statistic>
				<Statistic.Value>
					<Icon name="zip" color="red" />
					<span style={{ color: "#005258" }}>
						{data.Container_aggregate.aggregate.count}
					</span>
				</Statistic.Value>
				<Statistic.Label>
					<span style={{ color: "#007982" }}>En magasin</span>
				</Statistic.Label>
			</Statistic>
		</Statistic.Group>
	);
};

const ContainerFeedTop = () => {
	let shopName;
	const most = [];
	const values = [];
	const query = gql`
    query MyQuery4 {
      Container(where: { status: { _eq: "opened" } }) {
        items
        id
        choosen_shop
        usedFor
      }
      Shop {
        brand
        name
        id
      }
    }
  `;
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;

	const receiptsInfos = [];

	data.Container.forEach((el) => {
		let image;
		data.Shop.forEach((element) => {
			if (el.choosen_shop === element.id) {
				shopName = `${element.brand}-${element.name}`;
				if (element.brand === "U") {
					image = "/u.png"
				} else if (element.brand === "Leclerc") {
					image = "/1200px-Logo_E.Leclerc_Sans_le_texte.svg.png"			
				} else if (element.brand === "Intermarché") {
					image = "/730-dmwew3stwq.webp"
				} else if (element.brand === "Sodebo") {
					image = "/1200px-Logosodebo.svg.png"
				} else {
					image = "/kisspng-logo-carrefour-market-brand-image-startup-weekend-monaco-2017-retour-sur-la-4ème-5b677e2a9f4257.9785071315335091626523.jpg"
				}
			}
		});

		let items = el.items?.replace("[", "") ?? "";
		items = items.replace("]", "");

		const numberOfItems = items.split(", ");

		if (numberOfItems.length > 35) {
			receiptsInfos.push({
				items: numberOfItems.length,
				shopName: shopName,
				id: el.id,
				usedFor: el.usedFor,
				image: image,
			});
		} else {
			most.push(numberOfItems.length);
			values.push(shopName);
		}
	});

	const receipts = receiptsInfos
		.sort((a, b) => b.items - a.items)
		.map((el) => (
			<Feed.Event>
				<Feed.Label image={el.image} />
				<Feed.Content>
					<Feed.Summary content={`BAC n°${el.id}`} />
					<Feed.Extra text>
						Nombre contenants:{" "}
						<span style={{ fontWeight: "bold" }}>{el.items}</span>
					</Feed.Extra>
					<Feed.Extra text>
						Chez: <span style={{ fontWeight: "bold" }}>{el.shopName}</span>
					</Feed.Extra>
					<Feed.Extra text>
						Type:{" "}
						<span style={{ fontWeight: "bold" }}>
							{el.usedFor === "both" ? "Retours et invendus" : el.usedFor}
						</span>
					</Feed.Extra>
				</Feed.Content>
			</Feed.Event>
		));

	return (
		<div style={{ textAlign: "center" }}>
			<Feed>
				{receipts.length > 0
					? receipts
					: most.length > 0
					? ` Le plus rempli en est à ${Math.max(...most)} (${
							values[most.indexOf(Math.max(...most))]
					  })`
					: "Aucun bac n'est bientôt plein."}
			</Feed>
		</div>
	);
};
function groupBy(list, keyGetter) {
	const map = new Map();
	list.forEach((item) => {
		const key = keyGetter(item);
		const collection = map.get(key);
		if (!collection) {
			map.set(key, [item]);
		} else {
			collection.push(item);
		}
	});
	return map;
}

const ShopsClosedContainer = () => {
	let shopName;
	const values = [];
	const html = [];

	const query = gql`
    query MyQuery4 {
      Container(where: { status: { _eq: "close" } }) {
        items
        id
        choosen_shop
        usedFor
      }
      Shop {
        brand
        name
        id
      }
    }
  `;

	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const shops = [];
	data.Container.forEach((el) => {
		let items = el.items?.replace("[", "") ?? "";
		items = items?.replace("]", "");
		const numberOfItems = items?.split(", ") ?? [];
		let image;
		data.Shop.forEach((element) => {
			if (el.choosen_shop === element.id) {
				if (element.brand === "U") {
					shopName = `${element.brand}-${element.name}`;
					image = "/u.png"
				} else if (element.brand === "Leclerc") {
					shopName = `${element.brand}-${element.name}`;
					image = "/1200px-Logo_E.Leclerc_Sans_le_texte.svg.png"					
				} else if (element.brand === "Intermarché") {
					shopName = `${element.brand}-${element.name}`;
					image = "/730-dmwew3stwq.webp"
				} else {
					shopName = `${element.brand}-${element.name}`;
					image = "/kisspng-logo-carrefour-market-brand-image-startup-weekend-monaco-2017-retour-sur-la-4ème-5b677e2a9f4257.9785071315335091626523.jpg"
				}
			}
		});
		shops.push(shopName);
		values.push({
			shop: shopName,
			items: numberOfItems.length,
			bac: el.id,
			image: image,
		});
	});
	function onlyUnique(value, index, self) {
		return self.indexOf(value) === index;
	}
	const uniqueShops = shops.filter(onlyUnique);
	const style = {
		borderRadius: 0,
		opacity: 1,
		padding: "2em",
	};
	const res = groupBy(values, (val) => val.shop);
	uniqueShops
		.sort((a, b) => {
			const elementA = res.get(a);
			const elementB = res.get(b);
			let aItems = 0;
			let bItems = 0;
			elementA.forEach((el) => {
				aItems = aItems + el.items;
			});
			elementB.forEach((el) => {
				bItems = bItems + el.items;
			});
			return bItems - aItems;
		})
		.forEach((shop) => {
			let allItems = 0;
			const bacs = [];
			const element = res.get(shop);
			element.forEach((el) => {
				allItems = allItems + el.items;
				bacs.push(el.bac);
			});
			html.push(
				<Feed.Event>
					<Feed.Label image={element[0].image} />
					<Feed.Content>
						<Feed.Summary content={element[0].shop} />
						<Feed.Extra text>
							Nombre contenants:{" "}
							<span style={{ fontWeight: "bold" }}>{allItems}</span>
						</Feed.Extra>
						<Feed.Extra text>
							Nombre de bacs:{" "}
							<Popup
								trigger={
									<span style={{ fontWeight: "bold" }}>{bacs.length}</span>
								}
								content={`Détail bacs: ${bacs.toString()}`}
								style={style}
								inverted
							/>
						</Feed.Extra>
					</Feed.Content>
				</Feed.Event>,
			);
		});

	return (
		<div style={{ textAlign: "center" }}>
			<Feed>{html}</Feed>
		</div>
	);
};

const StockContainerSupply = () => {
	const query = gql`
    query MyQuery {
      Container(where: { status: { _eq: "received" } }) {
        id
      }
    }
  `;
	const { loading, error, data } = useQuery(query);
	if (loading) return "Loading...";
	if (error) return `Error! ${error.message}`;
	const container = [];
	data.Container.forEach((el) => {
		container.push(el.id);
	});
	const style = {
		borderRadius: 0,
		opacity: 1,
		padding: "2em",
	};
	return (
		<Statistic.Group widths="two">
			<Popup
				trigger={
					<Statistic>
						<Statistic.Value>{container.length}</Statistic.Value>
						<Statistic.Label>en stock</Statistic.Label>
					</Statistic>
				}
				content={`Détail bacs: ${container.toString()}`}
				style={style}
				inverted
			/>

			<Statistic>
				<Statistic.Value>
					<Icon name="map marker alternate" color="red" />
					Tsl
				</Statistic.Value>
				<Statistic.Label>Lieu de stockage</Statistic.Label>
			</Statistic>
		</Statistic.Group>
	);
};

const ContainerControl = () => {
	return (
		<Container className="container">
				<Row>
					<Col sm={4}>
						<h3 style={{ marginBottom:"30px" }}>
							Magasins avec le plus de bacs fermés
						</h3>
						<ShopsClosedContainer />
					</Col>
					<Col sm={4}>
						<h3 style={{marginBottom:"30px" }}>Bacs les plus remplis</h3>
						<ContainerFeedTop />
					</Col>
					<Col sm={4}>
						<div style={{ marginBottom: "50px" }}>
							<h5
								style={{
									textAlign: "center",
									paddingBottom: "50px",
								}}
							>
								Bacs en magasin
							</h5>
							<ContainerInShops />
						</div>
						<Divider />
						<div style={{ marginTop: "50px" }}>
							<h5
								style={{
									textAlign: "center",
									paddingBottom: "50px",
								}}
							>
								Stock
							</h5>
							<StockContainerSupply />
						</div>
					</Col>
				</Row>
		</Container>
	);
};

export default ContainerControl;
