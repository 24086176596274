import gql from "graphql-tag";
import React from "react";
import { useQuery } from "@apollo/client";
import "chart.js/auto";
import { Accordion, Alert, Button, Col, Container, Row } from "react-bootstrap";
import NavBar from "../../Navbar";
import "bootstrap/dist/css/bootstrap.min.css";
import { useKeycloak } from "react-keycloak";
import { HiOutlineExclamationCircle } from "react-icons/hi";

const query = gql`
  query MyQuery {
    Shop(distinct_on: brand, where: { isShop: { _eq: true } }) {
      brand
    }
  }
`;

const QueryShop = ({ queryShop, urlPrefix }) => {
  const arrayShop = [];
  const arrayDepartement = [];
  const { loading, error, data } = useQuery(queryShop);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  let i = 0;
  data.Shop.forEach((e) => {
    const link = urlPrefix + e.id;
    if (arrayShop.length >= 1) {
      if (arrayDepartement.includes(e.departement)) {
        arrayShop.push(
          <div key={i}>
            <hr />
            <a href={link} style={{ fontSize: "15px" }}>
              {e.name}
            </a>
            <br />
          </div>
        );
      } else {
        arrayDepartement.push(e.departement);
        arrayShop.push(
          <div key={i}>
            <h3 style={{ fontSize: "17px" }}>
              <u>Département: {e.departement}</u>
            </h3>
            <div>
              <a href={link} style={{ fontSize: "15px" }}>
                {e.name}
              </a>
              <br />
            </div>
          </div>
        );
      }
    } else {
      arrayDepartement.push(e.departement);
      arrayShop.push(
        <div key={i}>
          <h3 style={{ fontSize: "17px" }}>
            <u>Département: {e.departement}</u>
          </h3>
          <div>
            <a href={link} style={{ fontSize: "15px" }}>
              {e.name}
            </a>
            <br />
          </div>
        </div>
      );
    }
    i++;
  });
  return arrayShop;
};

function MakeQuery(query) {
  const { loading, error, data } = useQuery(query);

  if (loading) return { success: false, message: "Loading..." };
  if (error) return { success: false, message: `Error! ${error.message}` };
  return { success: true, data: data };
}

const Chart = ({ query, urlPrefix }) => {
  const table = [];
  let i = 0;
  const rslt = MakeQuery(query);
  if (rslt.success === false) return rslt.message;
  rslt.data.Shop.forEach((element) => {
    i++;
    let image;
    const tableShop = [];
    const queryshop = gql`
                          query MyQuery{
                          Shop(where: {brand: {_eq: "${element.brand}"}}, order_by: {departement: asc}) {
                            id
                            name
                            departement
                          }
                        }
                        `;

    tableShop.push(
      <QueryShop queryShop={queryshop} urlPrefix={urlPrefix} key={i} />
    );
    switch (element.brand) {
      case "U":
        image = "/u.png";
        break;
      case "Leclerc":
        image = "/1200px-Logo_E.Leclerc_Sans_le_texte.svg.png";
        break;
      case "Intermarché":
        image = "/730-dmwew3stwq.webp";
        break;
      case "Auchan":
        image = "/Auchan-Logo.png";
        break;
      default:
        image =
          "/kisspng-logo-carrefour-market-brand-image-startup-weekend-monaco-2017-retour-sur-la-4ème-5b677e2a9f4257.9785071315335091626523.jpg";
        break;
    }
    table.push(
      <div key={i}>
        <Accordion.Item eventKey={i}>
          <Accordion.Header>
            <img src={image} alt={""} style={{ height: 50, width: 50 }} />
            <span style={{ paddingLeft: 50, fontWeight: "bold" }}>
              {element.brand}
            </span>
          </Accordion.Header>
          <Accordion.Body>{tableShop}</Accordion.Body>
        </Accordion.Item>
      </div>
    );
  });
  return table;
};

const SelectShopComponent = ({ urlPrefix, redirect }) => {
  redirect ??= false;
  const { initialized } = useKeycloak();
  return initialized ? (
    <Container className="container">
      <div>
        <NavBar />
        <Row>
          <Col sm={13} xl={11}>
            <div
              style={{
                marginTop: "50px",
                marginLeft: "15px",
                marginBottom: "50px",
              }}
            >
              {redirect ? (
                <>
                  <Alert
                    variant="danger"
                    style={{ width: "fit-content", marginTop: "100px" }}
                  >
                    <Alert.Heading>
                      <HiOutlineExclamationCircle
                        size="30px"
                        style={{ marginRight: "10px" }}
                      />
                      Redirection vue commerce
                    </Alert.Heading>
                    La vue commerce peut être consultée sur l'application de
                    reporting{" "}
                    <a href="https://reporting.berny.app/dashboard/13-commerce-detail-par-magasin">
                      Metabase
                    </a>
                  </Alert>
                  {/* Blue outline button */}
                  <Button
                    href="https://reporting.berny.app/dashboard/13-commerce-detail-par-magasin"
                    variant="outline-primary"
                    style={{
                      marginTop: "25px",
                      marginBottom: "50px",
                      width: "fit-content",
                    }}
                  >
                    Consulter{" "}
                    <img
                      src="https://www.vectorlogo.zone/logos/metabase/metabase-ar21.svg"
                      alt="metabase"
                    />
                  </Button>
                </>
              ) : (
                <>
                  <h2> Vue par magasin :</h2>
                  <h4>
                    {" "}
                    Veuillez choisir votre magasin parmi la liste suivante :
                  </h4>
                  <Accordion
                    defaultActiveKey="0"
                    flush
                    style={{ marginTop: "25px", marginBottom: "50px" }}
                  >
                    <Chart query={query} urlPrefix={urlPrefix} />
                  </Accordion>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  ) : null;
};

export { SelectShopComponent };
