import React, {useEffect, useRef, useState} from "react";
import { Link } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getStorage, ref, listAll, getDownloadURL, getMetadata } from "firebase/storage";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { getAuth, signInAnonymously } from "firebase/auth";
import { firebaseConfig } from "../utils";
import {Button, Dropdown} from "react-bootstrap";
import {BsDownload, BsInfo} from "react-icons/all";

const FileProdArray = (token) => {

    const units = ['bytes', 'KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];

    function niceBytes(x){

        let l = 0, n = parseInt(x, 10) || 0;

        while(n >= 1024 && ++l){
            n = n/1024;
        }

        return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
    }

    const app = initializeApp(firebaseConfig);
    initializeAppCheck(app, {
        provider: new ReCaptchaV3Provider(
            "6Led7IQpAAAAACyLa4k7hh0YivDqRBCMwvaFhzDI" // Replace with your ReCaptcha Site Key
        ),
        isTokenAutoRefreshEnabled: true,
    });

    const auth = getAuth();
    const [results, setResults] = useState([]);
    const [selectedCleaner, setSelectedCleaner] = useState("");
    const [resultsByCleaner, setResultsByCleaner] = useState([{
        cleaner: "",
        url: "",
        date:"",
        size: ""
    }])
    const [toShow, setToShow] = useState([{}])
    useEffect(() => {
        signInAnonymously(auth)
            .then(() => {
                const storage = getStorage(app,"gs://app-pro-56fcb.appspot.com");
                const listRef = ref(storage, '/Fiche prod');
                listAll(listRef)
                    .then((res) => {
                        console.log(selectedCleaner)
                        setResults( []);
                        res.prefixes.forEach((folderRef) => {
                            setResults(results=> [...results, folderRef.name])

                        const listRefChild = ref(storage, '/Fiche prod/'+folderRef.name);
                        listAll(listRefChild)
                            .then((res) => {
                                resultsByCleaner.pop()
                                res.items.forEach((item) => {
                                    getMetadata(ref(storage, item.fullPath))
                                        .then((metadata) => {
                                            getDownloadURL(ref(getStorage(app,"gs://app-pro-56fcb.appspot.com"), item.fullPath)).then((imageResult) => {
                                                setResultsByCleaner( resultsByCleaner => [...resultsByCleaner, {
                                                    cleaner: folderRef.name,
                                                    url: imageResult,
                                                    date: item.name.toString().split(".pdf")[0],
                                                    size: niceBytes(metadata.size)
                                                }])
                                            })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                            // Uh-oh, an error occurred!
                                        });

                                });

                            }).catch((error) => {
                            console.log(error);
                            // Uh-oh, an error occurred!
                        });
                        });
                        setResults(results=> [...results, "Tous"])
                    }).catch((error) => {
                        console.log(error);
                    // Uh-oh, an error occurred!
                });
            })
            .catch((error) => {
                console.log("error : ", error.toString());
            });
        setToShow(resultsByCleaner);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function custom_sort(a, b) {
        return new Date(a.date.replaceAll('_',"T")).getTime() - new Date(b.date.replaceAll('_',"T")).getTime();
    }
    async function getImage(location) {
        const ImageURL = await getDownloadURL(ref(getStorage(app,"gs://app-pro-56fcb.appspot.com"), location));
        const xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = (event) => {
            const blob = xhr.response;
        };
        xhr.open('GET', ImageURL);
        xhr.send();
    }


    resultsByCleaner.sort(custom_sort)
    resultsByCleaner.reverse()



    return (
        <div>
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    {selectedCleaner !== "" ? selectedCleaner : "Laveur"}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                    {results.map((item, index) => {
                        return <Dropdown.Item onClick={() => {
                            setToShow(resultsByCleaner);
                            setSelectedCleaner(item);
                            if(item === "Tous"){

                            }
                            else{
                                setToShow(toShow => toShow.filter((toFilter)=> toFilter.cleaner === item))
                            }

                        }}>{item.toString()}</Dropdown.Item>
                            })}
                </Dropdown.Menu>
            </Dropdown>
        <table className="ui celled table table-striped">
            <thead>
            <tr class="table-success bg-success text-center">
                <td>Laveur</td>
                <td>Date de création</td>
                <td>Heure de création</td>
                <td>Poids</td>
                <td>Actions</td>
            </tr>
            </thead>
            <tbody>
            {selectedCleaner !== "" ? toShow.map((result) => {
                return <tr class="text-center">
                    <td data-label="creator" style={{alignContent: "center"}}>
                        {result.cleaner}
                    </td>
                    <td data-label="createdDate" style={{alignContent: "center"}}>
                        {result.date.split("_")[0]}
                    </td>
                    <td data-label="createdHour" style={{alignContent: "center"}}>
                        {result.date.split("_")[1]}
                    </td>
                    <td data-label="name" style={{alignContent: "center"}}>{result.size}</td>
                    <td data-label="comment" style={{alignContent: "center"}}>
                        <Button onClick={() => getImage(result.url)}><BsDownload/></Button>
                    </td>

                </tr>
            }) : resultsByCleaner.map((result) => {
                return <tr class="text-center">
                    <td data-label="creator" style={{alignContent: "center"}}>
                        {result.cleaner}
                    </td>
                    <td data-label="createdDate" style={{alignContent: "center"}}>
                        {result.date.split("_")[0]}
                    </td>
                    <td data-label="createdHour" style={{alignContent: "center"}}>
                        {result.date.split("_")[1]}
                    </td>
                    <td data-label="name" style={{alignContent: "center"}}>{result.size}</td>
                    <td data-label="comment">
                        <a href={result.url} >
                        <Button ><BsDownload/></Button>
                        </a>
                    </td>

                </tr>
            })}


            </tbody>
        </table>
        </div>
    );
};

export default FileProdArray;
