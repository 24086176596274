import { useQuery } from "@apollo/client";
import gql from "graphql-tag";
import momentTimezone from "moment-timezone";
import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useKeycloak } from "react-keycloak";
import { useParams } from "react-router-dom";
import { Button, Divider, Grid, Icon, Table } from "semantic-ui-react";
import Menu from "../../LeftMenu";
import NavBar from "../../Navbar";
import { _gqlStringToArray } from "../logistic/dashboardBac/Tools";

const GridExampleDividedPhrase = ({ id }) => {
  const query = gql`
    query MyQuery3 {
      Container_by_pk(id: "${id}") {
        choosen_shop
        choosen_delivery_date
        choosen_size
        items
        id
        max_big
        max_small
        status
        updatedDate
        usedFor
        updatedAt
        receiver
        Shop {
          brand
          name
        }
        Cleaner {
          enterprise
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(query);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;
  let nbContainers = 0;
  if (data.Container_by_pk.items !== null) {
    let items = data.Container_by_pk.items.replace("[");
    items = items.replace("]");
    nbContainers = items.split(",").length;
  }

  const deliveryDate = new Date(data.Container_by_pk.choosen_delivery_date);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour12: false,
    hour: "2-digit",
    minute: "2-digit",
  };

  let statusText = "";

  switch (data.Container_by_pk.status) {
    case "ready":
      statusText = "Prêt pour livraison";
      break;
    case "consumed":
      statusText = "Consommé";
      break;
    case "full":
      statusText = "Plein";
      break;
    case "empty":
      statusText = "Vide";
      break;
    case "notComplete":
      statusText = "Non complet";
      break;
    case "free":
      statusText = "Libre";
      break;
    case "shipped":
      statusText = "Livré";
      break;
    case "waitingDelivery":
      statusText = "En attente de livraison";
      break;
    case "close":
      statusText = "Fermé";
      break;
    case "opened":
      statusText = "Ouvert";
      break;
    case "received":
      statusText = "Reçu";
      break;
    case "error":
      statusText = "Erreur";
      break;
    default:
      statusText = "non renseigné";
      break;
  }

  return (
    <Grid columns="three" divided>
      <Grid.Row>
        <Grid.Column width={2}>
          <Icon name="shop" size="huge" color="purple" />
        </Grid.Column>
        <Grid.Column width={3}>
          <p style={{ textDecoration: "underline" }}>Livré à : </p>
          <span>
            {data.Container_by_pk.Shop
              ? `${data.Container_by_pk.Shop.brand} - ${data.Container_by_pk.Shop.name}`
              : data.Container_by_pk.Cleaner?.enterprise ?? "non renseigné"}
          </span>
        </Grid.Column>
        <Grid.Column width={2}>
          <Icon name="calendar alternate" size="huge" color="red" />
        </Grid.Column>
        <Grid.Column width={3}>
          <p style={{ textDecoration: "underline" }}>Livré le: </p>
          <span style={{ fontWeight: "bold" }}>
            {data.Container_by_pk.choosen_delivery_date
              ? deliveryDate.toLocaleString("fr-FR", options)
              : "pas de date de livraison"}
          </span>
        </Grid.Column>
        <Grid.Column width={2}>
          <Icon name="info circle" size="huge" color="grey" />
        </Grid.Column>
        <Grid.Column width={3}>
          <p style={{ textDecoration: "underline" }}>Statut: </p>
          <span style={{ fontWeight: "bold" }}>{statusText}</span>
          <br />
          <span style={{ fontWeight: "normal" }}>
            {data.Container_by_pk.status === "opened"
              ? `${nbContainers} sont dans ce bac`
              : ""}
          </span>
        </Grid.Column>
      </Grid.Row>
      <Grid.Row>
        {data.Container_by_pk.items !== null && (
          <ItemsInContainerTable items={data.Container_by_pk.items} />
        )}
      </Grid.Row>
    </Grid>
  );
};

export const ItemsInContainerTable = ({ items }) => {
  const query = gql`
    query {
      Item(where: {id: {_in: [${_gqlStringToArray(items)
        .map((item) => `"${item}"`)
        .join(",")}]}}) {
        id
        Format {
          name
        }
      }
    }
  `;

  const [expanded, setExpanded] = useState(false);

  const { loading, error, data } = useQuery(query);
  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <Grid.Row>
      <Grid.Column width={16}>
        {!expanded ? (
          <Button onClick={() => setExpanded(true)}>
            Voir le détail des contenants
          </Button>
        ) : (
          <>
            <Button onClick={() => setExpanded(false)}>
              Cacher le détail des contenants
            </Button>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell>Items</Table.HeaderCell>
                  <Table.HeaderCell>Format</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {data.Item.map((el, index) => {
                  return (
                    <Table.Row key={el.id}>
                      <Table.Cell>
                        <a href={`/contenant/${el.id}`}>{el.id}</a>
                      </Table.Cell>
                      <Table.Cell>{el.Format.name ?? "N/A"}</Table.Cell>
                    </Table.Row>
                  );
                })}
              </Table.Body>
            </Table>
          </>
        )}
      </Grid.Column>
    </Grid.Row>
  );
};

function ReceiptsHistory({ containerId }) {
  const query = gql`
    query MyQuery {
      receipt (where: {transportedIn: {_eq: "${containerId}"}}) {
        id
        receiptDate
        Shop {
          brand
          name
        }
        itemId
        Item {
          size
          type
        }
        updatedDate
        transportedIn
        Warehouse {
          brand
					name
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(query);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Expéditeur</Table.HeaderCell>
            <Table.HeaderCell>Destinataire</Table.HeaderCell>
            <Table.HeaderCell>Contenants</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.receipt.length > 0 ? (
            aggregateData(data)
              .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date
              .map((el) => {
                return (
                  <Table.Row key={el.date + containerId}>
                    <Table.Cell>
                      <a
                        href={encodeURI(
                          `/receptions/${momentTimezone
                            .tz(el.date, "Europe/Paris")
                            .toISOString()}/${containerId}`
                        )}
                      >
                        {el.date
                          .startOf("minute")
                          .toDate()
                          .toLocaleString("fr-FR", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour12: false,
                            hour: "2-digit",
                            minute: "2-digit",
                            timeZone: "Europe/Paris",
                          })}
                      </a>
                    </Table.Cell>
                    <Table.Cell>{el.shop}</Table.Cell>
                    <Table.Cell>
                      {el.items[0].Warehouse.brand} {el.items[0].Warehouse.name}
                    </Table.Cell>
                    <Table.Cell>
                      {el.items
                        .reduce((acc, item) => {
                          if (item.itemId === "empty") return acc;
                          if (!acc) {
                            return [
                              {
                                size: `${item.Item.size} ${
                                  item.Item.type ?? "degrenne"
                                }`,
                                count: 1,
                              },
                            ];
                          } else {
                            const found = acc.find(
                              (el) =>
                                el.size ===
                                `${item.Item.size} ${
                                  item.Item.type ?? "degrenne"
                                }`
                            );
                            if (found) {
                              found.count++;
                            } else {
                              acc.push({
                                size: `${item.Item.size} ${
                                  item.Item.type ?? "degrenne"
                                }`,
                                count: 1,
                              });
                            }
                            return acc;
                          }
                        }, [])
                        .map((item) => {
                          return (
                            <p key={item.size}>
                              {item.count} x {item.size}
                            </p>
                          );
                        })}
                      {el.items.find((item) => item.itemId === "empty") && (
                        <p>Bac vide</p>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="3">Aucune réception</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}

function DeliveriesHistory({ containerId }) {
  const query = gql`
    query MyQuery {
  Container_shipped (where: {container_id: {_eq: "${containerId}"}}) {
    Shop {
      brand
      name
      id
    }
		Warehouse_receiver {
			id
			brand
			name
		}
    Cleaner {
      enterprise
      id
    }
		Warehouse_sender {
			id
			brand
			name
		}
		container_id
		date
  }
}

  `;

  const { loading, error, data } = useQuery(query);

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Expéditeur</Table.HeaderCell>
            <Table.HeaderCell>Destinataire</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {data.Container_shipped.length > 0 ? (
            data.Container_shipped.slice() // Create a copy of the array to avoid mutating the original data
              .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort by date
              .map((el) => {
                return (
                  <Table.Row key={el.date + containerId}>
                    <Table.Cell>
                      <a
                        href={encodeURI(
                          `/livraison/${momentTimezone
                            .tz(el.date, "Europe/Paris")
                            .toISOString()}/${containerId}`
                        )}
                      >
                        {momentTimezone
                          .tz(el.date, "Europe/Paris")
                          .startOf("minute")
                          .toDate()
                          .toLocaleString("fr-FR", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                            hour12: false,
                            hour: "2-digit",
                            minute: "2-digit",
                            timeZone: "Europe/Paris",
                          })}
                      </a>
                    </Table.Cell>
                    <Table.Cell>
                      {el.Warehouse_sender
                        ? `${el.Warehouse_sender.brand} ${el.Warehouse_sender.name}`
                        : `${el.Cleaner.enterprise}`}
                    </Table.Cell>
                    <Table.Cell>
                      {el.Warehouse_receiver ? (
                        <a href={`/warehouse/${el.Warehouse_receiver.id}`}>
                          {el.Warehouse_receiver.brand}{" "}
                          {el.Warehouse_receiver.name}
                        </a>
                      ) : (
                        <a href={`/operation/shop/${el.Shop.id}`}>
                          {el.Shop.brand} {el.Shop.name}
                        </a>
                      )}
                    </Table.Cell>
                  </Table.Row>
                );
              })
          ) : (
            <Table.Row>
              <Table.Cell colSpan="2">Aucune livraison</Table.Cell>
            </Table.Row>
          )}
        </Table.Body>
      </Table>
    </>
  );
}

function aggregateData(data) {
  const result = [];
  data.receipt.forEach((el) => {
    const date = new momentTimezone.tz(el.receiptDate, "UTC");
    const index = result.findIndex((el) => {
      return el.date.isSame(date, "hour");
    });

    // condition in order to handle no shop_id data in receipt table
    if (index === -1) {
      if (el.Shop !== null) {
        console.log(el);
        result.push({
          date: date,
          shop: `${el.Shop.brand} - ${el.Shop.name}`,
          items: [el],
        });
      } else {
        result.push({
          date: date,
          shop: "Pas de détail",
          items: [el],
        });
      }
    } else {
      result[index].items.push(el);
    }
  });
  return result;
}

const CollectContainerInfos = () => {
  const params = useParams();
  const container = params.id;
  const { initialized } = useKeycloak();
  return initialized ? (
    <Container className="container">
      <div>
        <NavBar />
        <Row>
          <Col sm={2} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Menu />
          </Col>
          <Col sm={10}>
            <div>
              <div style={{ marginTop: "100px", marginLeft: "15px" }}>
                <div>
                  <h2 style={{ margin: "10px", textAlign: "center" }}>
                    Bac - {container}
                  </h2>
                  <div style={{ marginTop: "100px" }} />
                  <h4 style={{ margin: "10px", textAlign: "center" }}>
                    <GridExampleDividedPhrase id={container} />
                  </h4>
                  {/* divider */}
                  <Divider />
                  <div
                    style={{
                      textAlign: "center",
                    }}
                  >
                    <h2 style={{ margin: "10px" }}>Historique de transport</h2>
                    {/* Receipts and deliveries next to each other in two columns */}
                    <Grid columns={2} divided>
                      <Grid.Row>
                        <Grid.Column>
                          <h3 style={{ margin: "10px" }}>Livraisons</h3>
                          <DeliveriesHistory containerId={container} />
                        </Grid.Column>
                        <Grid.Column>
                          <h3 style={{ margin: "10px" }}>Réceptions</h3>
                          <ReceiptsHistory containerId={container} />
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Container>
  ) : null;
};
export { CollectContainerInfos };
